export const colors = {
  default_text: "#414141",
  default_highlight: "#EFBF07",
  table_primary: "#EFBF07",
  white: "#FFFFFF",
  light_gray: "#C7C7C7",
  background_white: "#F6F6F6",
  gray_white: "#EEECEC",
  red_warning: "#D2202A",
  bg_gray: "#A2A1A1",
  gray_theme: "#979797",
};
