import { useTranslation } from "react-i18next";

const WorkshopParticipants: React.FC<{
  totalNum: number;
  psaS: number;
  psaT: number;
  schoolS: number;
  schoolT: number;
  farmers: number;
  promoters: number;
  junior_youth_parents: number;
  community_leaders: number;
  others: number;
  index?: boolean;
  scroll?: boolean;
}> = ({
  totalNum,
  psaS,
  psaT,
  schoolS,
  schoolT,
  farmers,
  promoters,
  junior_youth_parents,
  community_leaders,
  others,
  index,
  scroll,
}) => {
  const { t } = useTranslation();

  return (
    <h3 className="p-tooltip inline">
      {totalNum}
      <table
        className={`p-table absolute z-10 right-1 h-fit shadow-lg rounded-md text-center bg-white px-4 py-1  ${
          scroll
            ? "top-[20px]"
            : "bottom-[0px]" && index
            ? "bottom-[20px]"
            : "top-[20px]"
        }`}
      >
        <thead>
          <tr className="border-b border-b-gray-200 font-medium">
            <td>{t("Workshops.Participants of")}</td>
            <td className="pl-6">Nr.</td>
          </tr>
        </thead>
        <tbody className="text-[#939393]">
          <tr>
            <td className="py-2">{t("Workshops.PSA students")}</td>
            <td className="pl-6">{psaS}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.School students")}</td>
            <td className="pl-6">{schoolS}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.PSA tutors")}</td>
            <td className="pl-6">{psaT}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.School teachers")}</td>
            <td className="pl-6">{schoolT}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.Farmers")}</td>
            <td className="pl-6">{farmers}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.Promoters")}</td>
            <td className="pl-6">{promoters}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.Junior youth parents")}</td>
            <td className="pl-6">{junior_youth_parents}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.Community leaders")}</td>
            <td className="pl-6">{community_leaders}</td>
          </tr>
          <tr>
            <td className="py-2">{t("Workshops.Other participants")}</td>
            <td className="pl-6">{others}</td>
          </tr>
        </tbody>
      </table>
    </h3>
  );
};

export default WorkshopParticipants;
