import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type tokens = {token : any , refresh : any}
type profile = {first_name:string ; email :string ; role : string; profile_picture : string; is_root_admin: boolean;}
const storedToken = localStorage.getItem('token');
 const storedRefresh = localStorage.getItem('refresh');
const userIsLoggedIn = !! storedToken;
const initialState: { token: any; refresh : any ; isLogin: boolean; profile : profile ;} = {
  token: storedToken,
  isLogin: userIsLoggedIn,
  refresh : storedRefresh,
  profile : {email : "" , first_name : "" , role : "" ,profile_picture : "",is_root_admin: false}
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    userLogin: (state, action: PayloadAction<tokens>) => {
      state.isLogin = true;
      state.token = action.payload.token;
      state.refresh = action.payload.refresh;
      localStorage.setItem('token',action.payload.token);
      localStorage.setItem('refresh' ,action.payload.refresh);
    },
    refresh : (state, action: PayloadAction<tokens>) => {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
      state.isLogin = true;
      state.token = action.payload.token;
      state.refresh = action.payload.refresh;
      localStorage.setItem('token',action.payload.token);
      localStorage.setItem('refresh' ,action.payload.refresh);
    },
    profile : (state , action: PayloadAction<profile>) => {
      state.profile = action.payload;
    },
    userRoleChange:(state) =>{
      state.profile.is_root_admin =false;
    },
    userLogOut: (state) => {
      state.isLogin = false;
      state.token = "";
      state.refresh = "";
      state.profile = {email : "" , first_name : "" , role : "",profile_picture:"", is_root_admin:false,};
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
    },
  },
});
export const LoginSliceActions = loginSlice.actions;

export default loginSlice;
