import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";

const InfoPopoverNursery: React.FC<{
  phone?: string;
  note?:string;
  index?: boolean;
  scroll?: boolean;
}> = ({ index, scroll, phone,note }) => {
  return (
    <div className="desc-btn-nursery ">
      <FontAwesomeIcon
        className="desc-icon-nursery w-4 h-4 text-default-text"
        icon={faInfoCircle}
      />
      <div
        className={`desc-text-nursery shadow-2xl ${phone ? "w-[150px]" : "w-[120px] truncate"} ${
          scroll ? "top-[20px]" : index ? "bottom-[20px]" : "top-[20px]"
        }`}
      >
        <p>{phone || note}</p>
      </div>
    </div>
  );
};

export default InfoPopoverNursery;
