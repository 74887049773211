import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddPlantingOrganizerForm from "./AddPlantingOrganizerForm";

const AddPlantingOrganizer = () => {
  const { t } = useTranslation();
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/organizers/new/`,
    "POST",
    false,
    "",
    true,
    true
  );
  const { id } = useParams<any>();

  let title;
  if (id === "grp") {
    title = t("Organizer.Groups");
  } else if (id === "ind") {
    title = t("Organizer.Individuals");
  } else if (id === "ins") {
    title = t("Organizer.Institutions");
  } else {
    title = t("Organizer.Families");
  }

  let button_title;
  if (id === "grp") {
    button_title = t("Register.Group");
  } else if (id === "ind") {
    button_title = t("Planting organizers.Individual");
  } else if (id === "ins") {
    button_title = t("Planting organizers.Institution");
  } else {
    button_title = t("Register.Family");
  }

  return (
    <div className="parent-container">
      <h1 className="font-bold mt-5 text-default-text">
        {t("Register.Add") + button_title}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Planting organizers.Planting organizers"), url: "" },
          { name: title, url: `/setting/organizers/${id}` },
          { name: t("Register.Add") + button_title, url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddPlantingOrganizerForm
            send={fetchData}
            type={id}
            sendResponse={response}
            textButton={t("Register.Add") + button_title}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Add-register-2.svg"
            alt="Add Planting Organizer"
            className="mt-4 ml-2 xl:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default AddPlantingOrganizer;
