import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { Fragment } from "react";

const WarningRedAlert: React.FC<{
  type: string; //For delete alerts has value, for others would be empty string
  isDelete: boolean; //If it is delete record warning it should be true and other boolean props should be false.
  isSignOut: boolean; //If it is sign out warning it should be true and other boolean props should be false.
  hide: () => void;
  action: () => void;
}> = ({ type, isDelete, isSignOut, hide, action }) => {
  const { t } = useTranslation();

  const acceptHandler = () => {
    action();
  };

  const hidePopOver = () => {
    hide();
  };

  return (
    <div className="w-full h-full bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 z-50">
      <div className="bg-white w-full h-fit px-10 py-14 sm:w-[492px] rounded-md shadow-md text-center">
        <FontAwesomeIcon
          className="w-[104px] h-[104px] text-ph-red-warning"
          icon={faCircleExclamation}
        />
        {isDelete && (
          <div>
            <h1 className="mt-4 text-[25px] font-bold text-ph-red-warning">
              {t("PlantingFilter.Delete")} {type}
            </h1>
            <p className="mt-5">
              <Fragment>
                {t("Message.Are you sure you want to")} <br />{" "}
                {t("Message.Delete this")}{" "}
                <span className="font-semibold">{t("Message.Record")}</span>?
              </Fragment>
            </p>
            <div className="mt-7 border-t border-gray-300 pt-8">
              <button
                onClick={hidePopOver}
                className="mr-2 px-12 py-3 text-sm font-medium"
              >
                {t("Message.Cancel")}
              </button>
              <button
                onClick={acceptHandler}
                className="px-12 py-3 bg-ph-red-warning text-white text-sm rounded-3xl font-medium"
              >
                {t("PlantingFilter.Delete")}
              </button>
            </div>
          </div>
        )}
        {isSignOut && (
          <div>
            {" "}
            <h1 className="mt-4 text-[25px] font-bold text-ph-red-warning">
              {t("Navbar.Sign out")}
            </h1>
            <p className="mt-5">
              <Fragment>
                {t("Message.Are you sure you want to")} <br />{" "}
                <span className="font-bold">{t("Navbar.sign out")} </span>
              </Fragment>
            </p>
            <div className="mt-7 border-t border-gray-300 pt-8">
              <button
                onClick={hidePopOver}
                className="mr-2 px-12 py-3 text-sm font-medium"
              >
                {t("Message.No")}
              </button>
              <button
                onClick={action}
                className="px-12 py-3 bg-ph-red-warning text-white text-sm rounded-3xl font-medium"
              >
                {t("Message.Yes")}
              </button>
            </div>{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default WarningRedAlert;
