import { useState } from "react";
import { useTranslation } from "react-i18next";
import RegisterMap from "./RegisterMap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";

const LocationPage: React.FC<{
  isAdd: boolean;
  header: string;
  lat: number;
  long: number;
  closeMap: () => void;
  latLongChange: (newLat: number, newLong: number) => void;
}> = ({
  isAdd,
  lat: propsLat,
  long: propsLong,
  closeMap,
  latLongChange,
  header,
}) => {
  const [lat, setLat] = useState<number>(Number(propsLat));
  const [long, setLong] = useState<number>(Number(propsLong));
  const { t } = useTranslation();

  const updateLatLang = (lat: number, long: number) => {
    setLat(lat);
    setLong(long);
  };

  return (
    <div className="w-full h-full z-[9999] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
      <div className="bg-white w-full h-[95%] px-8 py-6 sm:w-[492px] rounded-md shadow-md">
        {isAdd ? (
          <h2 className="font-semibold text-default-text pb-2">
            {t("Register.Add tree location")}
            <span
              title={t("Register.Location hint") + ""}
              className="text-ph-gray-text ml-2"
            >
              <FontAwesomeIcon icon={faCircleInfo} size="sm" />
            </span>
          </h2>
        ) : (
          <h2 className="font-semibold text-default-text pb-2">
            {header}
            <span
              title={t("Register.Drag the tree icon") + ""}
              className="text-ph-gray-text ml-2"
            >
              <FontAwesomeIcon icon={faCircleInfo} size="sm" />
            </span>
          </h2>
        )}
        <RegisterMap lat={lat} long={long} updateLatLang={updateLatLang} />
        <div className="flex w-full h-[80px] bg-ph-bg-white mt-4">
          <div>
            <p className="text-sm text-default-text w-[100px] pt-3 px-4">
              {t("Register.Latitude")}
            </p>
            <p className="text-sm text-default-text w-[100px] pt-4 px-4">
              {t("Register.Longitude")}
            </p>
          </div>
          <div>
            <p className="text-sm text-default-text pl-10 pt-3">{lat}</p>
            <p className="text-sm text-default-text pl-10 pt-4">{long}</p>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="bg-ph-btn float-right text-sm font-medium mt-5 text-white rounded-3xl px-6 py-2 hover:bg-ph-hover"
            onClick={() => {
              latLongChange(lat, long);
            }}
          >
            {isAdd ? t("Register.Add") : t("Tree Species.Update")}
          </button>
          <button
            onClick={() => {
              closeMap();
            }}
            type="button"
            className="text-ph-dark-gray-text mr-4 float-right text-sm font-medium mt-5 rounded-3xl px-6 py-2"
          >
            {t("Message.Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LocationPage;
