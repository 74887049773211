import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";

const AddDistributeSuccess: React.FC<{
  isShow: boolean;
  onOK: () => boolean;
  onClose: () => void;
  distributedTreeSelected: number;
  selectedSpecies: string;
  ageSelected: string;
}> = ({
  onOK,
  isShow,
  onClose,
  distributedTreeSelected,
  selectedSpecies,
  ageSelected,
}) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isShow) {
      setShowPrompt(isShow);
    }
  }, [isShow]);

  const handleOK = useCallback(() => {
    if (onOK()) {
      setShowPrompt(false);
      onClose();
    }
  }, [onOK, onClose]);

  return showPrompt ? (
    <Modal
      open={showPrompt}
      onClose={() => {
        setShowPrompt(false);
        onClose();
      }}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
    >
      <div className="bg-white w-full h-fit p-10 sm:w-[375px] rounded-md shadow-md text-center">
        <FontAwesomeIcon
          className="w-[48px] h-[48px] text-ph-hover"
          icon={faCircleCheck}
        />
        <p className="mt-4 text-lg font-bold text-ph-dark-gray-text text-center">
          {t("Message.Add distribution")}!
        </p>
        <p className="mt-6">
          {`${distributedTreeSelected} ${selectedSpecies} ${t(
            "Message.Of"
          )} ${ageSelected} ${t("Message.distributed successfully")}`}{" "}
        </p>

        <button
          onClick={handleOK}
          className="main-btn text-sm font-medium px-12 py-2 mt-5"
        >
          {t("Ok")}
        </button>
      </div>
    </Modal>
  ) : null;
};

export default AddDistributeSuccess;
