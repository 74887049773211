import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faUser,
  faUsers,
  faRectangleList,
} from "@fortawesome/pro-regular-svg-icons";
import { faCalendarDays } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import { Planting } from "../../../type/planting";
import WorkshopSlider from "../../Workshops/WorkshopList/WorkshopSlider";
import WorkshopParticipants from "../../Workshops/WorkshopList/WorkshopParticipants";
import OrganizerName from "./OrganizerName";
import PlantingActionMenu from "./PlantingActionMenu";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const PlantingGrid: React.FC<{
  plantings: Planting;
  fetchData: () => void;
}> = ({ plantings, fetchData: propFetchData }) => {
  const { i18n, t } = useTranslation();

  const [showDelete, isShowDelete] = useState<boolean>(false);
  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
    `/api/admin/plantings/${plantings.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (!plantings.is_deletable) {
      if ((plantings.type_transes.en || plantings.type_transes.fr) === t("Events.Planting Tree")) {
        toast.error(
          t(
            "Message.You can’t delete the event. It contains some registers."
          )
        );
      } else if ((plantings.type_transes.en || plantings.type_transes.fr) === t("Events.Nursery Establishment")) {
        toast.error(
          t(
            "Message.You can’t delete the event. It contains details of the trees supplied in the nursery."
          )
        );
      }
    } else {
      isShowDelete(true);
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const name =
    plantings.name_transes.fr === ""
      ? plantings.name_transes.en
      : plantings.name_transes.fr;

  const desc =
    plantings.description_transes.fr === ""
      ? plantings.description_transes.en
      : plantings.description_transes.fr;

  const image = plantings.resources.filter((image) => image.is_image);
  return (
    <Fragment>
      <div className="w-full lg:w-[49%] mb-4 p-5 rounded-md shadow-sm border border-[#EEECEC] text-sm hover:shadow-lg">
        <div className="flex justify-between items-center">
          <Link
            className="font-semibold text-default-text hover:text-[#0884B8]"
            to={`/events/view-event/${plantings.slug}`}
          >
            {i18n.language === "fr" ? name : plantings.name_transes.en}
          </Link>
          <PlantingActionMenu
            deleteAction={deleteAction}
            plantedTrees={plantings.planted_trees}
            registeredTrees={plantings.registered_trees}
            slug={plantings.slug}
            plantingDate={
              new Date(plantings.date.split("/").reverse().join("-"))
            }
            initials={plantings?.community_initials}
            cSlug={plantings?.community_slug}
            type={
              i18n.language === "fr"
                ? plantings.type_transes?.fr === ""
                  ? plantings.type_transes?.en
                  : plantings.type_transes?.fr
                : plantings.type_transes?.en
            }
            hasRegister={plantings?.has_register}
          />
        </div>
        {image?.length > 0 ? (
          <WorkshopSlider images={image} />
        ) : (
          <div className="w-full h-[269px] bg-ph-bg-white rounded-md mt-2 flex items-center justify-center">
            <img src="/img/No Pic placeholder illu.svg" alt="noPic" />
          </div>
        )}

        <p
          title={
            i18n.language === "fr" ? desc : plantings.description_transes.en
          }
          className="py-2 grid-desc"
        >
          {i18n.language === "fr" ? desc : plantings.description_transes.en}
        </p>
        <hr />
        <div className="mt-2">
          <div className="sm:flex py-2">
            <div className="w-full sm:w-[50%]">
              <span className="text-[#979797]">
                <FontAwesomeIcon icon={faRectangleList} /> {t("Events.Type")}:
              </span>
              <p className="w-9 inline pl-6">
                {i18n.language === "en"
                  ? plantings.type_transes.en
                  : plantings.type_transes.fr}
              </p>
            </div>
            <div className="w-[250px] mt-5 sm:mt-0 h-5 whitespace-nowrap overflow-hidden text-ellipsis">
              <span className="text-[#979797]">
                <FontAwesomeIcon icon={faCalendarDays} />{" "}
                {t("Tree inventory.Date")}:
              </span>
              <p className="w-9 inline pl-6">{plantings.date}</p>
            </div>
          </div>

          <div className="sm:flex items-center py-2">
            <div className="w-full sm:w-[50%] mt-2 sm:mt-0">
              <span className="text-[#979797]">
                <FontAwesomeIcon icon={faLocationDot} />{" "}
                {t("Plantings.Province")}:
              </span>
              <p className="inline ml-4">{plantings.department}</p>
            </div>
            <div className="mt-5 sm:mt-0">
              <span className="text-[#979797]">
                <FontAwesomeIcon icon={faUsers} /> {t("Plantings.Organizer(s)")}
                :
              </span>
              <div className="inline-block ml-2">
                <OrganizerName organizers={plantings.organizers} />
              </div>
            </div>
          </div>

          <div className="sm:flex items-center mt-1">
            <div className="w-full sm:w-[50%]">
              <span className="text-[#979797]">
                <FontAwesomeIcon icon={faUser} />{" "}
                {t("PlantingFilter.Participants")}:
              </span>
              <p className="inline px-3">
                <WorkshopParticipants
                  totalNum={plantings.participants_total}
                  others={plantings.other_participants}
                  psaS={plantings.psa_students}
                  psaT={plantings.psa_tutors}
                  schoolS={plantings.school_students}
                  schoolT={plantings.school_tutors}
                  farmers={plantings.farmers}
                  promoters={plantings.promoters}
                  junior_youth_parents={plantings.junior_youth_parents}
                  community_leaders={plantings.community_leaders}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      {showDelete && (
        <DeleteAlert
          message={
            plantings.registered_trees > 0
              ? t("Message.Delete planting with its registers alert") + ""
              : ""
          }
          type={t("Events.Events")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default PlantingGrid;
