import React, { useEffect, useState, Fragment, FC } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { Box, Stack, TableContainer, TableFooter } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import TableHeader from "./TableHeader";
import Loading from "./Loading";
import { TableThem } from "../../styles/TableThem";
import TablePagination from "./TablePagination";
import { t } from "i18next";

interface GenericTableProps {
  dataList: any[];
  titles: { name: string; value: string }[];
  loading: boolean;
  renderItem: (
    item: any,
    fetchData: () => void,
    lastIndex: number
  ) => JSX.Element;
  fetchData: () => void;
  setOrderingList: (value: string) => void;
  showAction?: boolean;
  search?: string;
  countData?: number;
  handleChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
}

const GenericTable: FC<GenericTableProps> = ({
  dataList,
  titles,
  loading,
  renderItem,
  setOrderingList,
  fetchData,
  countData,
  showAction = true,
  search,
  handleChange,
}) => {
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  useEffect(() => {
    setOrderingList(ordering);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);
  const order = (value: string) => {
    setOrdering(value);
  };
  useEffect(() => {
    if (dataList) {
      setCount(countData ? countData : count);
    }
  }, [dataList]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const internalHandleChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    if (handleChange) handleChange(event, value);
  };
  return (
    <Fragment>
      {dataList.length > 0 ? (
        <TableContainer>
          <ThemeProvider theme={TableThem}>
            <Table>
              <TableHeader
                setOrdering={order}
                titles={titles}
                showAction={showAction}
              />
              <TableBody>
                {dataList.map((item, index) => (
                  <TableRow key={index}>
                    {renderItem(
                      item,
                      fetchData,
                      0 <= dataList.length && dataList.length <= 7
                        ? 0
                        : index >= Math.floor(dataList.length / 2)
                        ? index
                        : -1
                    )}
                  </TableRow>
                ))}
              </TableBody>
              {dataList.length > 0 && (
                <TableFooter
                  style={{
                    border: "none",
                    height: "160px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{ flexShrink: 0 }}
                    style={{ position: "absolute", left: "0px", top: "30px" }}
                  >
                    <Stack spacing={2}>
                      <TablePagination
                        count={Math.ceil(count / 10)}
                        handleChange={internalHandleChange}
                      />
                    </Stack>
                  </Box>
                  <p className="absolute right-0 top-[30px] text-sm text-default-text">{`${t(
                    "Message.Showing"
                  )} ${(page - 1) * 10 + 1} - ${
                    (page - 1) * 10 + dataList.length
                  } ${t("Message.Of")} ${count}`}</p>
                </TableFooter>
              )}
            </Table>
          </ThemeProvider>
        </TableContainer>
      ) : (
        <Loading loading={loading} />
      )}
    </Fragment>
  );
};

export default GenericTable;
