import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  plantingOrganizerFilters,
  plantingOrganizerFilterSet,
  plantingOrganizerURL,
} from "../../../store";
import BreadCrumb from "../../Global/BreadCrumb";
import AppliedItem from "../../Register/RegisterList/AppliedItem";
import PlantingOrganizerFilterBox from "./PlantingOrganizerFilterBox";
import SearchField from "../../Global/SearchField";
import { plantingOrganizerFilterAction } from "../../../store/plantingOrganizerFilter-slice";
import useAxios from "../../../hook/useAxios";
import { PlantingOrganizer } from "../../../type/plantingOrganizer";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../../Global/TablePagination";
import { TableThem } from "../../../styles/TableThem";
import GenericTable from "../../Global/GenericTable";
import PlantingOrganizerTableItem from "./PlantingOrganizerTableItem";

const PlantingOrganizers = () => {
  const [organizerList, setOrganizerList] = useState<PlantingOrganizer[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const dispatch = useDispatch();
  const url = useSelector(plantingOrganizerURL);
  const filters = useSelector(plantingOrganizerFilters);
  const isFilter = useSelector(plantingOrganizerFilterSet);
  const { id } = useParams<any>();
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin${url}&organizer_type=${id}&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setOrganizerList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [url, sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  let title;
  if (id === "grp") {
    title = t("Organizer.Groups");
  } else if (id === "ind") {
    title = t("Organizer.Individuals");
  } else if (id === "fml") {
    title = t("Organizer.Families");
  } else {
    title = t("Organizer.Institutions");
  }

  let button_title;
  if (id === "grp") {
    button_title = t("Register.Group");
  } else if (id === "ind") {
    button_title = t("Planting organizers.Individual");
  } else if (id === "fml") {
    button_title = t("Register.Family");
  } else {
    button_title = t("Planting organizers.Institution");
  }

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-bold mt-5 text-default-text">{title}</h1>
        <Link
          to={`/setting/organizers/add/${id}`}
          className="main-btn px-[23px] py-[10px] text-sm"
        >
          {t("Register.Add") + button_title}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Planting organizers.Planting organizers"), url: "" },
          { name: title, url: "" },
        ]}
      />
      <PlantingOrganizerFilterBox />
      {isFilter && (
        <div className="bg-white p-4 rounded-md relative mt-3">
          <h1 className="text-[#979797] font-medium text-sm">
            {t("Tree Species.Applied Filters")}
          </h1>
          <div className="flex flex-wrap items-center">
            <AppliedItem
              type={t("Workshops.Workshop status")}
              value={
                filters.isActive === "true"
                  ? t("Province.Active")
                  : t("Province.Inactive")
              }
              removeFilter={() => {
                dispatch(plantingOrganizerFilterAction.clearFilter());
                dispatch(plantingOrganizerFilterAction.setURL());
              }}
            />
          </div>
        </div>
      )}
      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          search={sendSearch}
          countData={count}
          handleChange={handleChange}
          dataList={organizerList}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Tree Species.Name"), value: "name" },
            { name: t("Workshops.Workshop status"), value: "is_active" },
          ]}
          renderItem={(plantingOrganizer, fetchData, lastIndex) => (
            <PlantingOrganizerTableItem
              plantingOrganizer={plantingOrganizer}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        <br />
      </div>
    </div>
  );
};

export default PlantingOrganizers;
