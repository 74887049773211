import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import Status from "../../../../Global/Status";
import { useForm } from "react-hook-form";

type FormValues = {
  name: string;
};
const AddMunicipality = ({
  handleModalClose,
  send,
  getInfo,
  textButton,
  title,
}: {
  handleModalClose: () => void;
  send: (data: any) => void;
  getInfo?: any;
  textButton: string;
  title: string;
}) => {
  const { t } = useTranslation();
  const [active, isActive] = useState<boolean>(true);

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (getInfo) {
      setValue("name", getInfo.data.name);
      isActive(getInfo.data.is_active);
    }
  }, [getInfo, setValue]);

  const addMunicipality = (data: FormValues) => {
    send({
      name: data.name,
      is_active: active,
    });
  };

  return (
    <Modal
      open
      onClose={() => handleModalClose()}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
    >
      <div className="py-12 px-8 bg-ph-white w-[70%] lg:w-[40%] shadow-[0px_3px_6px_#00000029] rounded-md">
        <div className="font-semibold text-default-text text-base">{title}</div>
        <form onSubmit={handleSubmit(addMunicipality)}>
          <div className="mt-8 flex flex-col md:flex-row md:items-center">
            <div className="w-full md:w-[80%]">
              <p className="text-sm text-default-text mb-[6px]">
                {t("Municipality.Municipality")} *
              </p>
              <input
                {...register("name", {
                  required: "required",
                })}
                type="text"
                className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                placeholder={t("Municipality.Municipality") + ""}
              />
              {errors.name && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
            </div>

            <div
              className={`md:ml-5 ${errors.name ? "-mt-2" : "mt-4 lg:mt-4"}`}
            >
              <Status
                active={active}
                isActive={(value) => {
                  isActive(value);
                }}
              />
            </div>
          </div>
          <div className="mt-10 flex flex-row justify-end">
            <button
              onClick={() => {
                handleModalClose();
              }}
              type="button"
              className="text-default-text text-sm mt-16rounded-3xl px-6 py-2 font-medium"
            >
              {t("Message.Cancel")}
            </button>
            <button
              type="submit"
              className="main-btn px-[23px] py-[10px] text-sm"
            >
              {textButton}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddMunicipality;
