import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    warning: {
      main: "#439FC6",
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#C7C7C7",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Inter,sans-serif",
          fontSize: "14px",
          "&.Mui-focused": {
            outline: "1px solid #439FC6",
            backgroundColor: "white",
            border: "none",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            paddingTop: "5.45px",
            paddingBottom: "5.45px",
            paddingLeft: "10px",
            "&.Mui-disabled": {
              color: "#FFFFFF",
            },
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root.Mui-error": {
            color: "#979797",
          },
          "& .MuiOutlinedInput-input": {
            padding: "12.2px  14px",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#C7C7C7",
          fontSize: "14px",
          fontFamily: "Inter,sans-serif",
          top: "-4.5px",
          "&.Mui-focused": {
            color: "#C7C7C7",
            fontSize: "14px",
            fontFamily: "Inter,sans-serif",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#F6F6F6",
        },
        notchedOutline: {
          borderColor: "#EEECEC",
        },
      },
    },
  },
});
