import { useTranslation } from "react-i18next";
import TranslationField from "../../../type/translationField";

const ScientificNamesTooltip: React.FC<{ names: TranslationField[] }> = ({
  names: propsNames,
}) => {
  const { i18n } = useTranslation();
  return (
    <div className="name-number">
      <span className=" text-white text-[12px]">+ {propsNames.length - 1}</span>
      <div className="name-content shadow-2xl">
        {propsNames.map((name, index) => (
          <h3 className="p-2" key={index}>
            {i18n.language === "fr" ? name.fr || name.en : name.en}
          </h3>
        ))}
      </div>
    </div>
  );
};

export default ScientificNamesTooltip;
