import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import { UsersRecord } from "../../../type/user";
import { useDispatch, useSelector } from "react-redux";
import { profileInfo } from "../../../store";
import RootAdminAssign from "../../ErrorAndAlert/RootAdminAssign";
import { LoginSliceActions } from "../../../store/login-slice";
import { faUserGear } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserActionItem from "./UserActionItem";

const UserTableItem: React.FC<{
  user: UsersRecord;
  fetchData: () => void;
  lastIndex: number;
}> = ({ user, lastIndex, fetchData: propFetchData }) => {
  const { i18n, t } = useTranslation();
  const profile = useSelector(profileInfo);
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const dispatch = useDispatch();
  const index = lastIndex >= 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/users/${user.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );

  const { fetchData: fetchAssign } = useAxios(
    process.env.REACT_APP_API_URL +
    `/api/admin/users/${user.slug}/assign-root-admin/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (deleteR !== null) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const rootAdminAction = async () => {
    try {
      await fetchAssign({
        is_root_admin: true,
      });
      await propFetchData();
      dispatch(LoginSliceActions.userRoleChange());
    } catch (error) {
      console.error("Error updating root admin:", error);
    }
  };

  const deleteAction = () => {
    isShowDelete(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };
  const active =
    i18n.language === "fr"
      ? user.is_active_transes.fr
      : user.is_active_transes.en;
  return (
    <Fragment>
      <TableCell align="left">
        <div className="flex items-center">
          {user.profile_image ? (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
              src={user.profile_image}
              alt="profile picture"
              className="w-10 h-10 mr-2 object-cover rounded-md"
            />
          ) : (
            <div className="flex items-center justify-center w-10 h-10 rounded-md object-cover bg-ph-map-bg mr-2">
              <p className="text-ph-toggle-text-blue">
                {user.first_name[0]}
                {user.last_name[0]}
              </p>
            </div>
          )}

          <p className="w-32">
            <span className="text-default-text">
              {user.first_name} {user.last_name}
            </span>
          </p>
          {user.is_root_admin && (
            <span className="text-default-text">
              <FontAwesomeIcon icon={faUserGear} />
            </span>
          )}
        </div>
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        {i18n.language === "fr" ? user.role_transes.fr : user.role_transes.en}
      </TableCell>
      <TableCell>
        <p
          className={`${user.is_active_transes.en === "Active"
            ? "bg-ph-toggle-bg-green text-ph-toggle-text-green"
            : "bg-ph-toggle-bg-red text-ph-red-warning"
            } text-center w-16 py-1 rounded-xl`}
        >
          {active}
        </p>
      </TableCell>
      <TableCell>
        {user.last_login && (
          <p>
            {user.last_login.split(" ")[0]} <br />
            {user.last_login.split(" ")[1]}
          </p>
        )}
      </TableCell>
      <TableCell align="right">
        {((profile.is_root_admin && profile.email !== user.email) ||
          (profile.role === "A" &&
            user.role_transes.en !== "Admin" &&
            profile.email !== user.email)) && (
          <UserActionItem
            editLink={`/setting/users/edit-user/${user.slug}`}
            deleteAction={deleteAction}
            index={index}
            scroll={scroll}
            rootAdminAlert={() => {
              setShowAlert(true);
            }}
            userAdmin={user.role_transes.en === "Admin" ? true : false}
          />
        )}
      </TableCell>
      {showAlert && (
        <RootAdminAssign
          name={user.first_name + " " + user.last_name}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          onRootAdminAction={rootAdminAction}
        />
      )}
      {showDelete && (
        <DeleteAlert
          type={t("Users.User")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default UserTableItem;
