import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddCommunityForm from "./AddCommunityForm";
import { useEffect, useState } from "react";
import { Department } from "../../../type/department";

const AddCommunity = () => {
  const { t } = useTranslation();
  const [selectOption, setSelectOption] = useState<{
    department: Department[];
  }>({ department: [] });
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/communities/new/`,
    "POST",
    false,
    "",
    true,
    true
  );
  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (departmentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          department: departmentRes.data,
        };
      });
    }
  }, [departmentRes]);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Community.Add community")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Community.Communities"), url: "/setting/communities" },
          { name: t("Community.Add community"), url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddCommunityForm
            send={fetchData}
            sendResponse={response}
            selectOptions={selectOption}
            textButton={t("Community.Add community")}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Did You Know (Add Community).svg"
            alt="Add community"
            className="mt-4 ml-2 xl:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default AddCommunity;
