import { createTheme } from "@mui/material/styles";

export const plantingTheme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#C7C7C7",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: "#414141",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#EEECEC",
          },
          "&.Mui-focused": {
            backgroundColor: "white",
            outline: "1px solid #439FC6",
            border: "none",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            paddingTop: "1px",
            paddingBottom: "1px",
            paddingLeft: "10px",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            padding: "7px  12px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#EEECEC",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#C7C7C7",
          fontSize: "14px",
          fontFamily: "Inter,sans-serif",
          top: "-8.5px",
          "&.Mui-focused": {
            color: "#C7C7C7",
            fontSize: "14px",
            fontFamily: "Inter,sans-serif",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#F6F6F6",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          ":hover": {
            color: "#439FC6",
            backgroundColor: "white",
          },
          "&.Mui-selected": {
            color: "#439FC6",
            backgroundColor: "white",
            ":hover": {
              backgroundColor: "white",
            },
          },
        },
      },
    },
  },
});
