import { SelectChangeEvent } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TranslationField from "../../../type/translationField";
import LanguagePoint from "../../Global/LanguagePoint";
import SelectLanguage from "../../Global/SelectLanguage";
import Warning from "../../ErrorAndAlert/Warning";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../Register/AddRegister/FormTheme";
import file from "../../../type/file";
import DragDropField from "../../Global/DragDropField";
import OldFile from "../../Global/OldFile";
import Status from "../../Global/Status";

const AddBotanicalFamilyForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  title: string;
  link: string;
  getInfo?: any;
  textButton: string;
}> = ({ send, sendResponse, title, link, getInfo, textButton }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState<string>("EN");
  const [name, setName] = useState<TranslationField>({ en: "", fr: "" });
  const [description, setDescription] = useState<TranslationField>({
    en: "",
    fr: "",
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const [showError, isShowError] = useState<boolean>(false);
  const [featureIcon, setFeatureIcon] = useState<file[]>([]);
  const [oldFeatureIcon, setOldFeatureIcon] = useState<string>("");
  const [active, isActive] = useState<boolean>(true);
  const history = useHistory();
  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };
  useEffect(() => {
    if (sendResponse) {
      toast.success(
        `${getInfo
          ? `${t("Message.Successful update")}`
          : `${t("Message.Successful add")}`
        }`
      );
      history.replace(link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (getInfo) {
      setLoading(false);
      setName(getInfo.data.name_transes);
      setDescription(getInfo.data.description_transes);
      isActive(getInfo.data.is_active_transes?.en === "Active" ? true : false);
      if (title === t("Incidents.Incident")) {
        setOldFeatureIcon(getInfo.data.featured_icon);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  const nameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (lang === "EN") {
      setName({ en: event.target.value, fr: name?.fr });
    } else if (lang === "FR") {
      setName({ en: name.en, fr: event.target.value });
    }
  };

  const descChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setDescription({ en: event.target.value, fr: description.fr });
    } else if (lang === "FR") {
      setDescription({ en: description.en, fr: event.target.value });
    }
  };

  const insertFile = (newFile: file[]) => {
    if (!newFile[0].type.includes("image")) {
      toast.error(`${t("Message.Image supported formats")}`);
    } else if (newFile[0].size > 5000000) {
      toast.error(`${t("Message.Maximum file size is 5 MB")}`);
    } else {
      setFeatureIcon([newFile[0]]);
      setOldFeatureIcon("");
    }
  };
  const removeFile = (index: number) => {
    setFeatureIcon([]);
  };
  const removeOldFeaturedIcon = (index: number) => {
    setOldFeatureIcon("");
  };

  const addIncident = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      (name.fr.trim() !== "" && name.en.trim() === "") ||
      (description.fr.trim() !== "" && description.en.trim() === "")
    ) {
      toast.error(`${t("Message.Empty default language error")}`);
    } else if (name.en) {
      isDirty(false);
      if (title === t("Incidents.Incident")) {
        const formData = new FormData();
        formData.append("name_transes ", JSON.stringify(name));
        formData.append("description_transes", JSON.stringify(description));
        if (!oldFeatureIcon && featureIcon.length !== 0) {
          formData.append("featured_icon", featureIcon[0]?.data);
        } else if (!oldFeatureIcon && featureIcon.length === 0) {
          formData.append("featured_icon", "");
        }
        formData.append("is_active", active ? "True" : "False");

        send(formData);
      } else {
        send({
          name_transes: name,
          description_transes: description,
          is_active: active,
        });
      }
    }
  };
  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={addIncident}>
          <ThemeProvider theme={theme}>
            <p className="text-sm text-ph-dark-text mb-[6px]">
              {t("AddPlanting.Language")}
            </p>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
          </ThemeProvider>
          <div className="flex flex-wrap flex-row">
            <div className="w-full md:w-[50%]">
              <p className="text-sm text-ph-dark-text mb-[6px] mt-7">
                {title} * <LanguagePoint lang={lang} />
              </p>
              <input
                onChange={nameChange}
                value={lang === "FR" ? name.fr : name.en}
                type="text"
                className="input-field pl-4 py-3 text-sm text-default-text placeholder-ph-light-gray"
                placeholder={title}
              />
              {showError && !name.en && (
                <Fragment>
                  <p className="error-text">{t("Message.Required field")}</p>
                </Fragment>
              )}
            </div>
            {!title.includes("Botanical") && (
              <div className="md:pl-4 mt-7 flex">
                <Status
                  active={active}
                  isActive={(value) => {
                    isActive(value);
                  }}
                />
              </div>
            )}
          </div>
          <p className="text-sm text-ph-dark-text mt-7 mb-[6px]">
            {t("Tree Species.Description")} <LanguagePoint lang={lang} />
          </p>
          <textarea
            onChange={descChange}
            value={lang === "FR" ? description.fr : description.en}
            rows={7}
            className="input-field resize-none text-sm text-default-text pl-4 py-[10px] placeholder-ph-light-gray"
            placeholder={t("Tree Species.Description") + ""}
          />

          {title === t("Incidents.Incident") && (
            <Fragment>
              <p className="text-sm text-default-text mt-7 mb-[6px]">
                {t("Tree Species.Featured icon")}
              </p>
              <DragDropField
                files={featureIcon}
                format="png (128 x 128 pixels) or SVG"
                insertFile={insertFile}
                removeFile={removeFile}
              />

              {oldFeatureIcon && (
                <OldFile
                  address={oldFeatureIcon}
                  id={0}
                  delete={true}
                  removeOldFile={removeOldFeaturedIcon}
                />
              )}
            </Fragment>
          )}

          <button
            onClick={() => {
              isShowError(true);
            }}
            type="submit"
            className="main-btn text-sm  px-6 py-2 mt-5 float-right font-medium"
          >
            {textButton}
          </button>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddBotanicalFamilyForm;
