import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddCommunityForm from "../AddCommunity/AddCommunityForm";
import Loading from "../../Global/Loading";
import { Department } from "../../../type/department";

const EditCommunity = () => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const [selectOption, setSelectOption] = useState<{
    department: Department[];
  }>({ department: [] });
  const { fetchData, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/communities/${id}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  const {
    fetchData: getCommunities,
    response: getEditResponse,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/communities/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );
  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  useEffect(() => {
    getCommunities({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (departmentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          department: departmentRes.data,
        };
      });
    }
  }, [departmentRes]);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Community.Edit community")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Community.Communities"), url: "/setting/communities" },
          { name: t("Community.Edit community"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
            <AddCommunityForm
              send={fetchData}
              selectOptions={selectOption}
              sendResponse={editResponse}
              getInfo={getEditResponse}
              textButton={t("Tree Species.Update")}
            />
          </div>
          <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
            <img
              src="/img/Did You Know (Add Community).svg"
              alt="Add community"
              className="mt-4 ml-2 xl:ml-10"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditCommunity;
