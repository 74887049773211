import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Warning from "../../ErrorAndAlert/Warning";
import Status from "../../Global/Status";

type FormValues = {
  name: string;
  kml: string;
};

const AddDepartmentForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
  textButton: string;
}> = ({ send, sendResponse, getInfo, textButton }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const [active, isActive] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    if (sendResponse) {
      toast.success(
        `${getInfo
          ? `${t("Message.Successful update")}`
          : `${t("Message.Successful add")}`
        }`
      );
      history.replace("/setting/provinces");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (getInfo) {
      setLoading(true);
      setValue("name", getInfo.data.name);
      setValue("kml", getInfo.data.kml);
      isActive(getInfo.data.is_active);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<FormValues>();

  const addDepartment = (data: FormValues) => {
    isDirty(false);
    send({
      name: data.name,
      kml: data.kml,
      is_active: active,
    });
  };

  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <form onSubmit={handleSubmit(addDepartment)}>
          <p className="text-sm text-default-text mb-[6px]">
            {t("Plantings.Country")}
          </p>
          <input
            type="text"
            value="Zambia"
            readOnly
            className="text-sm bg-ph-bg-gray text-white border border-[#eeecec] outline-none rounded-md w-full pl-4 py-3"
          />
          <p className="text-sm text-default-text mb-[6px] mt-7">
            {t("PlantingFilter.Province")} *
          </p>
          <input
            {...register("name", {
              required: "required",
            })}
            type="text"
            className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
            placeholder={t("PlantingFilter.Province") + ""}
          />
          {errors.name && (
            <p className="error-text">{t("Message.Required field")}</p>
          )}

          <p className="text-sm text-default-text mt-7 mb-[6px]">KML *</p>
          <textarea
            {...register("kml", {
              required: "required",
            })}
            rows={7}
            className="input-field resize-none text-sm  pl-4  py-[10px] placeholder-ph-light-gray"
            placeholder="KML"
          />
          {errors.kml && (
            <p className="error-text">{t("Message.Required field")}</p>
          )}

          <div className="mt-7">
            <Status
              active={active}
              isActive={(value) => {
                isActive(value);
              }}
            />
          </div>

          <button
            type="submit"
            className="main-btn text-sm px-6 py-2 mt-5 float-right font-medium"
          >
            {textButton}
          </button>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddDepartmentForm;
