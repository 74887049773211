import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../hook/useAxios";
import { accessToken, mapFilters, mapFilterSet, mapURL } from "../../store";
import { mapFilterAction } from "../../store/mapFilter-slice";
import { MapMarker } from "../../type/mapMarker";
import AppliedItem from "../Register/RegisterList/AppliedItem";
import Map from "./Map";
import MapFilter from "./MapFilter";
import Loading from "../Global/Loading";
import SearchField from "../Global/SearchField";

const MapPage = () => {
  const [loadMap, setLoadMap] = useState(false);
  const [mapRender, setMapRender] = useState(false);
  const [markerList, setMarkerList] = useState<MapMarker[]>([]);
  const [kml, setKml] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const [searching, isSearching] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector(mapFilters);
  const isFilter = useSelector(mapFilterSet);
  const access = useSelector(accessToken);
  const url = useSelector(mapURL);
  const { t, i18n } = useTranslation();

  const { response: mapMarkers, loading: mapLoading } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin${url}&search=${sendSearch}`,
    "GET",
    true,
    "",
    true,
    false
  );

  const { response: kmlData, fetchData: getKml } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin${url}`,
    "GET",
    false,
    "",
    false,
    false
  );

  useEffect(() => {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&map_ids=de709e4ae98647a3&callback=initMap`;
    window.document.body.appendChild(googleMapScript);

    (window as any).initMap = function () {
      setMapRender(true);
    };
  }, []);

  useEffect(() => {
    if (filters.department) {
      getKml(
        { slug: filters.department.slug },
        process.env.REACT_APP_API_URL +
          `/api/admin/departments/${filters.department.slug}/kml/`
      );
    } else {
      setKml("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.department, access]);

  useEffect(() => {
    if (mapMarkers && mapRender) {
      setMarkerList(mapMarkers.data);
      if (!filters.department) {
        setLoadMap(true);
      } else if (kmlData) {
        if (filters.department?.slug === kmlData?.data.slug) {
          setKml(kmlData.data.kml);
          setLoadMap(true);
        }
      }
    }

    if (mapLoading) {
      setLoadMap(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapMarkers, mapLoading, kmlData, mapRender]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
    isSearching(false);
  };

  return (
    <div className="h-full w-full px-5 md:px-[72px] py-3 bg-ph-map-bg">
      <h1 className="font-semibold text-default-text mt-5">{t("Navbar.Map")}</h1>

      <MapFilter />
      {isFilter && (
        <div className="bg-white p-4 rounded-md relative mt-3">
          <p className="text-[#979797] font-medium text-sm">
            {t("Tree Species.Applied Filters")}
          </p>
          <div className="flex flex-wrap items-center">
            {(filters.dateFrom !== "" || filters.dateTo !== "") && (
              <AppliedItem
                type={t("Plantings.Date of planting")}
                value={`${filters.dateFrom} ${
                  filters.dateTo !== "" ? "-" : ""
                } ${filters.dateTo}`}
                removeFilter={() => {
                  dispatch(mapFilterAction.removeDate());
                  dispatch(mapFilterAction.setURL());
                  dispatch(mapFilterAction.isFilterSet());
                }}
              />
            )}
            {filters.treeSpecies?.name_transes.en !== undefined && (
              <AppliedItem
                type={t("Register.Tree species")}
                value={
                  i18n.language === "fr"
                    ? filters.treeSpecies.name_transes.fr === ""
                      ? filters.treeSpecies.name_transes.en
                      : filters.treeSpecies.name_transes.fr
                    : filters.treeSpecies.name_transes.en
                }
                removeFilter={() => {
                  dispatch(mapFilterAction.removeTreeSpecies());
                  dispatch(mapFilterAction.setURL());
                  dispatch(mapFilterAction.isFilterSet());
                }}
              />
            )}
            {filters.department?.name !== undefined && (
              <AppliedItem
                type={t("PlantingFilter.Province")}
                value={filters.department?.name + ""}
                removeFilter={() => {
                  dispatch(mapFilterAction.removeDepartment());
                  dispatch(mapFilterAction.setURL());
                  dispatch(mapFilterAction.isFilterSet());
                }}
              />
            )}
          </div>
        </div>
      )}
      <SearchField
        applySearch={applySearch}
        change={(e) => {
          setSearch(e.target.value);
          isSearching(true);
        }}
        searchValue={search}
      />
      <div className="w-full mt-6">
        {!loadMap ? (
          <Loading loading={true} />
        ) : (
          <div className="relative">
            <Map
              markerList={markerList}
              kmlData={kml}
              search={searching}
              searchingStateHandler={() => isSearching(false)}
            />

            <div className="z-50 absolute top-16">
              <img src="/img/nrTree.png" alt="trees" />
              <span className="text-sm absolute top-14 left-6">
                <h2>{t("Plantings.No. of trees")}</h2>
                <p className="font-semibold">{markerList?.length}</p>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapPage;
