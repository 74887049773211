import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type DepartmentFilter = {
  isActive: string;
};
const initialState: {
  filterItem: DepartmentFilter;
  url: string;
  filterSet: boolean;
} = {
  filterItem: {
    isActive : ""
  },
  url: "/departments/?",
  filterSet: false
};

const departmentFilterSlice = createSlice({
  name: "departmentFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<DepartmentFilter>) => {
      state.filterItem = action.payload;
    },
    setURL: (state) => {
      state.url = `/departments/?&is_active=${state.filterItem.isActive}`;
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.isActive = "";
    },
    isFilterSet: (state) => {
      if (
        !state.filterItem.isActive
      ) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    }
  },
});

export const departmentFilterAction = departmentFilterSlice.actions;
export default departmentFilterSlice;
