import { Fragment } from "react";
import TableCell from "@mui/material/TableCell";
import TranslationField from "../../../type/translationField";
import { useTranslation } from "react-i18next";

const RegisterTableItem: React.FC<{
  name_transes: TranslationField;
  type_transes: TranslationField;
  trees_count: number;
  dead_trees?: number;
}> = ({ name_transes, type_transes, trees_count, dead_trees }) => {
  const { i18n } = useTranslation();
  const name = name_transes?.fr === "" ? name_transes.en : name_transes.fr;
  return (
    <Fragment>
      <TableCell>{i18n.language === "fr" ? name : name_transes.en}</TableCell>
      <TableCell>
        {i18n.language === "fr" ? type_transes.fr : type_transes.en}
      </TableCell>
      <TableCell align="center">{trees_count}</TableCell>
      <TableCell align="center">{dead_trees}</TableCell>
    </Fragment>
  );
};

export default RegisterTableItem;
