import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CollaboratorName from "../type/collaboratorName";

type PlantingFilter = {
  collaboratorType: string;
  collaborator: CollaboratorName[];
  dateFrom: string;
  dateTo: string;
  partiFrom: string;
  partiTo: string;
  department: CollaboratorName | null | undefined;
  type: string;
};
const initialState: {
  filterItem: PlantingFilter;
  url: string;
  filterSet: boolean;
  deleteFilter:
    | "type"
    | "date"
    | "par"
    | "collaborator"
    | "department"
    | "collaboratorType"
    | "";
} = {
  filterItem: {
    dateFrom: "",
    dateTo: "",
    partiFrom: "",
    partiTo: "",
    type: "",
    department: null,
    collaborator: [],
    collaboratorType: "",
  },
  url: "/?",
  filterSet: false,
  deleteFilter: "",
};

const plantingFilterSlice = createSlice({
  name: "plantingFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<PlantingFilter>) => {
      state.filterItem = action.payload;
      state.deleteFilter = "";
    },
    removeDate: (state) => {
      state.filterItem.dateFrom = "";
      state.filterItem.dateTo = "";
      state.deleteFilter = "date";
    },
    removeParticipants: (state) => {
      state.filterItem.partiFrom = "";
      state.filterItem.partiTo = "";
      state.deleteFilter = "par";
    },
    removeDepartment: (state) => {
      state.filterItem.department = null;
      state.deleteFilter = "department";
    },
    removeType: (state) => {
      state.filterItem.type = "";
      state.deleteFilter = "type";
    },
    removeCollaborator: (state) => {
      state.filterItem.collaborator = [];
      state.deleteFilter = "collaborator";
    },
    removeCollaboratorType: (state) => {
      state.filterItem.collaboratorType = "";
      state.filterItem.collaborator = [];
      state.deleteFilter = "collaboratorType";
    },
    setURL: (state) => {
      let coll = "";
      if (state.filterItem.collaborator.length !== 0) {
        state.filterItem.collaborator.forEach((c) => {
          coll += `organizers=${c.slug}&`;
        });
      }
      state.url = `/?organizer_type=${
        state.filterItem.collaboratorType
      }&date_from=${state.filterItem.dateFrom}&date_to=${
        state.filterItem.dateTo
      }&department=${
        state.filterItem.department ? state.filterItem.department.slug : ""
      }&${coll}&participants_from=${
        state.filterItem.partiFrom
      }&participants_to=${state.filterItem.partiTo}&type=${
        state.filterItem.type
      }`;
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.dateFrom = "";
      state.filterItem.dateTo = "";
      state.filterItem.partiFrom = "";
      state.filterItem.partiTo = "";
      state.filterItem.type = "";
      state.filterItem.collaboratorType = "";
      state.filterItem.collaborator = [];
      state.filterItem.department = null;
      state.deleteFilter = "";
    },
    isFilterSet: (state) => {
      if (
        state.filterItem.dateFrom === "" &&
        state.filterItem.dateTo === "" &&
        state.filterItem.type === "" &&
        state.filterItem.partiFrom === "" &&
        state.filterItem.partiTo === "" &&
        state.filterItem.collaboratorType === "" &&
        !state.filterItem.department &&
        state.filterItem.collaborator.length === 0
      ) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const plantingFilterAction = plantingFilterSlice.actions;
export default plantingFilterSlice;
