import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAxios from "../../../../hook/useAxios";
import WarningRedAlert from "../../../ErrorAndAlert/WarningRedAlert";
import ActionItem from "../../../Global/ActionItem";
import { DistroDetail } from "../../../../type/distroDetail";
import AddTreeDistro from "./AddTreeDistro";
const TreeDistroTableItem: React.FC<{
  distroDetail: DistroDetail;
  fetchData: () => void;
  lastIndex: number;
}> = ({ distroDetail, fetchData: propFetchData, lastIndex }) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const [editData, setEditData] = useState(null);

  const { i18n, t } = useTranslation();
  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
    `/api/admin/distributes/${distroDetail.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );
  const { fetchData: editMunicipality, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL +
    `/api/admin/distributes/${distroDetail.slug}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );
  const { fetchData: getMunicipality, response: getEditResponse } = useAxios(
    process.env.REACT_APP_API_URL +
    `/api/admin/distributes/${distroDetail.slug}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (getEditResponse) {
      setEditData(getEditResponse);
    }
  }, [getEditResponse]);

  useEffect(() => {
    if (editResponse) {
      propFetchData();
      toast.success(t("Message.Successful update"));
      setShowEditModal(false);
    }
  }, [editResponse]);

  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    isShowDelete(true);
  };

  const editAction = () => {
    getMunicipality({});
    setShowEditModal(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };

  return (
    <Fragment>
      <TableCell align="left">
        {i18n.language === "fr"
          ? distroDetail.species_name_transes.fr === ""
            ? distroDetail.species_name_transes.en
            : distroDetail.species_name_transes.fr
          : distroDetail.species_name_transes.en}
      </TableCell>
      <TableCell>
        {distroDetail.number}
      </TableCell>
      <TableCell>
        {distroDetail.community}
      </TableCell>
      <TableCell >
        {i18n.language === "fr"
          ? distroDetail.recipient_type_transes.fr === ""
            ? distroDetail.recipient_type_transes.en
            : distroDetail.recipient_type_transes.fr
          : distroDetail.recipient_type_transes.en}
      </TableCell>
      <TableCell>
        {distroDetail.number_received}
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={true}
          editAction={editAction}
          deleteAction={deleteAction}
          view=""
          viewLink=""
          historyLink=""
          viewHistory=""
          isEditModal
        />
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={t("Events.Tree distribution detail")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
      {showEditModal && editData && (
        <AddTreeDistro
          handleModalClose={handleModalClose}
          send={editMunicipality}
          textButton={t("Tree Species.Update")}
          title={t("Events.Tree distribution detail")}
          getInfo={editData}
        />
      )}
    </Fragment>
  );
};

export default TreeDistroTableItem;
