import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import { Planting } from "../../../type/planting";
import WorkshopParticipants from "../../Workshops/WorkshopList/WorkshopParticipants";
import InfoPopover from "../../Register/RegisterList/InfoPopover";
import OrganizerName from "./OrganizerName";
import PlantingActionMenu from "./PlantingActionMenu";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const PlantingTableItem: React.FC<{
  planting: Planting;
  fetchData: () => void;
  lastIndex: number;
}> = ({ planting, fetchData: propFetchData, lastIndex }) => {
  const { t, i18n } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);

  const index = lastIndex >= 0 ? true : false;

  const scroll = lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
    `/api/admin/plantings/${planting.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );

  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (!planting.is_deletable) {
      if ((planting.type_transes.en || planting.type_transes.fr) === t("Events.Planting Tree")) {
        toast.error(
          t(
            "Message.You can’t delete the event. It contains some registers."
          )
        );
      } else if ((planting.type_transes.en || planting.type_transes.fr) === t("Events.Nursery Establishment")) {
        toast.error(
          t(
            "Message.You can’t delete the event. It contains details of the trees supplied in the nursery."
          )
        );
      }
    } else {
      isShowDelete(true);
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const name =
    planting.name_transes.fr === ""
      ? planting.name_transes.en
      : planting.name_transes.fr;

  // Convert the string date to a Date object
  const getPlantingDate = () => {
    const plantingDate = new Date(
      planting.date.split("/").reverse().join("-") // Convert to "yyyy-mm-dd" format
    );
    return plantingDate;
  };

  return (
    <Fragment>
      <TableCell align="left">
        <Link
          className="hover:text-[#0884B8]"
          to={`/events/view-event/${planting.slug}`}
        >
          <div
            title={i18n.language === "fr" ? name : planting.name_transes.en}
            className="name-overflow"
          >
            {i18n.language === "fr" ? name : planting.name_transes.en}
          </div>
        </Link>
      </TableCell>
      <TableCell align="left">
        <div
          title={i18n.language === "fr" ? name : planting.type_transes.en}
          className="name-overflow"
        >
          {i18n.language === "fr" ? name : planting.type_transes.en}
        </div>
      </TableCell>
      <TableCell align="left">{planting.date}</TableCell>
      <TableCell align="left">
        <div className=" flex items-center">
          {" "}
          {planting.department}
          {planting.description_transes.en !== "" && (
            <InfoPopover
              index={index}
              scroll={scroll}
              desc={planting.description_transes}
            />
          )}
        </div>
      </TableCell>
      <TableCell align="left">{planting.municipality}</TableCell>
      <TableCell align="left">{planting.community}</TableCell>
      <TableCell align="left">
        <OrganizerName
          organizers={planting.organizers}
          index={index}
          scroll={scroll}
        />
      </TableCell>
      <TableCell align="left">
        <WorkshopParticipants
          totalNum={planting.participants_total}
          others={planting.other_participants}
          psaS={planting.psa_students}
          psaT={planting.psa_tutors}
          schoolS={planting.school_students}
          schoolT={planting.school_tutors}
          farmers={planting.farmers}
          promoters={planting.promoters}
          junior_youth_parents={planting.junior_youth_parents}
          community_leaders={planting.community_leaders}
          index={index}
          scroll={scroll}
        />
      </TableCell>
      <TableCell align="center">
        <PlantingActionMenu
          deleteAction={deleteAction}
          plantedTrees={planting.planted_trees}
          registeredTrees={planting.registered_trees}
          slug={planting.slug}
          plantingDate={getPlantingDate()}
          initials={planting?.community_initials}
          cSlug={planting?.community_slug}
          type={
            i18n.language === "en"
              ? planting?.type_transes.en
              : planting?.type_transes.fr
          }
          hasRegister={planting?.has_register}
        />
      </TableCell>
      {showDelete && (
        <DeleteAlert
          message={
            planting.registered_trees > 0
              ? t("Message.Delete planting with its registers alert") + ""
              : ""
          }
          type={t("Plantings.Planting")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default PlantingTableItem;
