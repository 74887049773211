import { Fragment, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import {
  faPencil,
  faTrashCan,
  faPlus,
  faEye,
  faFileImport,
} from "@fortawesome/pro-light-svg-icons";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { profileInfo } from "../../../store";

const PlantingActionMenu: React.FC<{
  slug: string;
  plantedTrees: number;
  registeredTrees: number;
  deleteAction: () => void;
  plantingDate: Date;
  cSlug: string | undefined;
  initials: string | undefined;
  type: string;
  hasRegister: boolean;
}> = ({
  slug,
  plantedTrees,
  registeredTrees,
  deleteAction,
  plantingDate,
  cSlug,
  initials,
  type,
  hasRegister,
}) => {
    const [showAction, isShowAction] = useState<boolean>(false);
    const profile = useSelector(profileInfo);
    const divRef = useRef<HTMLDivElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);
    const { i18n, t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
      document.addEventListener("click", handelClick, true);
    }, []);
    const toggleAction = () => {
      isShowAction(!showAction);
    };

    const handelClick = (e: any) => {
      if (
        !divRef.current?.contains(e.target) &&
        !btnRef.current?.contains(e.target)
      ) {
        isShowAction(false);
      }
    };

    const addRegisterAction = () => {
      if (plantedTrees + 20 <= registeredTrees) {
        toast.error(
          t(
            "Message.The number of registered trees shouldn’t exceed the planted trees by up to 20."
          )
        );
      } else {
        history.replace(`/registers/add-register/${slug}/${cSlug}/${initials}`);
      }
    };

    const importRegisterAction = () => {
      history.replace(`/tools/imports/${slug}`);
    };

    return (
      <div className="relative">
        <Fragment>
          <button
            ref={btnRef}
            onClick={toggleAction}
            className="text-[#979797] px-3 py-1 rounded-md hover:bg-ph-bgIcon hover:text-ph-btn"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </button>
          {showAction && (
            <div
              ref={divRef}
              className={`${i18n.language === "en" ? "w-[175px]" : "w-max"
                } absolute z-10 right-1 h-fit shadow-lg rounded-md text-center bg-white px-4 py-1`}
            >
              {profile.role !== "V" && (
                <Fragment>
                  {type === t("Events.Planting Tree") && (
                    <button
                      onClick={addRegisterAction}
                      className="text-sm text-default-text flex items-center py-[7px]"
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-2" />
                      {t("Plantings.Add register")}
                    </button>
                  )}
                  {type === t("Events.Planting Tree") && (
                    <button
                      onClick={importRegisterAction}
                      className="text-sm text-default-text flex items-center py-[7px]"
                    >
                      <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                      {t("Import register.Import registers")}
                    </button>
                  )}
                </Fragment>
              )}

              {hasRegister && type === t("Events.Planting Tree") && (
                <>
                  <Link
                    to={`/registers/${slug}`}
                    className="text-sm text-default-text flex items-center py-[7px]"
                  >
                    <FontAwesomeIcon icon={faEye} className="mr-2" />{" "}
                    {t("Plantings.View registers")}
                  </Link>
                  <hr />
                </>
              )}
              <Link
                to={`/events/view-event/${slug}`}
                className="text-sm text-default-text flex items-center py-[7px]"
              >
                <FontAwesomeIcon icon={faEye} className="mr-2" />
                {t("Events.View event")}
              </Link>
              {(profile.role === "A" || profile.role === "C") && (
                <Fragment>
                  <Link
                    to={`/events/edit-event/${slug}`}
                    className="text-sm text-default-text flex items-center py-[7px]"
                  >
                    <FontAwesomeIcon icon={faPencil} className="mr-2" />
                    {t("Events.Edit event")}
                  </Link>
                  <button
                    onClick={deleteAction}
                    className="text-sm text-default-text flex items-center py-[7px]"
                  >
                    <FontAwesomeIcon icon={faTrashCan} className="mr-2" />{" "}
                    {t("Events.Delete event")}
                  </button>
                </Fragment>
              )}
            </div>
          )}
        </Fragment>
      </div>
    );
  };

export default PlantingActionMenu;
