import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { AddSpeciesDropdown } from "../../../type/addSpeciesDropDown";
import { theme } from "../../Register/AddRegister/FormTheme";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import file from "../../../type/file";
import { SpeciesBaseId } from "../../../type/SpeciesBaseId";
import TranslationField from "../../../type/translationField";
import DragDropField from "../../Global/DragDropField";
import useAxios from "../../../hook/useAxios";
import OldFile from "../../Global/OldFile";
import LanguagePoint from "../../Global/LanguagePoint";
import SelectLanguage from "../../Global/SelectLanguage";
import Warning from "../../ErrorAndAlert/Warning";
import { SNames } from "../../../type/sNames";
import { SpeciesBaseSlug } from "../../../type/SpeciesBaseSlug";
import { BotanicalFamily } from "../../../type/botanicalFamily";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import Status from "../../Global/Status";

type FormValues = {
  type: SpeciesBaseId;
  botanical: BotanicalFamily;
  abundance: string;
  floweringMonth: SpeciesBaseId[];
  harvestingMonths: SpeciesBaseId[];
  name: SNames;
  sName: SpeciesBaseSlug;
};

const AddSpeciesForm: React.FC<{
  dropDown: AddSpeciesDropdown;
  names: SNames[];
  botanicalFamilies: BotanicalFamily[];
  send: (data: any) => void;
  sendResponse: any;
  sendError: any;
  getInfo?: any;
  textButton: string;
}> = ({
  dropDown,
  names,
  botanicalFamilies,
  send,
  sendResponse,
  sendError,
  getInfo,
  textButton,
}) => {
    const [lang, setLang] = useState<string>("EN");
    const [description, setDescription] = useState<TranslationField>({
      en: "",
      fr: "",
    });
    const { i18n, t } = useTranslation();
    const [active, isActive] = useState<boolean>(true);
    const [resources, setResources] = useState<file[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [uploading, isUploading] = useState<boolean>(false);
    const [oldResource, setOldResource] = useState<
      {
        id: number;
        file_address: string;
      }[]
    >([]);
    const [deleteRecourse, setDeleteResource] = useState<number[]>([]);
    const [featureIcon, setFeatureIcon] = useState<file[]>([]);
    const [oldFeatureIcon, setOldFeatureIcon] = useState<string>("");
    const [requestNumber, setRequestNumber] = useState<number>(1);
    const [scientificNames, setScientificName] = useState<SpeciesBaseSlug[]>([]);
    const [dirty, isDirty] = useState<boolean>(true);
    const [fileIndex, setFileIndex] = useState<number>(0);
    const { fetchData: sendFile, response: fileResp } = useAxios(
      process.env.REACT_APP_API_URL + "/api/admin/files/new/",
      "POST",
      false,
      "",
      true,
      true,
      "multipart/form-data"
    );
    const { fetchData: deleteImage } = useAxios(
      "",
      "DELETE",
      false,
      "",
      true,
      true
    );
    const {
      handleSubmit,
      getValues,
      setValue,
      control,
      resetField,
      reset,
      formState: { errors },
    } = useForm<FormValues>();

    const history = useHistory();
    const handleLangChange = (event: SelectChangeEvent) => {
      setLang(event.target.value);
    };
    useEffect(() => {
      if (sendResponse) {
        setRequestNumber((prevState) => prevState - 1);
        if (resources.length > 0) {
          const formData = new FormData();
          formData.append("file_address", resources[fileIndex].data);
          formData.append("model_name", "Species");
          formData.append("input_slug", sendResponse.data.slug);
          sendFile(formData);
        }
        if (deleteRecourse.length > 0) {
          deleteRecourse.forEach((r) => {
            setRequestNumber((prevState) => prevState - 1);
            deleteImage(
              { id: r },
              process.env.REACT_APP_API_URL + `/api/admin/files/${r}/delete/`
            );
          });
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendResponse]);

    useEffect(() => {
      if (getInfo && names.length > 0) {
        const index = names.findIndex(
          (speciesName) =>
            speciesName.name_transes.en === getInfo.data.name_transes.en
        );
        setDescription({
          en: getInfo.data.description_transes.en,
          fr: getInfo.data.description_transes.fr,
        });
        setOldResource(getInfo.data.files);
        setOldFeatureIcon(getInfo.data.featured_icon);
        setScientificName(names[index]?.scientific_name_transes);
        setValue("name", {
          name_transes: getInfo.data.name_transes,
          scientific_name_transes: names[index].scientific_name_transes,
        });
        setValue("sName", {
          en: getInfo.data.scientific_name_transes.en,
          fr: getInfo.data.scientific_name_transes.fr,
          slug: getInfo.data.scientific_name_slug,
        });
        setValue("floweringMonth", getInfo.data.flowering_months);
        setValue("abundance", getInfo.data.abundance);
        setValue("harvestingMonths", getInfo.data.harvesting_months);
        setValue("type", {
          id: getInfo.data.type_id,
          en: getInfo.data.type_name_transes.en,
          fr: getInfo.data.type_name_transes.fr,
        });
        isActive(getInfo.data.is_active_transes.en === "Active" ? true : false);
        if (getInfo.data?.botanical_family_id) {
          setValue("botanical", {
            id: getInfo.data?.botanical_family_id,
            name_transes: {
              en: getInfo.data?.botanical_family_transes?.en,
              fr: getInfo.data?.botanical_family_transes?.fr,
            },
          });
        }
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getInfo, names]);

    useEffect(() => {
      if (fileResp) {
        setRequestNumber((prevState) => prevState - 1);
        setFileIndex(fileIndex + 1);
        if (resources.length - 1 >= fileIndex + 1) {
          const formData = new FormData();
          formData.append("file_address", resources[fileIndex + 1].data);
          formData.append("model_name", "Species");
          formData.append("input_slug", sendResponse.data.slug);
          sendFile(formData);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileResp]);

    useEffect(() => {
      if (requestNumber === 0) {
        reset();
        toast.success(
          `${getInfo
            ? `${t("Message.Successful update")}`
            : `${t("Message.Successful add")}`
          }`
        );
        history.replace("/setting/tree-species");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestNumber]);

    useEffect(() => {
      if (sendError) {
        if (sendError.response.status === 400) {
          isUploading(false);
        }
      }
    }, [sendError]);

    const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (lang === "EN") {
        setDescription({ en: event.target.value, fr: description.fr });
      } else if (lang === "FR") {
        setDescription({ fr: event.target.value, en: description.en });
      }
    };

    const insertResources = (files: file[]) => {
      files.forEach((f) => {
        if (!f.type.includes("image")) {
          toast.error(`${t("Message.Image supported formats")}`);
        } else if (f.size > 10000000) {
          toast.error(`${t("Message.Maximum file size is 10 MB")}`);
        } else {
          setRequestNumber((prevState) => prevState + 1);
          setResources((prevState) => [...prevState, f]);
        }
      });
    };

    const removeOldFeaturedIcon = (index: number) => {
      setOldFeatureIcon("");
    };
    const removeOldResource = (fileId: number) => {
      setDeleteResource((prevState) => [...prevState, fileId]);
      setOldResource((prevState) =>
        prevState.filter((prevItem) => prevItem.id !== fileId)
      );
      setRequestNumber((prevState) => prevState + 1);
    };
    const removeResource = (indexFile: number) => {
      setRequestNumber((prevState) => prevState - 1);
      setResources((prevState) =>
        prevState.filter((prevItem, index: number) => index !== indexFile)
      );
    };

    const insertFile = (newFile: file[]) => {
      if (!newFile[0].type.includes("image")) {
        toast.error(`${t("Message.Image supported formats")}`);
      } else if (newFile[0].size > 5000000) {
        toast.error(`${t("Message.Maximum file size is 5 MB")}`);
      } else {
        setFeatureIcon([newFile[0]]);
        setOldFeatureIcon("");
      }
    };
    const removeFile = (index: number) => {
      setFeatureIcon([]);
    };
    const addSpeciesItem = (data: FormValues) => {
      if (description.fr && !description.en) {
        toast.error(`${t("Message.Empty default language error")}`);
      } else {
        const hMonths: string[] = data.harvestingMonths?.map((c) => c.id);
        const fMonths: string[] = data.floweringMonth?.map((c) => c.id);
        isDirty(false);
        const formData = new FormData();
        formData.append("type", data.type.id);
        if (data.botanical) {
          formData.append("botanical_family", data.botanical?.id + "");
        }
        formData.append("scientific_name_slug", data.sName.slug);
        formData.append("abundance", data.abundance);
        if (hMonths?.length > 0) {
          hMonths.forEach((month: string, index: number) => {
            formData.append("harvesting_months", month);
          });
        }
        if (fMonths?.length > 0) {
          fMonths.forEach((month: string, index: number) => {
            formData.append("flowering_months", month);
          });
        }
        if (!oldFeatureIcon && featureIcon.length !== 0) {
          formData.append("featured_icon", featureIcon[0]?.data);
        } else if (!oldFeatureIcon && featureIcon.length === 0) {
          formData.append("featured_icon", "");
        }
        formData.append("description_transes", JSON.stringify(description));
        formData.append("is_active", active ? "True" : "False");
        isUploading(true);
        send(formData);
      }
    };
    return (
      <Fragment>
        {loading && getInfo ? (
          <p className="text-center text-2xl font-medium text-default-text">
            {t("Message.Loading")} ...
          </p>
        ) : (
          <form onSubmit={handleSubmit(addSpeciesItem)}>
            <ThemeProvider theme={theme}>
              <p className="text-sm text-default-text mb-[6px]">
                {t("AddPlanting.Language")}
              </p>
              <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
              <div className="flex flex-wrap justify-between mt-7">
                <div className="w-full sm:w-1/2 pr-2">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Tree Species.Name")} *
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="name"
                    rules={{ required: `${t("Message.Required field")}` }}
                    selectOptions={names}
                    placeholder={t("Tree Species.Tree name")}
                    getOptionLabel={(option) =>
                      i18n.language === "fr"
                        ? option.name_transes?.fr || option.name_transes?.en
                        : option.name_transes?.en
                    }
                    onCustomChange={(newValue: SNames | SNames[] | null) => {
                      if (newValue && !Array.isArray(newValue)) {
                        setScientificName(newValue?.scientific_name_transes);
                        resetField("sName");
                      }
                    }}
                  />
                  {errors.name && (
                    <p className="error-text">{t("Message.Required field")}</p>
                  )}
                </div>
                <div className="w-full sm:w-1/2 sm:pl-2 mt-7 sm:mt-0">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Tree Species.Scientific name")} *
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="sName"
                    rules={{ required: `${t("Message.Required field")}` }}
                    selectOptions={scientificNames}
                    placeholder={t("Tree Species.Scientific name")}
                    disabled={getValues("name") ? false : true}
                    getOptionLabel={(option) =>
                      i18n.language === "fr"
                        ? option?.fr || option?.en
                        : option?.en
                    }
                  />
                  {errors.sName && (
                    <p className="error-text">{t("Message.Required field")}</p>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap justify-between mt-7">
                <div className="w-full md:w-1/2 md:pr-2">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Tree Species.Type")} *
                  </p>
                  <CustomAutocomplete
                    rules={{ required: `${t("Message.Required field")}` }}
                    control={control}
                    name="type"
                    selectOptions={dropDown.species_type_transes}
                    placeholder={t("Tree Species.Type")}
                    getOptionLabel={(option) =>
                      i18n.language === "fr" ? option?.fr : option?.en
                    }
                  />
                  {errors.type && (
                    <p className="error-text">{t("Message.Required field")}</p>
                  )}
                </div>
                <div className="w-full md:w-1/2 md:pl-2 mt-7 md:mt-0">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Tree Species.Botanical family")}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="botanical"
                    selectOptions={botanicalFamilies}
                    placeholder={t("Tree Species.Botanical family")}
                    getOptionLabel={(option) =>
                      i18n.language === "fr"
                        ? option.name_transes?.fr || option.name_transes?.en
                        : option.name_transes?.en
                    }
                  />
                </div>
              </div>
              <div className="flex flex-wrap justify-between mt-7">
                <div className="w-full md:w-1/2 md:pr-2">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Tree Species.Abundance")} *
                  </p>
                  <Controller
                    control={control}
                    name="abundance"
                    rules={{ required: `${t("Message.Required field")}` }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {value === undefined &&
                            `${t("Tree Species.Abundance")}`}
                        </InputLabel>
                        <Select
                          style={{ height: "48px" }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value}
                          autoFocus={errors.abundance ? true : false}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                          }}
                        >
                          {dropDown?.abundance_choices_transes.map((ab) => (
                            <MenuItem key={ab.value} value={ab.value}>
                              {i18n.language === "fr" ? ab.fr : ab.en}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {errors.abundance && (
                    <p className="error-text">{t("Message.Required field")}</p>
                  )}
                </div>
                <div className="w-full md:w-1/2 md:pl-2 mt-7 md:mt-0">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Tree Species.Flowering season")}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="floweringMonth"
                    defaultValue={getValues("floweringMonth")}
                    isMulti={true}
                    selectOptions={dropDown.month_choices_transes}
                    placeholder={t("Tree Species.Flowering season")}
                    getOptionLabel={(option) =>
                      i18n.language === "fr" ? option?.fr : option?.en
                    }
                  />
                </div>
              </div>
              <div className="flex flex-wrap flex-row ">
                <div className="w-full sm:w-1/2 pr-2">
                  <p className="text-sm text-ph-dark-text mb-[6px] mt-7">
                    {t("Tree Species.Harvesting months")}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="harvestingMonths"
                    defaultValue={getValues("harvestingMonths")}
                    isMulti={true}
                    selectOptions={dropDown.month_choices_transes}
                    placeholder={t("Tree Species.Harvesting months")}
                    getOptionLabel={(option) =>
                      i18n.language === "en" ? option?.en : option?.fr
                    }
                  />
                </div>
                <div className="mt-7">
                  <Status
                    active={active}
                    isActive={(value) => {
                      isActive(value);
                    }}
                  />
                </div>
              </div>
              <p className="text-sm text-default-text mt-7 mb-[6px]">
                {t("Tree Species.Description")} <LanguagePoint lang={lang} />
              </p>
              <textarea
                onChange={descriptionChange}
                value={lang === "FR" ? description.fr : description.en}
                rows={7}
                className="input-field resize-none text-sm  pl-4  py-[10px] placeholder-ph-light-gray"
                placeholder={t("Tree Species.Description of tree species") + ""}
              />
            </ThemeProvider>

            <p className="text-sm text-default-text mt-7 mb-[6px]">
              {t("Tree Species.Tree images")}
            </p>
            <DragDropField
              files={resources}
              format="jpg/jpeg, png (max. 10 MB)"
              insertFile={insertResources}
              removeFile={removeResource}
            />
            {oldResource.map((or) => (
              <OldFile
                address={or.file_address}
                id={or.id}
                delete={true}
                removeOldFile={removeOldResource}
                key={or.id}
              />
            ))}

            <p className="text-sm text-default-text mt-7 mb-[6px]">
              {t("Tree Species.Featured icon")}
            </p>
            <DragDropField
              files={featureIcon}
              format="png (128 x 128 pixels) or SVG"
              insertFile={insertFile}
              removeFile={removeFile}
            />

            {oldFeatureIcon && (
              <OldFile
                address={oldFeatureIcon}
                id={0}
                delete={true}
                removeOldFile={removeOldFeaturedIcon}
              />
            )}

            <button
              type="submit"
              className={`${uploading ? "main-btn-disable" : "main-btn"
                } text-sm  px-6 py-2 mt-5 float-right font-medium`}
              disabled={uploading ? true : false}
            >
              {uploading ? t("Message.Uploading files") : textButton}
            </button>
          </form>
        )}
        <Warning when={dirty} onCancel={() => false} onOK={() => true} />
      </Fragment>
    );
  };

export default AddSpeciesForm;
