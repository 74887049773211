import React, { useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import file from "../../type/file";
import FileAccepted from "../Register/AddRegister/FileAccepted";
import { colors } from "../../styles/colors";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "24px",
  borderWidth: 1,
  borderRadius: 2,
  borderColor: colors.light_gray,
  backgroundColor: "#FCFCFC",
  border: "1px dashed",
  color: colors.light_gray,
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: colors.default_highlight,
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DragDropZipFile: React.FC<{
  format: string;
  removeFile: (index: number) => void;
  insertFile: (file: file[]) => void;
  files: file[];
  mandatoryError?: boolean;
}> = ({ format, removeFile, insertFile, files, mandatoryError }) => {
  const { t } = useTranslation();

  const removeAction = (fileIndex: number) => {
    removeFile(fileIndex);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "image/jpeg": [
          ".jpeg",
          ".png",
          ".svg",
          ".pdf",
          ".docx",
          ".doc",
          ".xlsx",
          ".xls",
          ".csv",
          ".txt",
          ".zip",
        ],
      },
      maxSize: 50 * 1024 * 1024, // 50 MB
      onDrop: (acceptedFiles: any) => {
        // If there's already a file, remove it before adding a new one
        if (files.length > 0) {
          removeFile(0);
        }

        let f: file[] = [];
        for (const accFile of acceptedFiles) {
          f.push({
            name: accFile.name,
            size: accFile.size,
            url: URL.createObjectURL(accFile),
            type: accFile.type,
            data: accFile,
          });
        }
        insertFile(f);
      },
    });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject || mandatoryError ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, mandatoryError]
  );

  // Only display the first file in the list (since we're limiting to one file)
  const fileToDisplay = files[0];

  const thumb = fileToDisplay && fileToDisplay.type !== "deleted" && (
    <FileAccepted
      fileName={fileToDisplay.name}
      fileSize={fileToDisplay.size}
      fileUrl={fileToDisplay.url}
      fileIndex={0}
      fileType={fileToDisplay.type}
      key={0}
      removeAction={removeAction}
    />
  );

  useEffect(() => {
    return () =>
      files.forEach((fileI) => {
        if (fileI.url !== "") {
          URL.revokeObjectURL(fileI.url);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="text-center text-xs text-ph-light-gray">
          {t("Message.Drag & Drop or")}{" "}
          <span className="text-ph-btn">{t("Message.Click")}</span>{" "}
          {t("Message.to browse")}
          <br /> {format}
        </p>
      </div>
      {mandatoryError && (
        <p className="error-text">{t("Message.Required field")}</p>
      )}
      <aside
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: 16,
        }}
      >
        {thumb}
      </aside>
    </section>
  );
};

export default DragDropZipFile;
