import Slider from "../../Global/Slider";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import SpeciesList from "../../../type/speciesList";
import useAxios from "../../../hook/useAxios";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../Global/BreadCrumb";
import AddRegisterForm from "./AddRegisterForm";
import PlantingList from "../../../type/plantingList";
import { Tags } from "../../../type/tag";
import { ResponsibleNames } from "../../../type/responsibleNames";

const AddRegister = () => {
  const [selectOption, setSelectOption] = useState<{
    treeSpecies: SpeciesList[];
    incidents: SpeciesList[];
    nursery: SpeciesList[];
    plantings: PlantingList[];
    tags: Tags[];
    resName: ResponsibleNames[];
  }>({
    treeSpecies: [],
    incidents: [],
    nursery: [],
    plantings: [],
    tags: [],
    resName: [],
  });
  const { t } = useTranslation();
  const { id, slug } = useParams<any>();
  const {
    fetchData,
    response: sendRegisterResponse,
    error,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/new/`,
    "POST",
    false,
    "",
    true,
    true
  );

  const { response: treeSpeciesRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    true,
    false
  );
  const { response: breadCrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${id}/breadcrumb-data/`,
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: incidentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/incidents/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: nurseryRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/nurseries/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { fetchData: getTags, response: tagsRes } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/${id}/tags/all/`,
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: responsibleRes } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/${slug}/responsible_names/all`,
    "GET",
    true,
    "",
    false,
    false
  );

  useEffect(() => {
    if (treeSpeciesRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          treeSpecies: treeSpeciesRes.data,
        };
      });
    }

    if (incidentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          incidents: incidentRes.data,
        };
      });
    }
    if (nurseryRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          nursery: nurseryRes.data,
        };
      });
    }
    if (tagsRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          tags: tagsRes.data,
        };
      });
    }
    if (responsibleRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          resName: responsibleRes.data,
        };
      });
    }
  }, [treeSpeciesRes, incidentRes, nurseryRes, tagsRes, responsibleRes]);

  useEffect(() => {
    getTags({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRegisterResponse]);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Plantings.Add register")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Events"), url: "/events" },
          {
            name: `${breadCrumb?.data.date}-${breadCrumb?.data.department_name}`,
            url: `/registers/${id}`,
          },
          { name: t("Plantings.Add register"), url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddRegisterForm
            selectOptions={selectOption}
            send={fetchData}
            sendResponse={sendRegisterResponse}
            sendError={error}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] sc:h-[529px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <Slider
            text={true}
            images={["/img/Add-register-1.svg", "/img/Add-register-2.svg"]}
          />
        </div>
      </div>
    </div>
  );
};

export default AddRegister;
