import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RegisterTableContent from "./RegisterTableContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiagramProject } from "@fortawesome/pro-solid-svg-icons";
import { faSkull } from "@fortawesome/pro-duotone-svg-icons";

import { faTreeDeciduous } from "@fortawesome/pro-duotone-svg-icons";
import TranslationField from "../../../type/translationField";

const RegisterStatistics: React.FC<{ response: any; showDead: boolean }> = ({
  response,
  showDead,
}) => {
  const [registerStatistics, setRegisterStatistics] = useState<{
    species_count: number;
    total_trees: number;
    total_dead_trees: number;
    species_list: {
      name_transes: TranslationField;
      type_transes: TranslationField;
      trees_count: number;
      dead_trees: number;
    }[];
  }>({
    species_count: 0,
    total_trees: 0,
    species_list: [],
    total_dead_trees: 0,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (response) {
      setRegisterStatistics(response?.data);
    }
  }, [response]);

  return (
    <Fragment>
      <h1 className="font-semibold text-default-text text-sm">
        {t("Register.Register")}
      </h1>
      <div className="flex flex-wrap md:flex-nowrap justify-between mt-5">
        <div className="flex mb-2 md:mb-0 w-full">
          <div className="bg-white w-[49%] rounded-md p-8 text-ph-orange-alert flex justify-center items-center mr-2 ">
            <FontAwesomeIcon
              icon={faDiagramProject}
              className="mr-6 text-[28px]"
            />
            <span>
              <p className="text-center text-sm font-semibold">
                {registerStatistics.species_count}
              </p>
              <p className="text-sm font-medium">{t("Statistics.Species")}</p>
            </span>
          </div>
          <div
            className={`bg-white w-[49%] rounded-md p-8 text-ph-toggle-text-blue flex justify-center items-center ${
              showDead ? "md:mr-2" : "md:mr-0"
            }`}
          >
            <FontAwesomeIcon
              icon={faTreeDeciduous}
              className="mr-6 text-[28px]"
            />
            <span>
              <p className="text-center text-sm font-semibold">
                {registerStatistics.total_trees}
              </p>
              <p className="text-sm font-medium">{t("Statistics.Trees")}</p>
            </span>
          </div>
        </div>
        {showDead && (
          <div className="bg-white  md:w-[49%] w-full rounded-md p-8 text-[#FFC1C5] flex justify-center items-center">
            <FontAwesomeIcon icon={faSkull} className="mr-6 text-[28px]" />
            <span>
              <p className="text-center text-sm font-semibold">
                {registerStatistics.total_dead_trees}
              </p>
              <p className="text-sm font-medium">
                {t("Statistics.Dead trees")}
              </p>
            </span>
          </div>
        )}
      </div>
      <div className="w-full mt-2 bg-white rounded-md">
        {registerStatistics?.species_list && (
          <RegisterTableContent
            showDead={showDead}
            registers={registerStatistics?.species_list}
          />
        )}
      </div>
    </Fragment>
  );
};

export default RegisterStatistics;
