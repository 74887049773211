import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import OldFile from "../../Global/OldFile";
import EditSlider from "../../Global/EditSlider";
import TranslationField from "../../../type/translationField";
import Loading from "../../Global/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faLandmark,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";

type FormValues = {
  slug: string;
  name_transes: TranslationField;
  department_name: string;
  municipality_name: string;
  community_name: string;
  date_from: string;
  date_to: string;
  time_from: string;
  time_to: string;
  psa_students: number;
  school_students: number;
  psa_tutors: number;
  school_tutors: number;
  farmers: number;
  promoters: number;
  junior_youth_parents: number;
  community_leaders: number;
  other_participants: number;
  description_transes: TranslationField;
  files: {
    id: number;
    file_address: string;
    thumbnail_address: string;
  }[];
};

const ViewWorkshop = () => {
  const [workshop, setWorkshop] = useState<FormValues>({
    slug: "",
    name_transes: { en: "", fr: "" },
    department_name: "",
    municipality_name: "",
    community_name: "",
    date_from: "",
    date_to: "",
    time_from: "",
    time_to: "",
    psa_students: 0,
    school_students: 0,
    psa_tutors: 0,
    school_tutors: 0,
    farmers: 0,
    promoters: 0,
    junior_youth_parents: 0,
    community_leaders: 0,
    other_participants: 0,
    description_transes: { en: "", fr: "" },
    files: [
      {
        id: 0,
        file_address: "",
        thumbnail_address: "",
      },
    ],
  });

  const { i18n, t } = useTranslation();
  const { id } = useParams<any>();

  const {
    response: getEditResponse,
    fetchData: getWorkshop,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/workshop/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (getEditResponse) {
      setWorkshop(getEditResponse.data);
    }
  }, [getEditResponse]);

  useEffect(() => {
    getWorkshop({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatTime(time: string) {
    const splitTime = time.split(":");
    let hours = parseInt(splitTime[0]);
    let period = "am";

    if (hours >= 12) period = "pm";

    return `${time} ${period}`;
  }

  return (
    <div className="parent-container overflow-x-hidden">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Workshops.View workshop")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          {
            name: t("Workshops.Workshops"),
            url: "/workshops",
          },
          { name: t("Workshops.View workshop"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between">
          <div className="w-full lg:w-[611px] h-fit sc:w-[620px] bg-white rounded-md relative">
            <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100  p-4">
              <div className="flex flex-row mb-8 justify-between">
                <div className="flex flex-col ">
                  <p className="font-medium text-default-text text-[16px] mb-[24px]">
                    {i18n.language === "fr"
                      ? workshop.name_transes.fr || workshop.name_transes.en
                      : workshop.name_transes.en}
                  </p>
                  <div className="flex flex-row">
                    <div className="flex flex-col items-start mr-[35px] md:mr-[110px]">
                      <p className="text-sm mb-2 font-regular text-ph-gray mr-4">
                        {t("Workshops.Start")}
                      </p>
                      <p className="bg-ph-toggle-bg-blue text-sm text-ph-toggle-text-blue text-center opacity-100 py-1 px-4 rounded-[14px]">
                        {workshop.date_from}
                        {workshop.time_from
                          ? ", " + formatTime(workshop.time_from)
                          : ""}
                      </p>
                    </div>
                    <div className="flex flex-col items-start">
                      <p className="mb-2 text-[12px] font-regular text-ph-gray ">
                        {t("Workshops.End")}
                      </p>
                      <p className="bg-ph-toggle-bg-red text-ph-red-warning text-sm text-center opacity-100 py-1 px-4 rounded-[14px]">
                        {workshop.date_to}
                        {workshop.time_to
                          ? ", " + formatTime(workshop.time_to)
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex md:flex-row flex-col mt-[40px] mb-[24px]">
                <div className="flex flex-col items-start text-left mr-[20px] md:mr-[138px]">
                  <div className="flex mb-10 flex-row">
                    <FontAwesomeIcon
                      className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-4"
                      icon={faBuilding}
                    />
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("PlantingFilter.Province")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.department_name}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-10 md:mb-0">
                    <FontAwesomeIcon
                      className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-4"
                      icon={faUsers}
                    />
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Plantings.Community")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.community_name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start text-left">
                  <div className="flex flex-row">
                    <FontAwesomeIcon
                      className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-4"
                      icon={faLandmark}
                    />
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Plantings.District")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.municipality_name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
              <div>
                <p className="text-sm font-semibold text-default-text py-3">
                  {t("PlantingFilter.Participants")}
                </p>
                <div className="flex flex-row justify-start mb-4 mt-[24px]">
                  <div className="flex flex-col mr-[20px] md:mr-[138px]">
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.PSA students")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.psa_students}
                      </p>
                    </div>
                    <div className="flex flex-col mt-[40px]">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.PSA tutors")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.psa_tutors}
                      </p>
                    </div>
                    <div className="flex flex-col mt-[40px]">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.Farmers")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.farmers}
                      </p>
                    </div>
                    <div className="flex flex-col mt-[40px]">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.Promoters")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.promoters}
                      </p>
                    </div>
                    <div className="flex flex-col mt-[40px]">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.Other participants")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.other_participants}
                      </p>
                    </div>
                  </div>
                  <div className="flex mb-10 flex-col">
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.School students")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.school_students}
                      </p>
                    </div>
                    <div className="flex flex-col mt-[40px]">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.School teachers")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.school_tutors}
                      </p>
                    </div>
                    <div className="flex flex-col mt-[40px]">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.Junior youth parents")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.junior_youth_parents}
                      </p>
                    </div>
                    <div className="flex flex-col mt-[40px]">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.Community leaders")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {workshop.community_leaders}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {workshop.files.length > 0 && (
              <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
                <p className="text-sm font-semibold text-default-text py-3 mb-[24px]">
                  {t("Workshops.Images")}
                </p>

                {workshop.files.map((file: any) => (
                  <OldFile
                    address={file.file_address}
                    id={file.id}
                    delete={false}
                    removeOldFile={() => {}}
                    key={file.id}
                  />
                ))}
              </div>
            )}

            {(workshop.description_transes.en !== "" ||
              workshop.description_transes.fr !== "") && (
              <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
                <div>
                  <p className="text-sm font-regular text-ph-gray mb-2">
                    {t("Tree Species.Description")}
                  </p>
                </div>
                <div className="">
                  <p className="text-sm text-default-text font-medium max-w-[500px] max-h-[400px] break-words overflow-y-auto">
                    {i18n.language === "fr"
                      ? workshop.description_transes.fr === ""
                        ? workshop.description_transes.en
                        : workshop.description_transes.fr
                      : workshop.description_transes.en}
                  </p>
                </div>
              </div>
            )}
            <Link
              to="/workshops"
              className="main-btn text-sm  px-6 py-2 mt-5  mb-[40px] mr-[90px] md:mr-[55px] float-right font-medium"
            >
              {t("Workshops.Back to workshops")}
            </Link>
          </div>
          <EditSlider
            images={["/img/Add Workshop1.svg"]}
            response={getEditResponse}
          />
        </div>
      )}
    </div>
  );
};

export default ViewWorkshop;
