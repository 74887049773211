import { useSelector } from "react-redux";
import { profileInfo } from "../../store";
import AccessDenied from "../ErrorAndAlert/AccessDenied";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const profile = useSelector(profileInfo);
  if (profile.role === "O" || profile.role === "V") {
    return <AccessDenied />;
  }

  return children;
};

export default ProtectedRoute;
