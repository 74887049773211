import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAxios from "../../../../hook/useAxios";
import WarningRedAlert from "../../../ErrorAndAlert/WarningRedAlert";
import ActionItem from "../../../Global/ActionItem";
import { CollectionDetails } from "../../../../type/collectionDetail";
import AddSeedCollection from "./AddSeedCollection";
const SeedCollectionTableItem: React.FC<{
  collection: CollectionDetails;
  fetchData: () => void;
  lastIndex: number;
  type: string
}> = ({ collection, fetchData: propFetchData, type }) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const [editData, setEditData] = useState(null);

  const { i18n, t } = useTranslation();
  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
    `/api/admin/seeds/${collection.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );
  const { fetchData: editCollection, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL +
    `/api/admin/seeds/${collection.slug}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );
  const { fetchData: getCollection, response: getEditResponse } = useAxios(
    process.env.REACT_APP_API_URL +
    `/api/admin/seeds/${collection.slug}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (getEditResponse) {
      setEditData(getEditResponse);
    }
  }, [getEditResponse]);

  useEffect(() => {
    if (editResponse) {
      propFetchData();
      toast.success(t("Message.Successful update"));
      setShowEditModal(false);
    }
  }, [editResponse]);

  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    isShowDelete(true);
  };

  const editAction = () => {
    getCollection({});
    setShowEditModal(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };


  return (
    <Fragment>
      <TableCell align="left">
        {i18n.language === "fr"
          ? collection.species_name_transes.fr === ""
            ? collection.species_name_transes.en
            : collection.species_name_transes.fr
          : collection.species_name_transes.en}
      </TableCell>
      <TableCell>
        {collection.quantity}
      </TableCell>
      <TableCell>
        {collection.source}
      </TableCell>
      <TableCell>
        {collection.storage}
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={true}
          editAction={editAction}
          deleteAction={deleteAction}
          view=""
          viewLink=""
          historyLink=""
          viewHistory=""
          isEditModal
        />
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={type === "seed"
            ? t("Events.Seed collection")
            : t("Events.Seedling collection")
          }
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
      {showEditModal && editData && (
        <AddSeedCollection
          handleModalClose={handleModalClose}
          send={editCollection}
          textButton={t("Tree Species.Update")}
          getInfo={editData}
          type={type}
        />
      )}
    </Fragment>
  );
};

export default SeedCollectionTableItem;
