import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import ActionItem from "../../Global/ActionItem";
import { toast } from "react-toastify";
import { GrowthStage } from "../../../type/growthStage";

const BotanicalFamilyTableItem: React.FC<{
  botanicalFamilies: GrowthStage;
  editLink: string;
  link: string;
  deleteMessage: string;
  title: string;
  featured_icon?: string;
  fetchData: () => void;
}> = ({
  botanicalFamilies,
  editLink,
  link,
  deleteMessage,
  title,
  featured_icon,
  fetchData: propsFetchData,
}) => {
    const { i18n, t } = useTranslation();
    const [showDelete, isShowDelete] = useState<boolean>(false);

    const { response: deleteR, fetchData } = useAxios(
      process.env.REACT_APP_API_URL + `${link}/${botanicalFamilies.id}/delete/`,
      "DELETE",
      false,
      `${t("Message.Successful delete")}`,
      true,
      true
    );
    useEffect(() => {
      if (deleteR !== null) {
        isShowDelete(false);
        propsFetchData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteR]);

    const deleteAction = () => {
      if (botanicalFamilies.is_deletable) {
        isShowDelete(true);
      } else {
        toast.error(deleteMessage);
      }
    };

    const hide = () => {
      isShowDelete(false);
    };

    const deleteItem = () => {
      fetchData({});
    };

    const incident =
      botanicalFamilies.name_transes.fr === ""
        ? botanicalFamilies.name_transes.en
        : botanicalFamilies.name_transes.fr;

    const desc =
      botanicalFamilies.description_transes.fr === ""
        ? botanicalFamilies.description_transes.en
        : botanicalFamilies.description_transes.fr;

    return (
      <Fragment>
        <TableCell align="left">
          {i18n.language === "fr" ? incident : botanicalFamilies.name_transes.en}
        </TableCell>
        <TableCell>
          <div className="max-w-[600px] overflow-x-auto break-words">
            {i18n.language === "en"
              ? desc
              : botanicalFamilies.description_transes.fr}
          </div>
        </TableCell>
        {link.includes("incidents") && (
          <TableCell align="center">
            {featured_icon && (
              <div className="flex justify-center">
                <img
                  src={featured_icon}
                  alt="incident"
                  className="w-[14px] h-[14px] mr-3 object-cover"
                />
              </div>
            )}
          </TableCell>
        )}
        <TableCell>
          {title !== t("Tree Species.Botanical family") && (
            <p
              className={`${botanicalFamilies.is_active_transes.en === "Active"
                  ? "bg-ph-toggle-bg-green text-ph-toggle-text-green"
                  : "bg-ph-toggle-bg-red text-ph-red-warning"
                } text-center w-16 py-1 rounded-xl`}
            >
              {i18n.language === "en"
                ? botanicalFamilies.is_active_transes.en
                : botanicalFamilies.is_active_transes.fr}
            </p>
          )}
        </TableCell>

        <TableCell align="right">
          <ActionItem
            delete={true}
            editLink={`${editLink}/${botanicalFamilies.slug}`}
            deleteAction={deleteAction}
            view=""
            viewLink=""
            historyLink=""
            viewHistory=""
          />
        </TableCell>
        {showDelete && (
          <DeleteAlert type={title} hide={hide} deleteAction={deleteItem} />
        )}
      </Fragment>
    );
  };

export default BotanicalFamilyTableItem;
