import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { faInbox } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

const Loading: React.FC<{ loading: boolean }> = ({ loading }) => {
  const { t } = useTranslation();
  return (
    <div className="m-auto text-center font-medium text-2xl mt-20 text-default-text">
      {loading ? (
        <FontAwesomeIcon
          className="animate-spin w-16 h-16"
          icon={faSpinnerThird}
        />
      ) : (
        <p>
          <FontAwesomeIcon className="mr-4" icon={faInbox} />
          {t("Message.No results found")}
        </p>
      )}
    </div>
  );
};

export default Loading;
