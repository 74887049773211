import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Warning from "../../ErrorAndAlert/Warning";
import Status from "../../Global/Status";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import { Department } from "../../../type/department";
import useAxios from "../../../hook/useAxios";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../Register/AddRegister/FormTheme";
import { Municipality } from "../../../type/municipality";

type FormValues = {
  name: string;
  department_slug: Department;
  municipality: Municipality;
  initials: string;
};

const AddCommunityForm: React.FC<{
  send: (data: any) => void;
  selectOptions: {
    department: Department[];
  };
  sendResponse: any;
  getInfo?: any;
  textButton: string;
}> = ({ send, sendResponse, getInfo, textButton, selectOptions }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const [active, isActive] = useState<boolean>(true);
  const [municipalities, setMunicipalities] = useState<{
    municipality: Municipality[];
  }>({ municipality: [] });

  const history = useHistory();
  const {
    handleSubmit,
    setValue,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<FormValues>({});

  const departmentValue = watch("department_slug");

  const { response: municipalityRes, fetchData: getMunicipality } = useAxios(
    `${process.env.REACT_APP_API_URL}/api/admin/${departmentValue?.slug}/municipalities/all`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (sendResponse) {
      toast.success(
        `${
          getInfo
            ? `${t("Message.Successful update")}`
            : `${t("Message.Successful add")}`
        }`
      );
      history.replace("/setting/communities");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  //getting municipality response
  useEffect(() => {
    if (municipalityRes) {
      if (municipalityRes.data) {
        setMunicipalities((prevState) => ({
          ...prevState,
          municipality: municipalityRes.data,
        }));
      }
    }
  }, [municipalityRes]);

  useEffect(() => {
    if (departmentValue?.slug) {
      getMunicipality({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentValue?.slug]);

  useEffect(() => {
    if (getInfo) {
      setLoading(true);
      setValue("name", getInfo.data.name);
      setValue("department_slug", {
        slug: getInfo.data.department_slug,
        name: getInfo.data.department,
      });
      setValue("municipality", {
        slug: getInfo.data.municipality_slug,
        name: getInfo.data.municipality,
      });
      setValue("initials", getInfo.data.initials);
      isActive(getInfo.data.is_active_transes.en === "Active" ? true : false);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  const AddCommunity = (data: FormValues) => {
    isDirty(false);
    if (
      getInfo &&
      data.initials.toUpperCase() !== getInfo.data.initials &&
      !getInfo.data.is_initials_editable
    ) {
      toast.error(
        t(
          "Message.You can't edit initials. Some tags belong to this community."
        )
      );
    } else {
      send({
        department_slug: data.department_slug.slug,
        municipality: data.municipality.slug,
        name: data.name,
        initials: data.initials.toUpperCase(),
        is_active: active,
      });
    }
  };

  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")} ...
        </p>
      ) : (
        <ThemeProvider theme={theme}>
          <form onSubmit={handleSubmit(AddCommunity)}>
            <div className="flex flex-wrap justify-between mt-7 items-center">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm text-ph-dark-text mb-[6px]">
                  {t("Community.Community")} *
                </p>
                <input
                  {...register("name", {
                    required: `${t("Message.Required field")}`,
                  })}
                  type="text"
                  placeholder={t("Community.Community") + ""}
                  className="input-field text-sm pl-4 py-3 placeholder-ph-light-gray"
                />
                {errors.name && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-[30px]">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm text-ph-dark-text mb-[6px]">
                  {t("PlantingFilter.Province")} *
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                  control={control}
                  name="department_slug"
                  selectOptions={selectOptions.department}
                  placeholder={t("PlantingFilter.Province")}
                  getOptionLabel={(option) =>
                    !getInfo
                      ? option.name
                      : option.name || (getInfo.data && getInfo.data.department)
                  }
                  onCustomChange={() => {
                    setValue("municipality", {
                      slug: "",
                      name: "",
                    });
                  }}
                />
                {errors.department_slug && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>

              <div className="w-full sm:w-1/2 ">
                <p
                  className={`${
                    departmentValue?.slug === "" || !departmentValue?.slug
                      ? "text-ph-light-gray"
                      : "text-default-text"
                  } text-sm mb-[6px] mt-7 sm:mt-0`}
                >
                  {t("Plantings.District")} *
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                    validate: (value: any) => {
                      if (!value || value.slug === "") {
                        return `${t("Message.Required field")}`;
                      }
                      return true;
                    },
                  }}
                  control={control}
                  name="municipality"
                  selectOptions={municipalities.municipality}
                  placeholder={t("Plantings.District")}
                  getOptionLabel={(option) => option.name}
                  disabled={
                    departmentValue?.slug === "" || !departmentValue?.slug
                  }
                />
                {errors.municipality && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-7 items-center">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm text-ph-dark-text mb-[6px]">
                  {t("Community.Initials")} *
                </p>
                <input
                  {...register("initials", {
                    required: `${t("Message.Required field")}`,
                    maxLength: {
                      value: 3,
                      message: `${t("Message.Initials must be 3 characters.")}`,
                    },
                    minLength: {
                      value: 3,
                      message: `${t("Message.Initials must be 3 characters.")}`,
                    },
                  })}
                  type="text"
                  placeholder={t("Community.Initials") + ""}
                  className="input-field text-sm pl-4 py-3 placeholder-ph-light-gray"
                />
                {errors.initials && (
                  <p className="error-text">{errors.initials.message}</p>
                )}
              </div>
              <div className="sm:w-1/2">
                <div className="md:pl-4 mt-7 md:mt-0 flex">
                  <Status
                    active={active}
                    isActive={(value) => {
                      isActive(value);
                    }}
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="main-btn text-sm px-6 py-2 mt-5 float-right font-medium"
            >
              {textButton}
            </button>
          </form>
        </ThemeProvider>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddCommunityForm;
