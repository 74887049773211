export const isSupportedFormat = (fileName: string) => {
  const parts = fileName.split(".");
  const fileFormat = parts[parts.length - 1].toLowerCase();

  if (
    fileFormat === "jpg" ||
    fileFormat === "jpeg" ||
    fileFormat === "png" ||
    fileFormat === "txt" ||
    fileFormat === "doc" ||
    fileFormat === "docx" ||
    fileFormat === "pdf" ||
    fileFormat === "xls" ||
    fileFormat === "xlsx"
  ) {
    return true;
  } else {
    return false;
  }
};
