import { useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import Loading from "../../Global/Loading";
import EventForm from "../EventForm";

const ViewPlanting = () => {
  const { t } = useTranslation();
  const { id } = useParams<any>();

  const {
    fetchData: getEvent,
    response: getEditResponse,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/plantings/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    getEvent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Events.View event")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Events.Events"), url: "/events" },
          { name: t("Events.View event"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <EventForm mode="view" getEditResponse={getEditResponse} />
      )}
    </div>
  );
};

export default ViewPlanting;
