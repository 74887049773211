import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Global/BreadCrumb";
import SearchField from "../../Global/SearchField";
import TreeNurseriesTableItem from "./TreeNurseriesTableItem";
import GenericTable from "../../Global/GenericTable";
import useAxios from "../../../hook/useAxios";
import { TreeNurseriesRecord } from "../../../type/treeNurseries";

const TreeNurseries = () => {
  const [search, setSearch] = useState<string>("");
  const [nurseryList, setNurseryList] = useState<TreeNurseriesRecord[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [sendSearch, setSendSearch] = useState<string>("");
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/nurseries/?&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );
  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setNurseryList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Tree nurseries.Tree nurseries")}
        </h1>
        <Link
          to="/setting/nurseries/add-nursery"
          className="main-btn px-[23px] py-[10px] text-sm"
        >
          {t("Tree nurseries.Add tree nursery")}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Tree nurseries.Tree nurseries"), url: "" },
        ]}
      />

      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />

      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          search={sendSearch}
          countData={count}
          dataList={nurseryList}
          handleChange={handleChange}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Register.Code"), value: "code" },
            { name: t("Tree Species.Name"), value: "name" },
            { name: t("Tree nurseries.Capacity"), value: "capacity" },
            { name: t("Community.Community"), value: "community" },
            {
              name: t("Tree nurseries.Available trees"),
              value: "available_trees",
            },
            {
              name: t("Tree nurseries.Nursery responsible"),
              value: "nursery_responsible",
            },
          ]}
          renderItem={(nurseries, fetchData, lastIndex) => (
            <TreeNurseriesTableItem
              nurseries={nurseries}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        <br />
      </div>
    </div>
  );
};

export default TreeNurseries;
