import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAxios from "../../../../hook/useAxios";
import WarningRedAlert from "../../../ErrorAndAlert/WarningRedAlert";
import ActionItem from "../../../Global/ActionItem";
import { NurseryDetail } from "../../../../type/nurseryDetail";
import InfoPopoverNursery from "../../../TreeNurseries/TreeNurseriesList/InfoPopoverNursery";
import AddNurseryEstablishment from "./AddNurseryEstablishment";
const NurseryEstablishmentTableItem: React.FC<{
  nurseries: NurseryDetail;
  fetchData: () => void;
  lastIndex: number;
}> = ({ nurseries, fetchData: propFetchData, lastIndex }) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const [editData, setEditData] = useState(null);

  const { i18n, t } = useTranslation();
  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/nurseries/inventory_tracking/${nurseries.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );
  const { fetchData: editNurseries, response: editResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/nurseries/supply/${nurseries.slug}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );
  const { fetchData: getNurseries, response: getEditResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/nurseries/supply/${nurseries.slug}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (getEditResponse) {
      setEditData(getEditResponse);
    }
  }, [getEditResponse]);

  useEffect(() => {
    if (editResponse) {
      propFetchData();
      toast.success(t("Message.Successful update"));
      setShowEditModal(false);
    }
  }, [editResponse]);

  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const index = lastIndex >= 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;

  const deleteAction = () => {
    if (!nurseries.is_deletable) {
      toast.error(
        t(
          "Message.You can't delete the supplied trees. There are distributed trees for selected species and age."
        )
      );
    } else {
      isShowDelete(true);
    }
  };

  const editAction = () => {
    getNurseries({});
    setShowEditModal(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  const handleModalClose = () => {
    setShowEditModal(false);
  };

  return (
    <Fragment>
      <TableCell align="left">
        {i18n.language === "fr"
          ? nurseries.nursery_name_transes?.fr === ""
            ? nurseries.nursery_name_transes?.en
            : nurseries.nursery_name_transes?.fr
          : nurseries.nursery_name_transes?.en}
      </TableCell>
      <TableCell>
        <span className="flex flex-row items-center">
          {i18n.language === "fr"
            ? nurseries.species_name_transes?.fr === ""
              ? nurseries.species_name_transes?.en
              : nurseries.species_name_transes?.fr
            : nurseries.species_name_transes?.en}
          {nurseries.note_transes.en && (
            <InfoPopoverNursery
              index={index}
              scroll={scroll}
              note={
                i18n.language === "fr"
                  ? nurseries.note_transes?.fr === ""
                    ? nurseries.note_transes?.en
                    : nurseries.note_transes?.fr
                  : nurseries.note_transes?.en
              }
            />
          )}
        </span>
      </TableCell>
      <TableCell>{nurseries.number_of_trees}</TableCell>
      <TableCell>
        {nurseries.age_year >= 0 && nurseries.age_month > 0
          ? nurseries.age_year > 0 && nurseries.age_month > 0
            ? nurseries.age_year +
              " " +
              t("Register.Year") +
              ", " +
              nurseries.age_month +
              " " +
              t("Register.Month")
            : nurseries.age_year > 0 && nurseries.age_month === 0
            ? nurseries.age_year + " " + t("Register.Year")
            : nurseries.age_year === 0 && nurseries.age_month > 0
            ? nurseries.age_month + " " + t("Register.Month")
            : ""
          : ""}
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={true}
          editAction={editAction}
          deleteAction={deleteAction}
          view=""
          viewLink=""
          historyLink=""
          viewHistory=""
          isEditModal
        />
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={t("Events.Nursery establishment")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
      {showEditModal && editData && (
        <AddNurseryEstablishment
          handleModalClose={handleModalClose}
          send={editNurseries}
          textButton={t("Tree Species.Update")}
          getInfo={editData}
          exist={{
            name_transes: getEditResponse.data.nursery_name_transes,
            slug: getEditResponse.data.nursery_slug,
          }}
        />
      )}
    </Fragment>
  );
};

export default NurseryEstablishmentTableItem;
