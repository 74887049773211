import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAxios from "../../../../hook/useAxios";
import { MunicipalityRecord } from "../../../../type/municipalityRecord";
import GenericTable from "../../../Global/GenericTable";
import AddSeedCollection from "./AddSeedCollection";
import SeedCollectionTableItem from "./SeedCollectionTableItem";

const SeedCollectionDetail = ({ id, type }: { id: string; type: string }) => {
  const [collectionList, setCollectionList] = useState<MunicipalityRecord[]>(
    []
  );
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const [count, setCount] = useState<number>(0);
  const { t } = useTranslation();
  const {
    fetchData: getSeedCollection,
    response,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${id}/seeds/?&page=${page}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );
  const { fetchData: addData, response: addResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/plantings/${id}/seed/new/`,
    "POST",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setCollectionList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    if (addResponse) {
      getSeedCollection({});
      toast.success(t("Message.Successful add"));
      setShowAddModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleAddSeed = () => {
    setShowAddModal(true);
  };

  const handleModalClose = () => {
    setShowAddModal(false);
  };

  return (
    <div className="bg-white mt-3 rounded-md">
      <div className="flex justify-between mb-11">
        <h1 className="py-[10px] font-semibold mr-[12px] md:mr-0 text-sm text-default-text">
          {type === "seed"
            ? t("Events.Seed collection details")
            : t("Events.Seedling collection details")}
        </h1>
        <button
          onClick={handleAddSeed}
          className="main-btn px-[23px] py-[10px] text-sm"
        >
          {t("Events.Add detail")}
        </button>
      </div>
      <GenericTable
        countData={count}
        handleChange={handleChange}
        dataList={collectionList}
        fetchData={() => {
          getSeedCollection({});
        }}
        loading={loading}
        setOrderingList={order}
        titles={[
          type === "seed"
            ? { name: t("Events.Seed species"), value: "species" }
            : { name: t("Events.Seedling species"), value: "species" },
          type === "seed"
            ? { name: t("Events.Quantity (gr)"), value: "quantity" }
            : { name: t("Events.Number"), value: "quantity" },
          { name: t("Events.Source"), value: "source" },
          { name: t("Events.Storage"), value: "storage" },
        ]}
        renderItem={(seedCollection, fetchData, lastIndex) => (
          <SeedCollectionTableItem
            collection={seedCollection}
            fetchData={fetchData}
            lastIndex={lastIndex}
            type={type}
          />
        )}
      />
      <br />
      {showAddModal && (
        <AddSeedCollection
          handleModalClose={handleModalClose}
          send={addData}
          textButton={t("Tree inventory.Add")}
          type={type}
        />
      )}
    </div>
  );
};

export default SeedCollectionDetail;
