import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { TableContainer } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@emotion/react";
import TableFooter from "@mui/material/TableFooter";
import RegisterTableItem from "./RegisterTableItem";
import { TableThem } from "../../../styles/TableThem";
import TranslationField from "../../../type/translationField";
import TableHeader from "../TableHeader";
import { useTranslation } from "react-i18next";
import { getComparator, sortedRowInformation } from "../tableUtils";
import TablePagination from "../../Global/TablePagination";

const RegisterTableContent: React.FC<{
  registers: {
    name_transes: TranslationField;
    type_transes: TranslationField;
    trees_count: number;
    dead_trees: number;
  }[];
  showDead: boolean;
}> = ({ registers, showDead }) => {
  const [orderDirection, setOrderDirection] = useState<"desc" | "asc">("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState<string>("name");
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  const handleRequestSort = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string
  ) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value - 1);
  };

  useEffect(() => {
    setPage(0);
  }, [registers]);

  return (
    <TableContainer>
      <ThemeProvider theme={TableThem}>
        <Table>
          <TableHeader
            valueToOrderBy={valueToOrderBy}
            orderDirection={orderDirection}
            handleRequestSort={handleRequestSort}
            titles={[
              { name: t("Statistics.Species"), value: "", align: "left" },
              { name: t("Tree Species.Type"), value: "", align: "left" },
              {
                name: t("Statistics.Trees"),
                value: "trees_count",
                align: "center",
              },
              showDead
                ? {
                    name: t("Statistics.Dead trees"),
                    value: "dead_trees",
                    align: "center",
                  }
                : {
                    name: "",
                    value: "",
                    align: "center",
                  },
            ]}
          />
          <TableBody>
            {sortedRowInformation(
              registers,
              getComparator(orderDirection, valueToOrderBy)
            )
              .slice(page * 3, page * 3 + 3)
              .map((register: any, index: number) => (
                <TableRow key={index}>
                  <RegisterTableItem
                    name_transes={register.name_transes}
                    type_transes={register.type_transes}
                    trees_count={register.trees_count}
                    dead_trees={showDead ? register.dead_trees : null}
                  />
                </TableRow>
              ))}
            {page === Math.ceil(registers.length / 3) - 1 &&
              registers.length % 3 === 1 && (
                <Fragment>
                  <TableRow></TableRow>
                  <TableRow></TableRow>
                </Fragment>
              )}
            {page === Math.ceil(registers.length / 3) - 1 &&
              registers.length % 3 === 2 && (
                <Fragment>
                  <TableRow></TableRow>
                </Fragment>
              )}
          </TableBody>
          <TableFooter
            style={{ border: "none", height: "70px", position: "relative" }}
          >
            <Box
              sx={{ flexShrink: 0 }}
              style={{ position: "absolute", left: "0px", top: "20px" }}
            >
              <Stack spacing={2}>
                <TablePagination
                  count={Math.ceil(registers.length / 3)}
                  handleChange={handleChangePage}
                />
              </Stack>
            </Box>
          </TableFooter>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
};
export default RegisterTableContent;
