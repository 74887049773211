import { createTheme } from "@mui/material";

export const TableThem = createTheme({
  palette: {
    primary: {
      main: "#439FC6",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Inter,sans-serif",
          fontSize: "14px",
          fontWeight: "580",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Inter,sans-serif",
          fontWeight: "400",
          color: "#414141",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "60px",
          ":hover": {
            backgroundColor: "#F7F7F7",
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: "#414141",
          "&.Mui-selected": {
            color: "#fff",
          },
        },
        icon: {
          color: "#979797",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          borderBottomColor: "#fff",
          width: "100%",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            color: "#439FC6",
            "& .MuiTableSortLabel-icon": {
              color: "#439FC6",
            },
          },
          fontWeight: "500",
        },
      },
    },
  },
});
