import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../../hook/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faFlag } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

const DepartmentTab = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState("");
  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const googleMapRef = useRef<HTMLDivElement>(null);
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/departments/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );
  useEffect(() => {
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (response?.data) {
      const isActive = response.data.is_active;
      const status = isActive ? t("Province.Active") : t("Province.Inactive");
      setActive(status);
    }
  }, [response, t]);

  useEffect(() => {
    const initGoogleMap = () => {
      const map = new window.google.maps.Map(
        googleMapRef.current as HTMLElement,
        {
          zoom: 6,
          center: { lat: 3.741765, lng: -73.508309 },
          mapId: "de709e4ae98647a3",
        }
      );
      setGoogleMap(map);
    };

    const loadGoogleMapScript = () => {
      if (!mapLoaded) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&map_ids=de709e4ae98647a3`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          setMapLoaded(true);
          initGoogleMap();
        };
        script.onerror = () => {
          console.error("Error loading Google Maps API.");
        };
        document.body.appendChild(script);
      } else {
        initGoogleMap();
      }
    };

    loadGoogleMapScript();
  }, [mapLoaded]);

  useEffect(() => {
    if (googleMap && response?.data?.kml) {
      // Ensure geoXML3 script is loaded
      const loadGeoXML3Script = () => {
        const script = document.createElement("script");
        script.src =
          "https://cdn.rawgit.com/geocodezip/geoxml3/master/polys/geoxml3.js";
        script.async = true;
        script.defer = true;
        script.onload = () => {
          // @ts-ignore
          const parser = new window.geoXML3.parser({ map: googleMap });
          parser.parseKmlString(response.data.kml);
        };
        document.body.appendChild(script);
      };
      // @ts-ignore
      if (!window.geoXML3) {
        loadGeoXML3Script();
      } else {
        // @ts-ignore
        const parser = new window.geoXML3.parser({ map: googleMap });
        parser.parseKmlString(response.data.kml);
      }
    }
  }, [googleMap, response]);

  return (
    <div className="m-8 w-full">
      <div className="w-full py-6 px-4 border border-solid border-ph-input">
        <div className="flex flex-col sm:flex-row justify-between w-full sm:w-[80%] sm:items-center">
          <div className="flex flex-row items-center mb-4 sm:mb-0 sm:justify-between">
            <div>
              <FontAwesomeIcon className="text-ph-gray" icon={faFlag} />
            </div>
            <div className="flex flex-col ml-5">
              <div className="text-sm text-ph-gray">
                {t("Province.Country")}
              </div>
              <div className="text-sm text-default-text">
                {response?.data?.project_country}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center mb-4 sm:mb-0 sm:justify-between">
            <div>
              <FontAwesomeIcon className="text-ph-gray" icon={faBuilding} />
            </div>
            <div className="flex flex-col ml-5">
              <div className="text-sm text-ph-gray">
                {t("Province.Province")}
              </div>
              <div className="text-sm text-default-text">
                {response?.data?.name}
              </div>
            </div>
          </div>
          <div>
            <p
              className={`${
                response?.data?.is_active
                  ? "bg-ph-toggle-bg-green text-ph-toggle-text-green"
                  : "bg-[#FFDBDE] text-[#D2202A]"
              } text-center w-16 py-1 rounded-xl`}
            >
              {active}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div ref={googleMapRef} className="w-full h-[300px]"></div>
      </div>
      <div className="mt-12 flex justify-end">
        <Link
          to="/setting/provinces"
          className="main-btn px-[23px] py-[10px] text-sm"
        >
          {t("Province.Back to province")}
        </Link>
      </div>
    </div>
  );
};

export default DepartmentTab;
