import { ThemeProvider } from "@emotion/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workshopFilterDelete, workshopFilterSet } from "../../../store";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { plantingTheme } from "../../Register/RegisterList/PlantingTheme";
import { workshopFilterAction } from "../../../store/workshopFilter-slice";
import CustomDate from "../../Global/CustomDate";
import NumberField from "../../Global/NumberField";

type FormValues = {
  dateFrom: Date;
  dateTo: Date;
  partiFrom: number;
  partiTo: number;
};
const WorkshopFilterBox = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    resetField,
    clearErrors,
    setError,
    formState: { errors },
    control,
    reset,
  } = useForm<FormValues>();
  const isFilterSet = useSelector(workshopFilterSet);
  const deleteItem = useSelector(workshopFilterDelete);

  const dispatch = useDispatch();

  useEffect(() => {
    if (deleteItem === "date") {
      resetField("dateTo");
      resetField("dateFrom");
    } else if (deleteItem === "par") {
      resetField("partiFrom");
      resetField("partiTo");
    } else if (deleteItem !== "") {
      resetField(deleteItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItem]);

  const applyFilter = (data: FormValues) => {
    const dateT =
      !data.dateTo || !data.dateTo?.getDate()
        ? ""
        : `${data.dateTo.getDate()}/${
            data.dateTo.getMonth() + 1
          }/${data.dateTo.getFullYear()}`;
    const dateF =
      !data.dateFrom || !data.dateFrom?.getDate()
        ? ""
        : `${data.dateFrom?.getDate()}/${
            data.dateFrom?.getMonth() + 1
          }/${data.dateFrom?.getFullYear()}`;
    if (data.dateFrom > data.dateTo) {
      setError("dateFrom", {
        type: "custom",
        message: `${t("Message.Invalid date filter error")}`,
      });
      return;
    }
    dispatch(
      workshopFilterAction.setFilter({
        dateTo: dateT,
        dateFrom: dateF,
        partiFrom: `${data.partiFrom === undefined ? "" : data.partiFrom}`,
        partiTo: `${data.partiTo === undefined ? "" : data.partiTo}`,
      })
    );
    dispatch(workshopFilterAction.setURL());
    dispatch(workshopFilterAction.isFilterSet());
  };

  const clearFliter = () => {
    reset();
    dispatch(workshopFilterAction.clearFilter());
    dispatch(workshopFilterAction.setURL());
  };
  return (
    <ThemeProvider theme={plantingTheme}>
      <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
        <form onSubmit={handleSubmit(applyFilter)} className="mt-4">
          <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%]">
            <div className="w-full lg:w-[48%]">
              <p className="text-sm mb-[6px]">{t("Workshops.Date")}</p>
              <div className="flex items-center">
                <CustomDate
                  clearError={clearErrors}
                  control={control}
                  name="dateFrom"
                  label={t("PlantingFilter.From")}
                />

                <span className="px-2 text-default-text text-xs">&#9866;</span>
                <CustomDate
                  control={control}
                  name="dateTo"
                  label={t("PlantingFilter.To")}
                />
              </div>
              {errors?.dateFrom?.type === "custom" && (
                <p className="error-text">
                  {t("Message.Invalid date filter error")}
                </p>
              )}
            </div>
            <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
              <p className="text-sm mb-[6px]">
                {t("PlantingFilter.Participants")}
              </p>
              <div className="flex items-center">
                <NumberField
                  onChange={() => {}}
                  getValues={getValues}
                  inputStyle="py-2 placeholder-ph-light-gray"
                  name="partiFrom"
                  placeholder={t("PlantingFilter.From") + ""}
                  register={register("partiFrom")}
                  setValue={setValue}
                  readOnl={false}
                />
                <span className="px-2 text-default-text text-xs">&#9866;</span>
                <NumberField
                  onChange={() => {}}
                  getValues={getValues}
                  inputStyle="py-2 placeholder-ph-light-gray"
                  name="partiTo"
                  placeholder={t("PlantingFilter.To") + ""}
                  register={register("partiTo")}
                  setValue={setValue}
                  readOnl={false}
                />
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
            {isFilterSet && (
              <button
                className="py-2 px-6 text-sm font-medium rounded-3xl text-default-text hover:bg-[#F6F6F6] mr-3"
                type="button"
                onClick={clearFliter}
              >
                {t("PlantingFilter.Reset")}
              </button>
            )}

            <button
              type="submit"
              className="white-btn border border-ph-btn text-sm font-medium py-2 px-6"
            >
              {t("PlantingFilter.Apply")}
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};
export default WorkshopFilterBox;
