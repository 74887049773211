import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Doughnut } from "react-chartjs-2";
import PlantingTableContent from "./PlantingTableContent";

const PlantingStatistics: React.FC<{ response: any; showDead: boolean }> = ({
  response,
  showDead,
}) => {
  const [plantingStatistics, setPlantingStatistics] = useState<{
    department_count: number;
    total_plantings: number;
    total_planted_trees: number;
    total_participants: number;
    total_dead_trees: number;
    psa_students: number;
    school_students: number;
    psa_tutors: number;
    school_teachers: number;
    farmers: number;
    promoters: number;
    junior_youth_parents: number;
    community_leaders: number;
    other_participants: number;
    planting_list: {
      department_name: string;
      plantings: number;
      planted_trees: number;
      participants: number;
      dead_trees: number;
    }[];
  }>({
    department_count: 0,
    other_participants: 0,
    psa_students: 0,
    psa_tutors: 0,
    school_students: 0,
    school_teachers: 0,
    farmers: 0,
    promoters: 0,
    junior_youth_parents: 0,
    community_leaders: 0,
    total_participants: 0,
    total_planted_trees: 0,
    total_dead_trees: 0,
    total_plantings: 0,
    planting_list: [],
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (response) {
      setPlantingStatistics(response?.data);
    }
  }, [response]);

  const data = {
    labels: [
      t("Workshops.PSA students"),
      t("Workshops.School students"),
      t("Workshops.PSA tutors"),
      t("Workshops.School teachers"),
      t("Workshops.Farmers"),
      t("Workshops.Promoters"),
      t("Workshops.Junior youth parents"),
      t("Workshops.Community leaders"),
      t("Workshops.Other participants"),
    ],
    datasets: [
      {
        data: [
          plantingStatistics?.psa_students,
          plantingStatistics?.school_students,
          plantingStatistics?.psa_tutors,
          plantingStatistics?.school_teachers,
          plantingStatistics?.farmers,
          plantingStatistics?.promoters,
          plantingStatistics?.junior_youth_parents,
          plantingStatistics?.community_leaders,
          plantingStatistics?.other_participants,
        ],
        backgroundColor: [
          "#B9E0C5", //psa student
          "#F4DB7D", //school students
          "#CDC4F0", //psa tutors
          "#B9E2FF", //school teachers
          "#EFFA8B", //farmers
          "#C8C69F", //promoters
          "#ECB9A2", //junior youth parents
          "#6A86F6", //community leaders
          "#FFC1C5", //other participants
        ],
        hoverBackgroundColor: [
          "#B9E0C5", //psa student
          "#F4DB7D", //school stu
          "#CDC4F0", //psa tutors
          "#B9E2FF", //school teachers
          "#EFFA8B", //farmers
          "#C8C69F", //promoters
          "#ECB9A2", //junior youth parents
          "#6A86F6", //community leaders
          "#FFC1C5", //other participants
        ],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        titleColor: (tooltipItem: any) => {
          if (tooltipItem.tooltipItems[0]) {
            let color = "#7EB48B";
            if (tooltipItem.tooltipItems[0].dataIndex === 0) {
              color = "#7EB48B";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 1) {
              color = "#EFBF07";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 2) {
              color = "#8269D7";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 3) {
              color = "#72AEFF";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 4) {
              color = "#a1b207";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 5) {
              color = "#bfbc8e";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 6) {
              color = "#e39978";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 7) {
              color = "#224bf2";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 8) {
              color = "#D2202A";
            }
            return color;
          }
        },
        TitleFont: {
          size: 14,
        },
        backgroundColor: (tooltipItem: any) => {
          if (tooltipItem.tooltipItems[0]) {
            let color = "#B9E0C5";
            if (tooltipItem.tooltipItems[0].dataIndex === 0) {
              color = "#EEF8F1";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 1) {
              color = "#FCF1C7";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 2) {
              color = "#F5F2FE";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 3) {
              color = "#F0F8FE";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 4) {
              color = "#f9fdd4";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 5) {
              color = "#edece0";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 6) {
              color = "#f5d9cc";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 7) {
              color = "#9aadf9";
            } else if (tooltipItem.tooltipItems[0].dataIndex === 8) {
              color = "#FEEAEC";
            }
            return color;
          }
        },
        bodyFont: {
          size: 14,
          weight: "bold",
        },
        callbacks: {
          labelTextColor: function (context: any) {
            let bodyColor = "#7EB48B";
            if (context.dataIndex === 0) {
              bodyColor = "#7EB48B";
              console.log(bodyColor);
            } else if (context.dataIndex === 1) {
              bodyColor = "#EFBF07";
            } else if (context.dataIndex === 2) {
              bodyColor = "#8269D7";
            } else if (context.dataIndex === 3) {
              bodyColor = "#72AEFF";
            } else if (context.dataIndex === 4) {
              bodyColor = "#a1b207";
            } else if (context.dataIndex === 5) {
              bodyColor = "#bfbc8e";
            } else if (context.dataIndex === 6) {
              bodyColor = "#e39978";
            } else if (context.dataIndex === 7) {
              bodyColor = "#224bf2";
            } else if (context.dataIndex === 8) {
              bodyColor = "#D2202A";
            }
            return bodyColor;
          },
        },
      },
    },
    cutout: "52%",
    rotation: 270,
    circumference: 180,
  };
  const data1 = {
    labels: [t("Statistics.Total participants")],
    datasets: [
      {
        data: [plantingStatistics?.total_participants],
        backgroundColor: ["#FFC889"],
        hoverBackgroundColor: ["#FFC889"],
      },
    ],
  };

  const options1 = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        titleColor: "#FE9D2B",
        TitleFont: {
          size: 14,
        },
        backgroundColor: "#FFDCB4",
        bodyColor: "#FE9D2B",
        bodyFont: {
          size: 14,
          weight: "bold",
        },
      },
    },
    cutout: "84%",
    rotation: 270,
    circumference: 180,
  };

  return (
    <Fragment>
      <h1 className="font-semibold text-default-text text-sm">
        {t("Plantings.Plantings")}
      </h1>
      <div className="flex flex-wrap justify-between mt-5">
        <div className="w-full sm:w-[26.42%] bg-white rounded-md flex flex-wrap flex-col justify-between text-center py-10">
          <div>
            <p className="text-sm text-ph-toggle-text-blue font-bold">
              {plantingStatistics.department_count
                ? plantingStatistics.department_count
                : 0}
            </p>
            <p className="text-sm text-ph-toggle-text-blue font-medium">
              {t("Navbar.Provinces")}
            </p>
          </div>
          <div className="mt-6 sm:mt-11">
            <p className="text-sm text-[#8268D7] font-bold">
              {plantingStatistics.total_plantings
                ? plantingStatistics.total_plantings
                : 0}
            </p>
            <p className="text-sm text-[#8268D7] font-medium">
              {t("Events.Events")}
            </p>
          </div>
          <div className="mt-6 sm:mt-11">
            <p className="text-sm text-ph-dark-blue font-bold">
              {plantingStatistics.total_planted_trees
                ? plantingStatistics.total_planted_trees
                : 0}
            </p>
            <p className="text-sm text-ph-dark-blue">
              {t("Statistics.Planted trees")}
            </p>
          </div>
          {showDead && (
            <div className="mt-6 sm:mt-11">
              <p className="text-sm text-[#FFC1C5] font-bold">
                {plantingStatistics.total_dead_trees
                  ? plantingStatistics.total_dead_trees
                  : 0}
              </p>
              <p className="text-sm text-[#FFC1C5]">
                {t("Statistics.Dead trees")}
              </p>
            </div>
          )}
          <div className="mt-6 sm:mt-11">
            <p className="text-sm text-ph-orange-alert font-bold">
              {plantingStatistics.total_participants
                ? plantingStatistics.total_participants
                : 0}
            </p>
            <p className="text-sm text-ph-orange-alert font-medium">
              {t("PlantingFilter.Participants")}
            </p>
          </div>
        </div>
        <div className="w-full sm:w-[71.8%] bg-white rounded-md body pb-5 relative">
          <div className=" w-[280px] m-auto absolute right-1/2 top-0 transform translate-x-1/2 sm:translate-y-[-50px] translate-y-[-85px] ">
            <Doughnut data={data1} options={options1} />
          </div>
          <div className="w-[221px] m-auto relative mt-[-40px] sm:mt-[-5px] ">
            <Doughnut data={data} options={options} />
          </div>

          <div className="flex items-center text-sm justify-center mt-[-20px]">
            <div className="w-[10px] h-[24px] bg-[#FFC889] rounded-md mr-2"></div>
            <p className="w-[160px]">{t("Statistics.Total participants")}</p>
            <p className="ml-4 w-8">
              {plantingStatistics.total_participants
                ? plantingStatistics.total_participants
                : 0}
            </p>
          </div>

          <div className="flex items-center text-sm justify-center mt-2">
            <div className="w-[10px] h-[24px] bg-[#B9E0C5] rounded-md mr-2"></div>
            <p className="w-[160px]">{t("Workshops.PSA students")}</p>
            <p className="ml-4 w-8">
              {plantingStatistics.psa_students
                ? plantingStatistics.psa_students
                : 0}
            </p>
          </div>

          <div className="flex items-center text-sm justify-center mt-2">
            <div className="w-[10px] h-[24px] bg-[#F4DB7D] rounded-md mr-2"></div>
            <p className="w-[160px]"> {t("Workshops.School students")}</p>
            <p className="ml-4 w-8">
              {plantingStatistics.school_students
                ? plantingStatistics.school_students
                : 0}
            </p>
          </div>

          <div className="flex items-center text-sm justify-center mt-2">
            <div className="w-[10px] h-[24px] bg-[#CDC4F0] rounded-md mr-2"></div>
            <p className="w-[160px]">{t("Workshops.PSA tutors")}</p>
            <p className="ml-4 w-8">
              {plantingStatistics.psa_tutors
                ? plantingStatistics.psa_tutors
                : 0}
            </p>
          </div>

          <div className="flex items-center text-sm justify-center mt-2">
            <div className="w-[10px] h-[24px] bg-[#B9E2FF] rounded-md mr-2"></div>
            <p className="w-[160px]">{t("Workshops.School teachers")}</p>
            <p className="ml-4 w-8">
              {plantingStatistics.school_teachers
                ? plantingStatistics.school_teachers
                : 0}
            </p>
          </div>
          <div className="flex items-center text-sm justify-center mt-2">
            <div className="w-[10px] h-[24px] bg-[#EFFA8B] rounded-md mr-2"></div>
            <p className="w-[160px]">{t("Workshops.Farmers")}</p>
            <p className="ml-4 w-8">
              {plantingStatistics.farmers ? plantingStatistics.farmers : 0}
            </p>
          </div>

          <div className="flex items-center text-sm justify-center mt-2">
            <div className="w-[10px] h-[24px] bg-[#C8C69F] rounded-md mr-2"></div>
            <p className="w-[160px]">{t("Workshops.Promoters")}</p>
            <p className="ml-4 w-8">
              {plantingStatistics.promoters ? plantingStatistics.promoters : 0}
            </p>
          </div>

          <div className="flex items-center text-sm justify-center mt-2">
            <div className="w-[10px] h-[24px] bg-[#ECB9A2] rounded-md mr-2"></div>
            <p className="w-[160px]">{t("Workshops.Junior youth parents")}</p>
            <p className="ml-4 w-8">
              {plantingStatistics.junior_youth_parents
                ? plantingStatistics.junior_youth_parents
                : 0}
            </p>
          </div>

          <div className="flex items-center text-sm justify-center mt-2">
            <div className="w-[10px] h-[24px] bg-[#6A86F6] rounded-md mr-2"></div>
            <p className="w-[160px]">{t("Workshops.Community leaders")}</p>
            <p className="ml-4 w-8">
              {plantingStatistics.community_leaders
                ? plantingStatistics.community_leaders
                : 0}
            </p>
          </div>

          <div className="flex items-center text-sm justify-center mt-2">
            <div className="w-[10px] h-[24px] bg-[#FFC1C5] rounded-md mr-2"></div>
            <p className="w-[160px]">{t("Workshops.Other participants")}</p>
            <p className="ml-4 w-8">
              {plantingStatistics.other_participants
                ? plantingStatistics.other_participants
                : 0}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full mt-2 bg-white rounded-md">
        {plantingStatistics?.planting_list && (
          <PlantingTableContent
            showDead={showDead}
            plantings={plantingStatistics?.planting_list}
          />
        )}
      </div>
    </Fragment>
  );
};

export default PlantingStatistics;
