import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BreadCrumb from "../../Global/BreadCrumb";
import SearchField from "../../Global/SearchField";
import IncidentTableContent from "./IncidentTableContent";

const Incident = () => {
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const { t } = useTranslation();
  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Incidents.Incidents")}
        </h1>
        <Link
          to="/setting/incidents/add-incident"
          className="main-btn px-[23px] py-[10px] text-sm"
        >
          {t("Incidents.Add incident")}
        </Link>
      </div>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Incidents.Incidents"), url: "" },
        ]}
      />

      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />

      <div className="p-4 bg-white mt-3 rounded-md">
        <IncidentTableContent
          search={sendSearch}
          link="/api/admin/incidents"
          title={t("Incidents.Incident")}
          deleteMessage={t("Message.Invalid incident delete")}
          editLink="/setting/incidents/edit-incident"
        />
        <br />
      </div>
    </div>
  );
};

export default Incident;
