import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import TranslationField from "../../../type/translationField";
import BreadCrumb from "../../Global/BreadCrumb";
import AddScientificNameForm from "./AddScientificNameForm";

const AddScientificName = () => {
  const { t } = useTranslation();
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/scientific_names/new/`,
    "POST",
    false,
    "",
    true,
    true
  );

  const addName = (name: TranslationField, sName: { slug: string; scientific_name_transes: TranslationField }[]) => {
    let scientificNames: TranslationField[] = sName.map(name => (
      name.scientific_name_transes
    ));
    fetchData({
      name_transes: name,
      scientific_name_transes: scientificNames,
    })
  }
  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">{t("Scientific name.Add scientific name")}</h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          { name: t("Navbar.Scientific names"), url: "/setting/scientific-names" },
          { name: t("Scientific name.Add scientific name"), url: "" }
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddScientificNameForm
            send={addName}
            sendResponse={response}
            textButton={t("Scientific name.Add scientific name")}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Add scientific name.svg"
            alt="Add scientific name"
            className="mt-4 ml-2 xl:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default AddScientificName;
