import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import { Department } from "../../../type/department";
import BreadCrumb from "../../Global/BreadCrumb";
import Loading from "../../Global/Loading";
import EventForm from "../EventForm";

const EditPlanting = () => {
  const [selectOption, setSelectOption] = useState<{
    department: Department[];
    grp: Department[];
    ins: Department[];
    fml: Department[];
    ind: Department[];
  }>({ department: [], ind: [], ins: [], grp: [], fml: [] });
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const [type, setType] = useState<string>("");
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/plantings/${id}/edit/`,
    "PUT",
    false,
    "",
    true,
    true
  );

  const {
    fetchData: getEvent,
    response: getEditResponse,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/plantings/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: grpRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=grp",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: fmlRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=fml",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: indRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=ind",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: insRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=ins",
    "GET",
    true,
    "",
    false,
    false
  );

  useEffect(() => {
    if (departmentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          department: departmentRes.data,
        };
      });
    }
    if (grpRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          grp: grpRes.data,
        };
      });
    }
    if (fmlRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          fml: fmlRes.data,
        };
      });
    }
    if (indRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ind: indRes.data,
        };
      });
    }
    if (insRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ins: insRes.data,
        };
      });
    }
  }, [departmentRes, indRes, insRes, grpRes, fmlRes]);

  useEffect(() => {
    getEvent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const addOrganizerName = (type: string, value: Department) => {
    if (type === "grp") {
      let grp = selectOption.grp;
      grp.push(value);
      setSelectOption({
        department: selectOption.department,
        ind: selectOption.ind,
        ins: selectOption.ins,
        grp: grp,
        fml: selectOption.fml,
      });
    } else if (type === "ind") {
      let ind = selectOption.ind;
      ind.push(value);
      setSelectOption({
        department: selectOption.department,
        ind: ind,
        ins: selectOption.ins,
        grp: selectOption.grp,
        fml: selectOption.fml,
      });
    } else if (type === "ins") {
      let ins = selectOption.ins;
      ins.push(value);
      setSelectOption({
        department: selectOption.department,
        ind: selectOption.ind,
        ins: ins,
        grp: selectOption.grp,
        fml: selectOption.fml,
      });
    } else if (type === "fml") {
      let fml = selectOption.fml;
      fml.push(value);
      setSelectOption({
        department: selectOption.department,
        ind: selectOption.ind,
        ins: selectOption.ins,
        grp: selectOption.grp,
        fml: fml,
      });
    }
  };
  const handleEventType = (type: string) => {
    setType(type);
  };

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Events.Edit event")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Events.Events"), url: "/events" },
          { name: t("Events.Edit event"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <EventForm
          mode="edit"
          response={response}
          fetchData={fetchData}
          selectOption={selectOption}
          eventType={handleEventType}
          getEditResponse={getEditResponse}
          addOrganizerName={addOrganizerName}
        />
      )}
    </div>
  );
};

export default EditPlanting;
