import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OldFile from "../../Global/OldFile";
import Video from "../AddPlanting/Video";
import { Link } from "react-router-dom";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faFlag,
  faGlobe,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import LanguagePoint from "../../Global/LanguagePoint";

const PlantingView: React.FC<{ getInfo: any }> = ({ getInfo }) => {
  const [planting, setPlanting] = useState<any>();
  const [lang, setLang] = useState<string>("EN");
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (getInfo) {
      setPlanting(getInfo.data);
    }
  }, [getInfo]);

  useEffect(() => {
    setLang(i18n.language === "fr" ? "FR" : "EN");
  }, [i18n.language]);

  interface LocationItemProps {
    icon: IconDefinition;
    label: string;
    value: string;
  }

  const LocationItem: React.FC<LocationItemProps> = ({ icon, label, value }) => (
    <div className="flex items-center space-x-3 mb-4">
      <FontAwesomeIcon icon={icon} className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-4" />
      <div>
        <p className="text-sm font-regular text-ph-gray mb-2">{label}</p>
        <p className="text-default-text font-medium text-sm">{value}</p>
      </div>
    </div>
  );

  const ParticipantItem: React.FC<{ label: string; value: number }> = ({ label, value }) => (
    <div className="flex flex-col mt-[40px]">
      <p className="text-sm font-regular text-ph-gray mb-2">{label}</p>
      <p className="text-default-text font-medium text-sm">{value}</p>
    </div>
  );

  return (
    <Fragment>
      {planting && (
        <div className="flex flex-wrap justify-between w-full max-w-[98%] xs:max-w-[95%] sm:max-w-[700px] md:max-w-[800px] lg:max-w-[900px] mx-auto px-1 xs:px-2 sm:px-4 lg:px-6">
          <div className="w-full bg-white rounded-md relative">
            <div className="flex flex-col space-y-4 xs:space-y-7">
              {/* section 1 */}
              <div className="border border-ph-input p-4 rounded-md">
                <div className="flex flex-col md:flex-row mb-4 xs:mb-8 mt-[24px] justify-between">
                  <div className="flex flex-col mb-4 xs:mb-7 md:mb-0">
                    <p className="font-medium text-default-text text-[16px] mb-[11px]">
                      {i18n.language === "fr"
                        ? planting.name_transes.fr || planting.name_transes.en
                        : planting.name_transes.en}
                    </p>
                    <div className="flex flex-row items-center">
                      <p className="text-sm font-regular text-ph-gray mr-4">{t("Tree Species.Type")}</p>
                      <p className="text-default-text font-medium text-sm">
                        {planting.type === "SD"
                          ? t("Events.Seed collection")
                          : planting.type === "PT"
                            ? t("Events.Planting tree")
                            : planting.type === "SL"
                              ? t("Events.Seedling collection")
                              : planting.type === "TD"
                                ? t("Events.Tree distribution")
                                : planting.type === "NE"
                                  ? t("Events.Nursery establishment")
                                  : ""}
                      </p>
                    </div>
                    {planting.type === "PT" && planting.planted_trees && (
                      <div className="flex flex-row items-center mt-[11px]">
                        <p className="text-sm font-regular text-ph-gray mr-4">{t("Statistics.Planted trees")}</p>
                        <p className="text-default-text font-medium text-sm">{planting.planted_trees}</p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col items-start md:items-end">
                    <p className="mb-2 text-[12px] font-regular text-ph-gray">{t("Events.Date of event")}</p>
                    <p className="bg-ph-toggle-bg-blue text-sm text-ph-toggle-text-blue text-center opacity-100 py-1 px-4 rounded-[14px]">
                      {planting.date}
                    </p>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-[40px]">
                  <LocationItem icon={faFlag} label={t("Plantings.Country")} value={planting.project_country} />
                  <LocationItem icon={faGlobe} label={t("PlantingFilter.Province")} value={planting.department} />
                  <LocationItem icon={faBuilding} label={t("Plantings.District")} value={planting.municipality} />
                  <LocationItem icon={faUsers} label={t("Plantings.Community")} value={planting.community} />
                </div>
              </div>

              {/* section 2 - Organizer */}
              <div className="border border-ph-input p-4 rounded-md">
                <p className="text-sm font-semibold text-default-text py-2 xs:py-3">{t("Plantings.Organizer")}</p>
                <div className="flex flex-row justify-start mb-2 mt-[24px] ml-[10px]">
                  <p className="text-sm text-ph-gray font-medium w-1/2">{t("Tree Species.Type")}</p>
                  <p className="text-sm text-ph-gray font-medium w-1/2">{t("Tree Species.Name")}</p>
                </div>
                <hr className="my-2 xs:my-4" />

                <div className="flex flex-col space-y-2">
                  {planting.organizers.map((organizer: any, index: number) => (
                    <div key={index} className="flex flex-row">
                      <div className="w-1/2 pr-2">
                        <p className={`text-sm font-medium py-2 xs:py-3 ${index > 0 ? 'mt-2' : ''}`}>
                          {organizer.type === "ind"
                            ? t("Register.Individual")
                            : organizer.type === "psa"
                              ? t("Register.PSA group")
                              : organizer.type === "fml"
                                ? t("Register.Family")
                                : organizer.type === "grp"
                                  ? t("Register.Group")
                                  : t("Register.Institution")}
                        </p>
                      </div>
                      <div className="w-1/2 pl-2">
                        <p className={`text-sm font-medium py-2 xs:py-3 ${index > 0 ? 'mt-2' : ''}`}>
                          {organizer.name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* section 3 - Participants */}
              <div className="border border-ph-input p-4 rounded-md">
                <p className="text-sm font-semibold text-default-text py-2 xs:py-3">{t("PlantingFilter.Participants")}</p>
                <div className="flex flex-col sm:flex-row">
                  <div className="flex flex-col sm:mr-[60px] md:mr-[120px]">
                    <ParticipantItem label={t("Workshops.PSA students")} value={planting.psa_students} />
                    <ParticipantItem label={t("Workshops.PSA tutors")} value={planting.psa_tutors} />
                    <ParticipantItem label={t("Workshops.Farmers")} value={planting.farmers} />
                    <ParticipantItem label={t("Workshops.Promoters")} value={planting.promoters} />
                    <ParticipantItem label={t("Workshops.Other participants")} value={planting.other_participants} />
                  </div>
                  <div className="flex flex-col">
                    <ParticipantItem label={t("Workshops.School students")} value={planting.school_students} />
                    <ParticipantItem label={t("Workshops.School teachers")} value={planting.school_tutors} />
                    <ParticipantItem label={t("Workshops.Junior youth parents")} value={planting.junior_youth_parents} />
                    <ParticipantItem label={t("Workshops.Community leaders")} value={planting.community_leaders} />
                  </div>
                </div>
              </div>

              {/* Resources section */}
              {(planting.files.length > 0 || planting.video_files.length > 0) && (
                <div className="border border-ph-input p-4 rounded-md">
                  <p className="text-sm font-semibold text-default-text py-2 xs:py-3 mb-[24px]">{t("AddPlanting.Relevant resources")}</p>
                  <div className="space-y-4">
                    {planting.files.map((file: any) => (
                      <OldFile
                        key={file.id}
                        delete={false}
                        address={file.file_address}
                        id={file.id}
                        removeOldFile={() => { }}
                      />
                    ))}
                    {planting.video_files.map((video: any, index: number) => (
                      <Video
                        key={index}
                        delete={false}
                        removeAction={() => { }}
                        title={video.title}
                        url={video.link}
                        videoIndex={index}
                      />
                    ))}
                  </div>
                </div>
              )}

              {/* Description section */}
              {(planting.description_transes.en !== "" || planting.description_transes.fr !== "") && (
                <div className="border border-ph-input p-4 rounded-md">
                  <p className="text-sm font-regular text-ph-gray mb-2">{t("Tree Species.Description")}</p>
                  <p className="text-sm text-default-text font-medium max-w-full xs:max-w-[500px] max-h-[400px] break-words overflow-y-auto">
                    {i18n.language === "fr"
                      ? planting.description_transes.fr || planting.description_transes.en
                      : planting.description_transes.en}
                  </p>
                </div>
              )}

              {/* Comment section */}
              {(planting.comment_transes.en !== "" || planting.comment_transes.fr !== "") && (
                <div className="border border-ph-input p-4 rounded-md">
                  <p className="text-sm font-regular text-ph-gray mb-2">
                    {t("Plantings.Comment")} <LanguagePoint lang={lang} />
                  </p>
                  <p className="text-sm text-default-text font-medium max-w-full xs:max-w-[500px] max-h-[400px] break-words overflow-y-auto">
                    {i18n.language === "fr"
                      ? planting.comment_transes.fr || planting.comment_transes.en
                      : planting.comment_transes.en}
                  </p>
                </div>
              )}

              <div className="mt-4 xs:mt-7 pb-[40px]">
                <Link
                  to = "/events"
                  className="main-btn text-sm px-4 xs:px-6 py-2 float-right font-medium"
                >
                  {t("Events.Back to events")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PlantingView;