import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { TableContainer } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@emotion/react";
import TableFooter from "@mui/material/TableFooter";
import WorkshopTableItem from "./WorkshopTableItem";
import { TableThem } from "../../../styles/TableThem";
import TableHeader from "../TableHeader";
import { useTranslation } from "react-i18next";
import { getComparator, sortedRowInformation } from "../tableUtils";
import TablePagination from "../../Global/TablePagination";

const WorkshopTableContent: React.FC<{
  workshops: {
    department_name: string;
    workshop_count: number;
    total_participants: number;
  }[];
}> = ({ workshops }) => {
  const [orderDirection, setOrderDirection] = useState<"desc" | "asc">("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState<string>("name");
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  const handleRequestSort = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string
  ) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value - 1);
  };

  useEffect(() => {
    setPage(0);
  }, [workshops]);

  return (
    <TableContainer>
      <ThemeProvider theme={TableThem}>
        <Table>
          <TableHeader
            valueToOrderBy={valueToOrderBy}
            orderDirection={orderDirection}
            handleRequestSort={handleRequestSort}
            titles={[
              {
                name: t("Plantings.Province"),
                value: "department_name",
                align: "left",
              },
              {
                name: t("Workshops.Workshops"),
                value: "workshop_count",
                align: "center",
              },
              {
                name: t("PlantingFilter.Participants"),
                value: "total_participants",
                align: "center",
              },
            ]}
          />
          <TableBody>
            {sortedRowInformation(
              workshops,
              getComparator(orderDirection, valueToOrderBy)
            )
              .slice(page * 3, page * 3 + 3)
              .map((workshop: any, index: number) => (
                <TableRow key={index}>
                  <WorkshopTableItem
                    department_name={workshop.department_name}
                    workshop_count={workshop.workshop_count}
                    total_participants={workshop.total_participants}
                  />
                </TableRow>
              ))}
            {page === Math.ceil(workshops.length / 3) - 1 &&
              workshops.length % 3 === 1 && (
                <Fragment>
                  <TableRow></TableRow>
                  <TableRow></TableRow>
                </Fragment>
              )}
            {page === Math.ceil(workshops.length / 3) - 1 &&
              workshops.length % 3 === 2 && (
                <Fragment>
                  <TableRow></TableRow>
                </Fragment>
              )}
          </TableBody>
          <TableFooter
            style={{ border: "none", height: "70px", position: "relative" }}
          >
            <Box
              sx={{ flexShrink: 0 }}
              style={{ position: "absolute", left: "0px", top: "20px" }}
            >
              <Stack spacing={2}>
                <TablePagination
                  count={Math.ceil(workshops.length / 3)}
                  handleChange={handleChangePage}
                />
              </Stack>
            </Box>
          </TableFooter>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
};
export default WorkshopTableContent;
