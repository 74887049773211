const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <p className="text-sm font-medium text-ph-gray-text">
      <span className="text-ph-gray ">&copy; {year} </span> Nyitondu
    </p>
  );
};

export default Footer;
