import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="w-full h-full p-4 bg-white flex flex-col justify-center items-center">
      <img src="/img/Error pack - 404.svg" alt="not found" />
      <Link to="/" className="bg-ph-btn text-white text-xs rounded-3xl px-6 py-2 hover:bg-ph-hover">
        Go to Home Page
      </Link>
    </div>
  );
};

export default NotFound;
