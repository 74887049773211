import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";

type title = { name: string; value: string; align: "left" | "center" };

const TableHeader: React.FC<{
  valueToOrderBy: string;
  orderDirection: "desc" | "asc";
  handleRequestSort: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string
  ) => void;
  titles: title[];
}> = ({ titles, valueToOrderBy, orderDirection, handleRequestSort }) => {
  const createSortHandler =
    (property: string) =>
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      handleRequestSort(event, property);
    };

  let angle = <FontAwesomeIcon icon={faAngleUp} />;
  if (orderDirection === "desc") {
    angle = <FontAwesomeIcon icon={faAngleDown} />;
  }
  return (
    <TableHead>
      <TableRow>
        {titles.map((title: title) => {
          return title.value ? (
            <TableCell align={title.align} key={title.value}>
              <TableSortLabel
                active={valueToOrderBy === title.value}
                direction={
                  valueToOrderBy === title.value ? orderDirection : "asc"
                }
                onClick={createSortHandler(title.value)}
                IconComponent={() => (
                  <div>
                    {valueToOrderBy === title.value && (
                      <div className="ml-3">{angle}</div>
                    )}
                  </div>
                )}
              >
                {title.name}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={title.name} style={{ fontWeight: "500" }}>
              {title.name}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
