import { useState, useEffect } from "react";
import axios, { Method } from "axios";
import { useDispatch, useSelector } from "react-redux";
import { accessToken } from "../store";
import { LoginSliceActions } from "../store/login-slice";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const useAxios = (
  url: string,
  method: Method,
  autoRun: boolean,
  popup: string,
  isCallRefresh: boolean,
  isCallBack: boolean,
  contentType: string = "application/json",
  timeZone?: string
) => {
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [call, setCall] = useState<boolean>(true);
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();
  const access = useSelector(accessToken);
  const history = useHistory();
  const dispatch = useDispatch();
  let headers = {
    "Content-Type": contentType,
    Authorization: `Bearer ${access}`,
    "Accept-Language": i18n.language,
  };

  let headerTimezone = {
    "Content-Type": contentType,
    Authorization: `Bearer ${access}`,
    "Accept-Language": i18n.language,
    "Client-Timezone": timeZone,
  };

  const fetchData = async (dataI: any, urlI: string = url) => {
    try {
      const res = await axios({
        url: urlI,
        method: method,
        data: dataI,
        headers: timeZone ? headerTimezone : headers,
      });
      setResponse(res);
      setCall(false);
      setLoading(false);
      if (popup.trim() !== "") {
        toast.success(popup);
      }
    } catch (err: any) {
      if (err.response?.status === 401 && isCallRefresh) {
        setCall(true);
        callRefresh(dataI, urlI);
      } else if (err.response?.status === 0) {
        toast.error("Network Error.");
      } else if (err.response?.status === 400) {
        try {
          const response = JSON.parse(err.request.response);
          Object.values(response).forEach((errMessage) => {
            toast.error(`${errMessage}`);
          });
        } catch (parseError) {
          toast.error("Error parsing response.");
        }
      }
      setError(err);
    }
  };

  const callRefresh = (dataI: any, urlI: string) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/api/token/refresh/", {
        refresh: localStorage.getItem("refresh"),
      })
      .then(function (resp: any) {
        dispatch(
          LoginSliceActions.refresh({
            token: resp.data.access,
            refresh: resp.data.refresh,
          })
        );
        headers = {
          "Content-Type": contentType,
          Authorization: `Bearer ${resp.data.access}`,
          "Accept-Language": i18n.language,
        };
        headerTimezone = {
          "Content-Type": contentType,
          Authorization: `Bearer ${resp.data.access}`,
          "Accept-Language": i18n.language,
          "Client-Timezone": timeZone,
        };
        if (isCallBack) {
          fetchData(dataI, urlI);
        }
      })
      .catch(function (err) {
        dispatch(LoginSliceActions.userLogOut());
        history.replace("/");
      });
  };
  useEffect(() => {
    setCall(true);
  }, [url]);
  useEffect(() => {
    if (!autoRun || !call) {
      return;
    }
    setLoading(true);
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call, access]);

  return { response, error, loading, fetchData };
};

export default useAxios;
