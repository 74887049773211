const InvalidURL = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <img src="/img/Error pack - Error URL not valid.svg" alt="invalid url" />
      <h1 className="text-base sm:text-3xl font-semibold mt-12">
        The URL Is Either Incorrect Or No Longer Valid.
      </h1>
    </div>
  );
};

export default InvalidURL;
