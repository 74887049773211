import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import DeleteAlert from "../../ErrorAndAlert/DeleteAlert";
import ActionItem from "../../Global/ActionItem";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { PlantingOrganizer } from "../../../type/plantingOrganizer";

const PlantingOrganizerTableItem: React.FC<{
  plantingOrganizer: PlantingOrganizer;
  fetchData: () => void;
  lastIndex: number;
}> = ({ plantingOrganizer, fetchData: propFetchData, lastIndex }) => {
  const { i18n, t } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);
  const { id } = useParams<any>();
  const index = lastIndex >= 0 ? true : false;
  const scroll = lastIndex === 0 ? true : false;

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/organizers/${plantingOrganizer.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.Successful delete")}`,
    true,
    true
  );
  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (plantingOrganizer.is_deletable) {
      isShowDelete(true);
    } else {
      toast.error(t(`Message.Delete ${id}`));
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };
  const active =
    i18n.language === "fr"
      ? plantingOrganizer.is_active_transes.fr
      : plantingOrganizer.is_active_transes.en;

  return (
    <Fragment>
      <TableCell align="left">{plantingOrganizer.name}</TableCell>
      <TableCell>
        <p
          className={`${
            plantingOrganizer.is_active_transes.en === "Active"
              ? "bg-ph-toggle-bg-green text-ph-toggle-text-green"
              : "bg-ph-toggle-bg-red text-ph-red-warning"
          } text-center w-16 py-1 rounded-xl text-sm`}
        >
          {active}
        </p>
      </TableCell>
      <TableCell align="right">
        <ActionItem
          delete={true}
          editLink={`/setting/organizers/edit/${id}-${plantingOrganizer.slug}`}
          deleteAction={deleteAction}
          view=""
          viewLink=""
          historyLink=""
          viewHistory=""
        />
      </TableCell>
      {showDelete && (
        <DeleteAlert
          type={t("Planting organizers.Planting organizer")}
          hide={hide}
          deleteAction={deleteItem}
        />
      )}
    </Fragment>
  );
};

export default PlantingOrganizerTableItem;
