import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";

type FormValues = {
  url: string;
  title: string;
};
const VideoLinkPopup: React.FC<{
  closeVideoLink: () => void;
  addVideo: (url: string, title: string) => void;
}> = ({ closeVideoLink, addVideo }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormValues>();

  const addCollaboratorName = (data: FormValues) => {
    addVideo(data.url, data.title);
    reset();
    closeVideoLink();
  };

  return (
    <div className="w-full h-full z-[9999] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
      <div className="bg-white w-full h-fit px-9 py-12 sm:w-[492px] rounded-md shadow-md">
        <h2 className="font-semibold text-default-text">
          {t("Plantings.Add video link")}
        </h2>
        <form onSubmit={handleSubmit(addCollaboratorName)}>
          <p className="text-sm text-default-text mb-[6px] mt-7">
            {t("Plantings.Video URL")} *{" "}
            <span
              title={t("Plantings.Video link hint") + ""}
              className="text-ph-gray-text ml-2"
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </span>
          </p>
          <input
            {...register("url", {
              required: `${t("Message.Required field")}`,
              pattern:
                /((?:https?:\/\/|ftp:\/\/)(?:\S+\.[^()\n ]+(?:\([^)]*\)|[^.,;:?!"'\n\)\]<* ])+))/i,
            })}
            type="text"
            id="addCollaborator"
            placeholder={t("Plantings.Video URL") + ""}
            className="text-sm w-full pl-4 rounded-md py-3 bg-ph-input border focus:bg-white focus:outline-none focus:border-default-highlight border-ph-iborder placeholder-ph-light-gray"
          />
          {errors.url && <p className="error-text">{errors.url.message}</p>}
          {errors.url?.type === "pattern" && (
            <p className="error-text">{t("Message.Invalid URL")}</p>
          )}

          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("Plantings.Title")} *
          </p>
          <input
            {...register("title", {
              required: `${t("Message.Required field")}`,
            })}
            type="text"
            placeholder={t("Plantings.Title") + ""}
            className="text-sm w-full pl-4 rounded-md py-3 bg-ph-input border focus:bg-white focus:border-default-highlight focus:outline-none border-ph-iborder placeholder-ph-light-gray"
          />
          {errors.title && <p className="error-text">{errors.title.message}</p>}

          <button
            type="submit"
            className="bg-ph-btn float-right text-sm font-medium mt-16 text-white rounded-3xl px-6 py-2 hover:bg-ph-hover"
          >
            {t("Plantings.Add video")}
          </button>
          <button
            onClick={() => {
              closeVideoLink();
            }}
            type="button"
            className="text-default-text mr-4 float-right text-sm font-medium mt-16 rounded-3xl px-6 py-2"
          >
            {t("Message.Cancel")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default VideoLinkPopup;
