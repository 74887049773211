import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faObjectsColumn } from "@fortawesome/pro-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";

const PlantingOrganizerMenu: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const location = useLocation();
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = createTheme({
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#414141",
            fontFamily: "Inter,sans-serif",
            fontSize: "14px",
            ":hover": {
              color: "#439FC6",
              backgroundColor: "white",
            },
            ":focus": {
              backgroundColor: "white",
            },
            "&.Mui-selected": {
              color: "#439FC6",
              backgroundColor: "white",
              "& .icon": {
                color: "#439FC6",
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: "#414141",
            fontFamily: "Inter,sans-serif",
            padding: "0px",
            fontWeight: "500",
            textTransform: "inherit",
            fontSize: "14px",
            "& .icon": {
              color: "#878787",
            },
            ":hover": {
              color: "#439FC6",
              backgroundColor: "white",
              "& .icon": {
                color: "#439FC6",
              },
            },
            ":focus": {
              backgroundColor: "white",
            },
            "&[aria-selected=true]": {
              color: "#439FC6",
              "& .icon": {
                color: "#439FC6",
              },
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        aria-selected={location.pathname.includes("/setting/organizers")}
      >
        <FontAwesomeIcon
          icon={faObjectsColumn}
          className="mr-2 pr-[2px] text-ph-menu-gray icon"
        />{" "}
        {t("Planting organizers.Planting organizers")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            onClose();
          }}
          selected={
            location.pathname.includes("/setting/organizers/grp") ||
            location.pathname.includes("/setting/organizers/add/grp") ||
            location.pathname.includes("/setting/organizers/edit/grp")
          }
          className="text-default-text"
        >
          <Link
            to="/setting/organizers/grp"
            className="text-sm py-[2px] font-medium  hover:text-default-highlight hover:bg-ph-input w-full mt-1 inline-block"
          >
            &#x2022; {t("Organizer.Groups")}
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onClose();
          }}
          selected={
            location.pathname.includes("/setting/organizers/ins") ||
            location.pathname.includes("/setting/organizers/add/ins") ||
            location.pathname.includes("/setting/organizers/edit/ins")
          }
          className="text-default-text"
        >
          {" "}
          <Link
            to="/setting/organizers/ins"
            className="text-sm py-[2px] font-medium hover:text-default-highlight hover:bg-ph-input w-full inline-block"
          >
            &#x2022; {t("Organizer.Institutions")}
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onClose();
          }}
          selected={
            location.pathname.includes("/setting/organizers/ind") ||
            location.pathname.includes("/setting/organizers/add/ind") ||
            location.pathname.includes("/setting/organizers/edit/ind")
          }
          className="text-default-text"
        >
          <Link
            to="/setting/organizers/ind"
            className="text-sm font-medium py-[2px] hover:text-default-highlight hover:bg-ph-input w-full inline-block"
          >
            &#x2022; {t("Organizer.Individuals")}
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onClose();
          }}
          selected={
            location.pathname.includes("/setting/organizers/fml") ||
            location.pathname.includes("/setting/organizers/add/fml") ||
            location.pathname.includes("/setting/organizers/edit/fml")
          }
          className="text-default-text"
        >
          {" "}
          <Link
            to="/setting/organizers/fml"
            className="text-sm py-[2px] font-medium hover:text-default-highlight hover:bg-ph-input w-full inline-block"
          >
            &#x2022; {t("Organizer.Families")}
          </Link>
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
};

export default PlantingOrganizerMenu;
