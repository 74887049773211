
const AccessDenied = () =>{
    return(
        <div className="flex flex-col justify-center items-center">
            <img src="/img/Error pack - Access Denied.svg" alt="Access Denied"/>
            <h1 className="text-base sm:text-3xl font-semibold mt-10">Access Denied</h1>
        </div>
    )
}

export default AccessDenied;