import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Modal, ThemeProvider } from "@mui/material";
import { useForm } from "react-hook-form";
import CustomAutocomplete from "../../../Global/CustomAutocomplete";
import { theme } from "../../../Register/AddRegister/FormTheme";
import SpeciesList from "../../../../type/speciesList";
import useAxios from "../../../../hook/useAxios";
import i18n from "../../../../i18n";
import NumberField from "../../../Global/NumberField";

type FormValues = {
  source: string;
  species: SpeciesList;
  storage: string;
  quantity: number;
};
const AddSeedCollection = ({
  handleModalClose,
  send,
  getInfo,
  textButton,
  type,
}: {
  handleModalClose: () => void;
  send: (data: any) => void;
  getInfo?: any;
  textButton: string;
  type: string;
}) => {

  const [treeSpecies, setTreeSpecies] = useState<SpeciesList[]>([]);

  const { response: TreeSpecies } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    true,
    false
  );


  const { t } = useTranslation();

  const {
    handleSubmit,
    setValue,
    register,
    clearErrors,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (getInfo) {
      setValue("species", { name_transes: getInfo.data.species_name_transes, slug: getInfo.data.species_slug });
      setValue("source", getInfo.data.source);
      setValue("quantity", getInfo.data.quantity);
      setValue("storage", getInfo.data.storage);
    }
  }, [getInfo, setValue]);

  useEffect(() => {
    if (TreeSpecies) {
      setTreeSpecies(TreeSpecies.data)
    }
  }, [TreeSpecies]);

  const addCollection = (data: FormValues) => {
    send({
      species: data.species.slug,
      source: data.source,
      quantity: data.quantity,
      storage: data.storage
    });
  };
  const quantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      clearErrors("quantity");
    }
  };

  return (
    <Modal
      open
      onClose={() => handleModalClose()}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
    >
      <div className="py-12 px-8 bg-ph-white w-[70%] lg:w-[615px] shadow-[0px_3px_6px_#00000029] rounded-md">
        <ThemeProvider theme={theme}>
          <div className="font-semibold text-default-text text-base">
            {type === "seed"
              ? t("Events.Seed collection detail")
              : t("Events.Seedling collection detail")
            }
          </div>
          <form onSubmit={handleSubmit(addCollection)}>
            <div className="mt-8 flex flex-col md:flex-row md:items-center">
              <div className="w-full sm:w-[48.5%] sm:pl-2">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {type === "seed"
                    ? `${t("Events.Seed species")} *`
                    : `${t("Events.Seedling species")} *`
                  }
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                  control={control}
                  name="species"
                  selectOptions={treeSpecies}
                  placeholder={type === "seed" ? t("Events.Seed species") : t("Events.Seedling species")}
                  getOptionLabel={(option) =>
                    i18n.language === "fr"
                      ? option.name_transes?.fr === ""
                        ? option.name_transes?.en
                        : option.name_transes?.fr
                      : option.name_transes?.en
                  } />
                {errors.species && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row mt-7">
              <div className="w-full sm:w-[48.5%] sm:pl-2">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {type === "seed"
                    ? `${t("Events.Quantity in “gr”")} *`
                    : `${t("Events.Number")} *`
                  }
                </p>
                <NumberField
                  onChange={quantityChange}
                  inputStyle="py-3 placeholder-ph-light-gray"
                  getValues={getValues}
                  clearError={clearErrors}
                  name="quantity"
                  placeholder={type === "seed" ? t("Events.Quantity in “gr”") : t("Events.Number")}
                  setValue={setValue}
                  register={register("quantity", {
                    pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                    min: {
                      value: 1,
                      message: `${t("Message.The minimum number is 1")}`,
                    },
                    required: `${t("Message.Required field")}`,

                  })}
                  readOnl={false}
                />
                {errors.quantity?.type === "min" && (
                  <p className="error-text">
                    {t("Message.The minimum number is 1")}
                  </p>
                )}
                {errors.quantity?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
                {errors.quantity &&
                  <p className="error-text">{t("Message.Required field")}</p>
                }
              </div>
              <div className="w-full sm:w-[51.5%] sm:pl-[23px]">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {t("Events.Source")} *
                </p>
                <input type="text"
                  {...register("source", {
                    required: `${t("Message.Required field")}`,
                  })}
                  placeholder={t("Events.Source") + ""}
                  className="input-field text-sm pl-4 py-3 placeholder-ph-light-gray" />
                {errors.source && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="w-full sm:pl-2 mt-7">
              <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                {t("Events.Storage")} *
              </p>
              <textarea
                {...register("storage", {
                  required: "required",
                })}
                rows={4}
                className="input-field resize-none text-sm pl-4 py-[10px] placeholder-ph-light-gray"
                placeholder={t("Tree Species.Description") + ""}
              />
              {errors.storage && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
            </div>
            <div className="mt-10 flex flex-row justify-end">
              <button
                onClick={() => {
                  handleModalClose();
                }}
                type="button"
                className="text-default-text text-sm mt-16rounded-3xl px-6 py-2 font-medium"
              >
                {t("Message.Cancel")}
              </button>
              <button
                type="submit"
                className="main-btn px-[23px] py-[10px] text-sm"
              >
                {textButton}
              </button>
            </div>
          </form>
        </ThemeProvider>
      </div>
    </Modal>
  );
};

export default AddSeedCollection;
