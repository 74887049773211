import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { registerFilterSet, registerURL } from "../../../store";
import AppliedFilter from "./AppliedFilter";
import FilterBox from "./FilterBox";
import SearchField from "../../Global/SearchField";
import Export from "../../ExportLists/Export";
import Loading from "../../Global/Loading";
import useAxios from "../../../hook/useAxios";
import { useParams } from "react-router-dom";
import { RegisterRecord } from "../../../type/register";
import RegisterTableItem from "./RegisterTableItem";
import GenericTable from "../../Global/GenericTable";

const Register = () => {
  const [registerList, setRegister] = useState<RegisterRecord[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const isAppliedFilter = useSelector(registerFilterSet);
  const url = useSelector(registerURL);
  const { id } = useParams<any>();
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/registers${
        id === "all" ? "" : `/${id}`
      }${url}&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setRegister(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [url, sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleLoading = () => {
    setExportLoading(!exportLoading);
  };

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Register.Register")}
        <span className="float-right mr-[-16px]">
          <Export
            url={url}
            name="registers"
            title={t("Register.Export registers") + ""}
            handleLoading={handleLoading}
            needBorder={false}
          />
        </span>
      </h1>
      <FilterBox />
      {isAppliedFilter && <AppliedFilter />}
      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        <GenericTable
          search={sendSearch}
          countData={count}
          handleChange={handleChange}
          dataList={registerList}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          titles={[
            { name: t("Register.Code"), value: "tag" },
            { name: t("Register.Planting date"), value: "date" },
            { name: t("Register.Tree species"), value: "species" },
            { name: t("Register.Nursery"), value: "nursery" },
            {
              name: t("Register.Responsible"),
              value: "responsible_name",
            },
            {
              name: t("Register.Category"),
              value: "responsible_category",
            },
            { name: t("Tree Species.Type"), value: "responsible_type" },
            { name: t("Register.Latest incidents"), value: "" },
            { name: t("Register.Replanting"), value: "is_replanting" },
          ]}
          renderItem={(register, fetchData, lastIndex) => (
            <RegisterTableItem
              registerItem={register}
              fetchData={fetchData}
              lastIndex={lastIndex}
            />
          )}
        />
        <br />
      </div>
      {exportLoading && (
        <div className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
          <Loading loading={true} />
        </div>
      )}
    </div>
  );
};

export default Register;
