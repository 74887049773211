import { Fragment, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import {
  faPencil,
  faTrashCan,
  faTruckFast,
  faHouseTree,
} from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { profileInfo } from "../../../store";
import { useSelector } from "react-redux";

const NurseriesActionItem: React.FC<{
  suppliesLink?: string;
  editLink: string;
  inventoryLink: string;
  deleteAction: () => void;
  delete: boolean;
  type: string;

  notEditable?: boolean;
}> = ({
  type,
  suppliesLink,
  editLink,
  delete: propsDelete,
  deleteAction,
  inventoryLink,
}) => {
  const [showAction, isShowAction] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const profile = useSelector(profileInfo);
  const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener("click", handelClick, true);
  }, []);
  const toggleAction = () => {
    isShowAction(!showAction);
  };

  const handelClick = (e: any) => {
    if (
      !divRef.current?.contains(e.target) &&
      !btnRef.current?.contains(e.target)
    ) {
      isShowAction(false);
    }
  };
  return (
    <div className="relative">
      <button
        ref={btnRef}
        onClick={toggleAction}
        className="text-ph-gray px-3 py-1 rounded-md hover:bg-ph-bgIcon hover:text-ph-btn"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      {showAction && (
        <div
          ref={divRef}
          className={`absolute z-10 right-1 grid-flow-col text-left shadow-lg rounded-md bg-white pl-4 w-fit py-3 `}
        >
          <Fragment>
            <div className={` ${type === "ex" ? "w-[80px]" : "w-[215px]"}`}>
              {type === "in" &&
                (profile.role === "A" || profile.role === "C") && (
                  <>
                    <Link
                      to={suppliesLink!!}
                      className="text-sm text-default-text flex items-center py-[5px]"
                    >
                      <FontAwesomeIcon className="mr-2" icon={faTruckFast} />
                      {t("Tree nurseries.Tree supplies")}
                    </Link>
                    <Link
                      to={inventoryLink!!}
                      className="text-sm text-default-text flex items-center py-[5px]"
                    >
                      <FontAwesomeIcon className="mr-2" icon={faHouseTree} />
                      {t("Tree nurseries.Tree inventory tracking")}
                    </Link>
                  </>
                )}
              <Link
                to={editLink!!}
                className="text-sm text-default-text flex items-center py-[5px]"
              >
                <FontAwesomeIcon className="mr-2" icon={faPencil} />
                {t("PlantingFilter.Edit")}
              </Link>

              {propsDelete && type === "ex" && (
                <button
                  onClick={() => {
                    isShowAction(false);
                    deleteAction();
                  }}
                  className="text-sm text-default-text flex items-center py-[5px]"
                >
                  <FontAwesomeIcon icon={faTrashCan} className="mr-2" />{" "}
                  {t("PlantingFilter.Delete")}
                </button>
              )}
            </div>
          </Fragment>
        </div>
      )}
    </div>
  );
};

export default NurseriesActionItem;
