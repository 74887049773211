import {
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Participants: React.FC<{
  register: any;
  setValue: any;
  clearErrors: any;
  getValues: any;
  participantsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ register, setValue, clearErrors, getValues, participantsChange }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {/* psaStudents */}
      <div className="flex flex-col md:flex-row justify-between md:items-center  mt-7">
        <p className="text-sm text-default-text mb-[6px]">
          {t("Workshops.PSA students")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("psaStudent") > 0) {
                setValue("psaStudent", getValues("psaStudent") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("psaStudent")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("participants");
              setValue("psaStudent", Number(getValues("psaStudent")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* schoolStudents */}
      <div className="flex flex-col md:flex-row justify-between md:items-center  mt-7">
        <p className="text-sm text-default-text mb-[6px] ">
          {t("Workshops.School students")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("schoolStudent") > 0) {
                setValue("schoolStudent", getValues("schoolStudent") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("schoolStudent")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("schoolStudent");
              setValue("schoolStudent", Number(getValues("schoolStudent")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* psaTutors */}
      <div className="flex flex-col md:flex-row justify-between md:items-center  mt-7">
        <p className="text-sm text-default-text mb-[6px]">
          {t("Workshops.PSA tutors")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("psaTutor") > 0) {
                setValue("psaTutor", getValues("psaTutor") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("psaTutor")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("psaTutor");
              setValue("psaTutor", Number(getValues("psaTutor")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* school teachers */}
      <div className="flex flex-col md:flex-row justify-between md:items-center  mt-7">
        <p className="text-sm text-default-text mb-[6px]">
          {t("Workshops.School teachers")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("schoolTeacher") > 0) {
                setValue("schoolTeacher", getValues("schoolTeacher") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("schoolTeacher")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("schoolTeacher");
              setValue("schoolTeacher", Number(getValues("schoolTeacher")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* Farmers */}
      <div className="flex flex-col md:flex-row justify-between md:items-center  mt-7">
        <p className="text-sm text-default-text mb-[6px] ">
          {t("Workshops.Farmers")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("farmers") > 0) {
                setValue("farmers", getValues("farmers") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("farmers")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("farmers");
              setValue("farmers", Number(getValues("farmers")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* promoters */}
      <div className="flex flex-col md:flex-row justify-between md:items-center  mt-7">
        <p className="text-sm text-default-text mb-[6px] ">
          {t("Workshops.Promoters")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("promoters") > 0) {
                setValue("promoters", getValues("promoters") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("promoters")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("promoters");
              setValue("promoters", Number(getValues("promoters")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* juniors */}
      <div className="flex flex-col md:flex-row justify-between md:items-center  mt-7">
        <p className="text-sm text-default-text mb-[6px] ">
          {t("Workshops.Junior youth parents")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("junior_youth_parents") > 0) {
                setValue(
                  "junior_youth_parents",
                  getValues("junior_youth_parents") - 1
                );
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("junior_youth_parents")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("junior_youth_parents");
              setValue(
                "junior_youth_parents",
                Number(getValues("junior_youth_parents")) + 1
              );
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* community leaders */}
      <div className="flex flex-col md:flex-row justify-between md:items-center  mt-7">
        <p className="text-sm text-default-text mb-[6px] ">
          {t("Workshops.Community leaders")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("community_leaders") > 0) {
                setValue(
                  "community_leaders",
                  getValues("community_leaders") - 1
                );
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("community_leaders")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("community_leaders");
              setValue(
                "community_leaders",
                Number(getValues("community_leaders")) + 1
              );
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
      {/* other participants */}
      <div className="flex flex-col md:flex-row justify-between md:items-center  mt-7">
        <p className="text-sm text-default-text mb-[6px]">
          {t("Workshops.Other participants")}
        </p>
        <div className="relative">
          <button
            type="button"
            className="number-field-icons top-3 left-2"
            onClick={() => {
              if (getValues("other") > 0) {
                setValue("other", getValues("other") - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faCircleMinus} size="lg" />
          </button>
          <input
            {...register("other")}
            onChange={participantsChange}
            type="number"
            className="input-field text-sm py-3 px-10"
          />
          <button
            type="button"
            className="number-field-icons right-2 top-3"
            onClick={() => {
              clearErrors("other");
              setValue("other", Number(getValues("other")) + 1);
            }}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default Participants;
