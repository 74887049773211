import {
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NumberField: React.FC<{
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  register: any;
  name: string;
  setValue: any;
  getValues: any;
  clearError?: any;
  styleClass?: string;
  readOnl: boolean;
  maxValue?: number;
  inputStyle: string;
  invalidInput?: boolean;
}> = ({
  onChange,
  placeholder,
  register,
  name,
  setValue,
  getValues,
  clearError,
  styleClass,
  readOnl,
  inputStyle,
  invalidInput,
  maxValue,
}) => {
  return (
    <div className={`relative ${styleClass}`}>
      <button
        type="button"
        onClick={() => {
          if (!readOnl) {
            if (getValues(name) > 0) {
              setValue(name, Number(getValues(name)) - 1);
            }
          }
        }}
        className={`number-field-icons top-[27%] left-2 ${
          readOnl ? "bg-ph-bg-white" : ""
        }`}
      >
        <FontAwesomeIcon icon={faCircleMinus} size="lg" />
      </button>
      <input
        {...register}
        type="number"
        readOnly={readOnl}
        onChange={onChange}
        placeholder={placeholder}
        className={
          readOnl
            ? `text-sm  h-12 px-10 text-ph-white bg-ph-bg-gray border border-ph-iborder outline-none rounded-[4px] w-full ${inputStyle}`
            : `text-sm px-10 bg-ph-input border border-ph-iborder outline-none focus:bg-white rounded-[4px] w-full focus:border-default-highlight ${inputStyle} ${
                invalidInput ? "border-red-500" : ""
              } `
        }
      />
      <button
        type="button"
        onClick={() => {
          if (clearError) {
            clearError(name);
          }
          if (!readOnl) {
            const currentValue = Number(getValues(name));
            if (maxValue) {
              if (currentValue < maxValue!!) {
                setValue(name, currentValue + 1);
              } else {
                setValue(name, maxValue);
              }
            } else {
              if (getValues(name) > 0) {
                setValue(name, Number(getValues(name)) + 1);
              } else {
                setValue(name, 1);
              }
            }
          }
        }}
        className={`number-field-icons top-[27%] right-2 ${
          readOnl ? "bg-ph-bg-white" : ""
        }`}
      >
        <FontAwesomeIcon icon={faCirclePlus} size="lg" />
      </button>
    </div>
  );
};

export default NumberField;
