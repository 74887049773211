import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CollaboratorName from "../type/collaboratorName";
import { Department } from "../type/department";
import SpeciesList from "../type/speciesList";

const initialState: {
  collaboratorNames: CollaboratorName[];
  department: Department[];
  grp: CollaboratorName[];
  ind: CollaboratorName[];
  ins: CollaboratorName[];
  fml: CollaboratorName[];
  speciesList: SpeciesList[];
} = {
  collaboratorNames: [],
  department: [],
  ind: [],
  grp: [],
  ins: [],
  fml: [],
  speciesList: [],
};
const selectFieldsSlice = createSlice({
  name: "selectField",
  initialState,
  reducers: {
    setCollaboratorNames: (state, action: PayloadAction<string>) => {
      if (action.payload === "grp") {
        state.collaboratorNames = state.grp;
      } else if (action.payload === "ind") {
        state.collaboratorNames = state.ind;
      } else if (action.payload === "ins") {
        state.collaboratorNames = state.ins;
      } else if (action.payload === "fml") {
        state.collaboratorNames = state.fml;
      }
    },
    setDepartments: (state, action: PayloadAction<Department[]>) => {
      state.department = action.payload;
    },
    setGrp: (state, action: PayloadAction<CollaboratorName[]>) => {
      state.grp = action.payload;
    },
    setInd: (state, action: PayloadAction<CollaboratorName[]>) => {
      state.ind = action.payload;
    },
    setIns: (state, action: PayloadAction<CollaboratorName[]>) => {
      state.ins = action.payload;
    },
    setFml: (state, action: PayloadAction<CollaboratorName[]>) => {
      state.fml = action.payload;
    },
    addCollaboratorName: (
      state,
      action: PayloadAction<{ type: string; name: string; slug: string }>
    ) => {
      if (action.payload.type === "ind") {
        state.ind.push({
          name: action.payload.name,
          slug: action.payload.slug,
        });
      } else if (action.payload.type === "ins") {
        state.ins.push({
          name: action.payload.name,
          slug: action.payload.slug,
        });
      } else if (action.payload.type === "grp") {
        state.grp.push({
          name: action.payload.name,
          slug: action.payload.slug,
        });
      } else if (action.payload.type === "fml") {
        state.fml.push({
          name: action.payload.name,
          slug: action.payload.slug,
        });
      }
      state.collaboratorNames.push({
        name: action.payload.name,
        slug: action.payload.slug,
      });
    },
    setSpeciesList: (state, action: PayloadAction<SpeciesList[]>) => {
      state.speciesList = action.payload;
    },
  },
});

export const selectFieldAction = selectFieldsSlice.actions;
export default selectFieldsSlice;
