import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TaggingToolFilters } from "../../../store";

import AppliedItem from "../../Register/RegisterList/AppliedItem";
import i18n from "../../../i18n";
import { TaggingToolFilterAction } from "../../../store/taggingToolFilter-slice";

const TaggingToolAppliedFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector(TaggingToolFilters);

  return (
    <div className="bg-white p-4 rounded-md relative mt-3">
      <h1 className="text-ph-gray font-medium text-sm">
        {t("Tree Species.Applied Filters")}
      </h1>
      <div className="flex flex-wrap items-center">
        {filters.community && (
          <AppliedItem
            type={t("Community.Community")}
            value={filters.community.name + ` (${filters.community.initials})`}
            removeFilter={() => {
              dispatch(TaggingToolFilterAction.removeCommunity());
              dispatch(TaggingToolFilterAction.setURL());
              dispatch(TaggingToolFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.responsible && (
          <AppliedItem
            type={t("Register.Responsible")}
            value={
              filters.responsible.full_name +
              ` (${filters.responsible.initials})`
            }
            removeFilter={() => {
              dispatch(TaggingToolFilterAction.removeResponsible());
              dispatch(TaggingToolFilterAction.setURL());
              dispatch(TaggingToolFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.nursery && (
          <AppliedItem
            type={t("Tagging tool.Nursery")}
            value={
              i18n.language === "en"
                ? filters.nursery.name_transes?.en ||
                  filters.nursery.name_transes?.fr
                : filters.nursery.name_transes?.fr
            }
            removeFilter={() => {
              dispatch(TaggingToolFilterAction.removeNursery());
              dispatch(TaggingToolFilterAction.setURL());
              dispatch(TaggingToolFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.species && (
          <AppliedItem
            type={t("Tagging tool.Species")}
            value={
              i18n.language === "en"
                ? filters.species.name_transes?.en ||
                  filters.species.name_transes?.fr
                : filters.species.name_transes?.fr
            }
            removeFilter={() => {
              dispatch(TaggingToolFilterAction.removeSpecies());
              dispatch(TaggingToolFilterAction.setURL());
              dispatch(TaggingToolFilterAction.isFilterSet());
            }}
          />
        )}
        {filters.isUsed && (
          <AppliedItem
            type={t("Tagging tool.Used")}
            value={
              filters.isUsed === "all"
                ? t("Statistics.All")
                : filters.isUsed === "true"
                ? t("Message.Yes")
                : t("Message.No")
            }
            removeFilter={() => {
              dispatch(TaggingToolFilterAction.removeIsUsed());
              dispatch(TaggingToolFilterAction.setURL());
              dispatch(TaggingToolFilterAction.isFilterSet());
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TaggingToolAppliedFilter;
