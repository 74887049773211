import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Modal, Select, ThemeProvider } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomAutocomplete from "../../../Global/CustomAutocomplete";
import { theme } from "../../../Register/AddRegister/FormTheme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NumberField from "../../../Global/NumberField";
import SpeciesList from "../../../../type/speciesList";
import useAxios from "../../../../hook/useAxios";
import i18n from "../../../../i18n";
import { Community } from "../../../../type/community";

type FormValues = {
  species: SpeciesList;
  number: number;
  community: Community;
  recipient_type: string;
  number_received: number;
};
const AddTreeDistro = ({
  handleModalClose,
  send,
  getInfo,
  textButton,
  title,
}: {
  handleModalClose: () => void;
  send: (data: any) => void;
  getInfo?: any;
  textButton: string;
  title: string;
}) => {
  const { t } = useTranslation();
  const [communities, setCommunities] = useState<Community[]>([]);
  const [treeSpecies, setTreeSpecies] = useState<SpeciesList[]>([]);

  const { response: TreeSpecies } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: communitiesRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/communities/all",
    "GET",
    true,
    "",
    true,
    false
  );
  const {
    handleSubmit,
    setValue,
    register,
    clearErrors,
    control,
    getValues,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (TreeSpecies) {
      setTreeSpecies(TreeSpecies.data);
    }
    if (communitiesRes) {
      setCommunities(communitiesRes.data)
    }
  }, [TreeSpecies, communitiesRes]);
  useEffect(() => {
    if (getInfo) {
      setValue("species", {
        slug: getInfo.data.species_slug,
        name_transes: getInfo.data.species_name_transes
      })
      setValue("number", getInfo.data.number)
      setValue("number_received", getInfo.data.number_received)
      setValue("recipient_type", getInfo.data.recipient_type)
      setValue("community", {
        slug: getInfo.data.community_slug,
        name: getInfo.data.community
      })
    }
  }, [getInfo])

  const addMunicipality = (data: FormValues) => {
    send({
      species: data.species.slug,
      community: data.community.slug,
      number: data.number,
      recipient_type: data.recipient_type,
      number_received: data.number_received
    });
  };
  const numberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      clearErrors("number");
    }
  };

  return (
    <Modal
      open
      onClose={() => handleModalClose()}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
    >
      <div className="py-12 px-6 bg-ph-white w-[615px] shadow-[0px_3px_6px_#00000029] rounded-md">
        <ThemeProvider theme={theme}>
          <div className="font-semibold text-default-text text-base">
            {title}
          </div>
          <form onSubmit={handleSubmit(addMunicipality)}>
            <div className="mt-8 flex flex-col md:flex-row md:items-center">
              <div className="w-full sm:w-[48.5%] sm:pl-2">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {t("Tree Species.Tree species")} *
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                  control={control}
                  name="species"
                  selectOptions={treeSpecies}
                  placeholder={t("Tree Species.Tree species")}
                  getOptionLabel={(option) =>
                    i18n.language === "fr"
                      ? option.name_transes?.fr === ""
                        ? option.name_transes?.en
                        : option.name_transes?.fr
                      : option.name_transes?.en
                  } />
                {errors.species && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row mt-7">
              <div className="w-full sm:w-[48.5%] sm:pl-2">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {t("Events.Number")} *
                </p>
                <NumberField
                  onChange={numberChange}
                  inputStyle="py-3 placeholder-ph-light-gray"
                  getValues={getValues}
                  clearError={clearErrors}
                  name="number"
                  placeholder={t("Events.Number") + ""}
                  setValue={setValue}
                  register={register("number", {
                    pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                    min: {
                      value: 1,
                      message: `${t("Message.The minimum number is 1")}`,
                    },
                    required: `${t("Message.Required field")}`,

                  })}
                  readOnl={false}
                />
                {errors.number?.type === "min" && (
                  <p className="error-text">
                    {t("Message.The minimum number is 1")}
                  </p>
                )}
                {errors.number?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
                {errors.number &&
                  <p className="error-text">{t("Message.Required field")}</p>
                }
              </div>
              <div className="w-full sm:w-[48.5%] sm:pl-[23px]">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {t("Plantings.Community")} *
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                    validate: (value: any) => {
                      if (!value || value.slug === "") {
                        return `${t("Message.Required field")}`;
                      }
                      return true;
                    },
                  }}
                  control={control}
                  name="community"
                  selectOptions={communities}
                  placeholder={t("Plantings.Community")}
                  getOptionLabel={(option) => option.name}
                />
                {errors.community && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row mt-7">
              <div className="w-full sm:w-[48.5%] sm:pl-2">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {t("Events.Recipient type")} *</p>
                <Controller
                  control={control}
                  rules={{ required: `${t("Message.Required field")}` }}
                  name="recipient_type"
                  render={({ field: { onChange, value } }) => (
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel shrink={false}>
                        {!value && `${t("Events.Recipient type")}`}
                      </InputLabel>
                      <Select
                        style={{}}
                        IconComponent={ExpandMoreIcon}
                        variant="outlined"
                        value={value || null}
                        onChange={(e) => {
                          onChange(e as ChangeEvent<Element>);
                        }}
                        sx={{
                          border: "1px solid #eeecec",
                          borderRadius: "4px",
                          "& fieldset": { border: "none" },
                        }}
                      >
                        <MenuItem value="fam">
                          {t("Register.Family")}
                        </MenuItem>
                        <MenuItem value="ind">
                          {t("Register.Individual")}
                        </MenuItem>
                        <MenuItem value="ins">
                          {t("Register.Institution")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.recipient_type && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full sm:w-[48.5%] sm:pl-[23px]">
                <p className="text-sm text-default-text mb-[6px] mt-7 sm:mt-0">
                  {t("Events.Number received")} *
                </p>
                <NumberField
                  onChange={numberChange}
                  inputStyle="py-3 placeholder-ph-light-gray"
                  getValues={getValues}
                  clearError={clearErrors}
                  name="number_received"
                  placeholder={t("Events.Number received") + ""}
                  setValue={setValue}
                  register={register("number_received", {
                    pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                    min: {
                      value: 1,
                      message: `${t("Message.The minimum number is 1")}`,
                    },
                    required: `${t("Message.Required field")}`,
                  })}
                  readOnl={false}
                />
                {errors.number_received?.type === "min" && (
                  <p className="error-text">
                    {t("Message.The minimum number is 1")}
                  </p>
                )}
                {errors.number_received?.type === "pattern" && (
                  <p className="error-text">{t("Message.Invalid number")}</p>
                )}
                {errors.number_received &&
                  <p className="error-text">{t("Message.Required field")}</p>
                }
              </div>
            </div>
            <div className="mt-10 flex flex-row justify-end">
              <button
                onClick={() => {
                  handleModalClose();
                }}
                type="button"
                className="text-default-text text-sm mt-16rounded-3xl px-6 py-2 font-medium"
              >
                {t("Message.Cancel")}
              </button>
              <button
                type="submit"
                className="main-btn px-[23px] py-[10px] text-sm"
              >
                {textButton}
              </button>
            </div>
          </form>
        </ThemeProvider>
      </div>
    </Modal>
  );
};

export default AddTreeDistro;
