import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { faDownload, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const OldFile: React.FC<{
  address: string;
  id: number;
  delete: boolean;
  removeOldFile: (id: number) => void;
}> = ({ address, id, delete: deleteOld, removeOldFile }) => {
  const [showAction, isAction] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    document.addEventListener("click", handelClick, true);
  }, []);
  const handelClick = (e: any) => {
    if (
      !divRef.current?.contains(e.target) &&
      !btnRef.current?.contains(e.target)
    ) {
      isAction(false);
    }
  };

  const removeFile = () => {
    removeOldFile(id);
  };

  const format = address.split(".");
  const realFormat = format[format.length - 1];
  return (
    <div className="w-full flex items-center rounded-md bg-ph-bg-light-white border border-[#EEECEC] relative mb-2">
      {(realFormat.toLowerCase() === "svg" ||
        realFormat.toLowerCase() === "png" ||
        realFormat.toLowerCase() === "jpg" ||
        realFormat.toLowerCase() === "jpeg") && (
          <img alt="img" src={address} className="w-[60px] h-[60px]" />
        )}

      {realFormat === "pdf" && (
        <img alt="img" src="/img/file-pdf.svg" className="w-[60px] h-[60px]" />
      )}
      {realFormat === "txt" && (
        <img alt="img" src="/img/file-txt.svg" className="w-[60px] h-[60px]" />
      )}
      {(realFormat === "docx" || realFormat === "doc") && (
        <img alt="img" src="/img/file-doc.svg" className="w-[60px] h-[60px]" />
      )}
      {(realFormat === "xlsx" || realFormat === "xls") && (
        <img
          alt="img"
          src="/img/file-excel.svg"
          className="w-[60px] h-[60px]"
        />
      )}
      <p className="text-sm ml-2 h-5 truncate w-20 sm:w-60 md:w-72 lg:w-[118px] xl:w-56 font-medium text-default-text">
        {address.split("/")[5]}
      </p>
      {deleteOld ? (
        <div className="absolute right-3">
          <button
            type="button"
            ref={btnRef}
            onClick={() => isAction(!showAction)}
            className="text-[#979797] px-3 py-1 rounded-md hover:bg-ph-bgIcon hover:text-ph-btn"
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </button>
          {showAction && (
            <div
              ref={divRef}
              className="absolute z-10 right-1 w-fit h-[71px] shadow-lg rounded-md text-center bg-white px-4 py-1"
            >
              <a
                href={address}
                onClick={() => isAction(false)}
                target="_blank"
                className="text-sm text-default-text flex justify-between py-[5px]"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faDownload} className="mr-2" />
                {t("Tree Species.Download")}
              </a>
              <button
                onClick={removeFile}
                className="text-sm text-default-text flex justify-between py-[5px]"
              >
                <FontAwesomeIcon icon={faTrashCan} className="mr-2" />
                {t("PlantingFilter.Delete")}
              </button>
            </div>
          )}{" "}
        </div>
      ) : (
        <div className="absolute right-3">
          <a
            href={address}
            onClick={() => isAction(false)}
            target="_blank"
            className="text-sm text-default-text flex justify-between py-[5px] hover:text-ph-btn"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faDownload} className="mr-2 " />
            {t("Tree Species.Download")}
          </a>{" "}
        </div>
      )}
    </div>
  );
};

export default OldFile;
