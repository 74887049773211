import { Fragment, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";
import { faPencil, faTrashCan, faEye } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { profileInfo } from "../../store";

const ActionItem: React.FC<{
  editLink?: string;
  deleteAction: () => void;
  editAction?: () => void;
  isEditModal?: boolean;
  delete: boolean;
  viewLink: string;
  view: string;
  viewHistory: string;
  viewCommunity?: string;
  viewDepartment?: string;
  historyLink: string;
  width?: string;
  height?: string;
  notEditable?: boolean;
}> = ({
  editLink,
  delete: propsDelete,
  deleteAction,
  editAction,
  isEditModal,
  view,
  viewHistory,
  viewCommunity,
  viewDepartment,
  viewLink,
  historyLink,
  width,
  height,
  notEditable,
}) => {
  const [showAction, isShowAction] = useState<boolean>(false);
  const profile = useSelector(profileInfo);
  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    document.addEventListener("click", handelClick, true);
  }, []);
  const toggleAction = () => {
    isShowAction(!showAction);
  };

  const handelClick = (e: any) => {
    if (
      !divRef.current?.contains(e.target) &&
      !btnRef.current?.contains(e.target)
    ) {
      isShowAction(false);
    }
  };
  return (
    <div className="relative">
      <button
        ref={btnRef}
        onClick={toggleAction}
        className="text-ph-gray px-3 py-1 rounded-md hover:bg-ph-bgIcon hover:text-ph-btn"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      {showAction && (
        <div
          ref={divRef}
          className={`absolute z-10 right-1  ${
            width !== "" ? width : "w-fit"
          } ${
            height ? `${height} py-1` : "py-3"
          } grid-flow-col text-left shadow-lg rounded-md bg-white px-4 top-[14px] right-[16px]`}
        >
          {viewHistory && (
            <Link
              to={historyLink}
              className="text-sm text-default-text flex items-center w-[132px] py-[5px]"
            >
              <FontAwesomeIcon className="mr-2" icon={faEye} />
              {viewHistory}
            </Link>
          )}
          {view && (
            <Link
              to={viewLink}
              className="text-sm text-default-text flex items-center w-[132px] py-[5px]"
            >
              <FontAwesomeIcon className="mr-2" icon={faEye} />
              {view}
            </Link>
          )}
          {(profile.role === "A" || profile.role === "C") && viewCommunity && (
            <Link
              to={viewLink}
              className={`text-sm text-default-text flex items-center ${
                width !== "" ? "" : "w-[132px]"
              } py-[5px]`}
            >
              <FontAwesomeIcon className="mr-2" icon={faEye} />
              {viewCommunity}
            </Link>
          )}
          {(profile.role === "A" || profile.role === "C") && viewDepartment && (
            <Link
              to={viewLink}
              className={`text-sm text-default-text flex items-center ${
                width !== "" ? "" : "w-[132px]"
              } py-[5px]`}
            >
              <FontAwesomeIcon className="mr-2" icon={faEye} />
              {viewDepartment}
            </Link>
          )}
          {(profile.role === "A" ||
            profile.role === "C" ||
            (editLink?.includes("register") && profile.role === "O")) && (
            <Fragment>
              <div className={`${width !== "" ? "" : "w-[184px]"}`}>
                {!notEditable &&
                  (isEditModal ? (
                    <button
                      onClick={() => {
                        isShowAction(false);
                        editAction && editAction();
                      }}
                      className="text-sm text-default-text flex items-center py-[5px]"
                    >
                      <FontAwesomeIcon className="mr-2" icon={faPencil} />
                      {t("PlantingFilter.Edit")}
                    </button>
                  ) : (
                    <Link
                      to={editLink!!}
                      className="text-sm text-default-text flex items-center py-[5px]"
                    >
                      <FontAwesomeIcon className="mr-2" icon={faPencil} />
                      {t("PlantingFilter.Edit")}
                    </Link>
                  ))}

                {propsDelete && (
                  <button
                    onClick={() => {
                      isShowAction(false);
                      deleteAction();
                    }}
                    className="text-sm text-default-text flex items-center py-[5px]"
                  >
                    <FontAwesomeIcon icon={faTrashCan} className="mr-2" />{" "}
                    {t("PlantingFilter.Delete")}
                  </button>
                )}
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default ActionItem;
