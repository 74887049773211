import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../Navbar/Dropdown";
import { Link } from "react-router-dom";

const Logo = () => {
  const { i18n } = useTranslation();
  return (
    <Fragment>
      <div className="absolute right-0">
        <Dropdown />
      </div>
      <br />
      <Link to="/">
        <img
          src="/img/Inshindo logo.png"
          alt="logo"
          className="mx-auto h-[113px]"
        />
      </Link>
    </Fragment>
  );
};

export default Logo;
