import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAxios from "../../../../hook/useAxios";
import GenericTable from "../../../Global/GenericTable";
import TreeDistroTableItem from "./TreeDistroTableItem";
import AddTreeDistro from "./AddTreeDistro";
import { DistroDetail } from "../../../../type/distroDetail";

const TreeDistributionDetail = ({ id }: { id: string }) => {
  const [distroList, setDistroList] = useState<DistroDetail[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const [count, setCount] = useState<number>(0);
  const { t } = useTranslation();
  const {
    fetchData: getDistroDetails,
    response,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${id}/distributes/?&page=${page}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );
  const { fetchData: addData, response: addResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${id}/distributes/new/`,
    "POST",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setDistroList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    if (addResponse) {
      getDistroDetails({});
      toast.success(t("Message.Successful add"));
      setShowAddModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleAddMunicipality = () => {
    setShowAddModal(true);
  };

  const handleModalClose = () => {
    setShowAddModal(false);
  };

  return (
    <div className="bg-white mt-3 rounded-md">
      <div className="flex justify-between mb-11">
        <h1 className="py-[10px] font-semibold mr-[12px] md:mr-0 text-sm text-default-text">
          {t("Events.Tree distribution details")}
        </h1>
        <button
          onClick={handleAddMunicipality}
          className="main-btn px-[23px] py-[10px] text-sm"
        >
          {t("Events.Add detail")}
        </button>
      </div>
      <GenericTable
        countData={count}
        handleChange={handleChange}
        dataList={distroList}
        fetchData={() => {
          getDistroDetails({});
        }}
        loading={loading}
        setOrderingList={order}
        titles={[
          { name: t("Tree Species.Tree species"), value: "species" },
          { name: t("Events.Number"), value: "number" },
          { name: t("Plantings.Community"), value: "community" },
          { name: t("Events.Recipient type"), value: "recipient_type" },
          { name: t("Events.Number received"), value: "number_received" },
        ]}
        renderItem={(distro, fetchData, lastIndex) => (
          <TreeDistroTableItem
            distroDetail={distro}
            fetchData={fetchData}
            lastIndex={lastIndex}
          />
        )}
      />
      <br />
      {showAddModal && (
        <AddTreeDistro
          handleModalClose={handleModalClose}
          send={addData}
          textButton={t("Register.Add")}
          title={t("Events.Tree distribution detail")}
        />
      )}
    </div>
  );
};

export default TreeDistributionDetail;
