import { Fragment } from "react";
import TableCell from "@mui/material/TableCell";

const PlantingTableItem: React.FC<{
  department: string;
  plantingEvents: number;
  plantedTrees: number;
  dead_trees: number;
  participants: number;
}> = ({
  department,
  plantedTrees,
  plantingEvents,
  dead_trees,
  participants,
}) => {
  return (
    <Fragment>
      <TableCell>{department}</TableCell>
      <TableCell align="center">{plantingEvents}</TableCell>
      <TableCell align="center">{plantedTrees}</TableCell>
      <TableCell align="center">{dead_trees}</TableCell>
      <TableCell align="center">{participants}</TableCell>
    </Fragment>
  );
};

export default PlantingTableItem;
