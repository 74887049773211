import { useTranslation } from "react-i18next";
import { Fragment, useEffect, useRef, useState } from "react";

const RegisterMap: React.FC<{
  lat: number;
  long: number;
  updateLatLang: (lat: number, long: number) => void;
}> = ({ lat, long, updateLatLang }) => {
  const [zoomLevel, setZoomLevel] = useState(7);
  const [mapType, setMapType] = useState<string | undefined>("roadmap");
  const googleMapRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  let googleMap: google.maps.Map;

  // initialize the google map
  const initGoogleMap = () => {
    if (!window.google || !window.google.maps) {
      setTimeout(initGoogleMap, 150);
      return;
    }
    const mapOptions = {
      zoom: zoomLevel,
      center: { lat: lat || -14.6811018, lng: long || 27.8295816 },
      mapId: "de709e4ae98647a3",
      mapTypeId: mapType,
    };
    googleMap = new window.google.maps.Map(
      googleMapRef.current! as HTMLElement,
      mapOptions
    );

    // create marker on google map
    const marker = new window.google.maps.Marker({
      position: { lat: lat || -14.6811018, lng: long || 27.8295816 },
      map: googleMap,
      icon: {
        url: "/img/tree-alt.svg",
        // set marker width and height
        scaledSize: new window.google.maps.Size(25, 25),
      },
      draggable: true,
      title: t("Register.Drag the tree icon") + "",
    });
    // Configure the drag marker listener and get new lat, long
    marker.addListener("dragend", (event: any) => {
      setZoomLevel(googleMap.getZoom()!);
      marker.setPosition(event.latLng);
      googleMap.setCenter(event.latLng);
      updateLatLang(event.latLng.toJSON().lat, event.latLng.toJSON().lng);
      setMapType(googleMap.getMapTypeId());
    });

    // Configure the click listener
    googleMap.addListener("click", (mapsMouseEvent: any) => {
      googleMap.setZoom(10);
    });
  };

  // load google map script
  const loadGoogleMapScript = () => {
    if (
      typeof window.google === "object" &&
      typeof window.google.maps === "object"
    ) {
      initGoogleMap();
    }
    if (
      !document.querySelector(
        "script[src^='https://maps.googleapis.com/maps/api/js']"
      )
    ) {
      const googleMapScript = document.createElement("script");
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&map_ids=de709e4ae98647a3`;
      window.document.body.appendChild(googleMapScript);
      initGoogleMap();
    }
  };

  useEffect(() => {
    loadGoogleMapScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, long]);

  return (
    <Fragment>
      {googleMapRef && <div ref={googleMapRef} className="w-full h-[65%]" />}
    </Fragment>
  );
};

export default RegisterMap;
