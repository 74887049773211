import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useAxios from "../../../../hook/useAxios";
import GenericTable from "../../../Global/GenericTable";
import NurseryEstablishmentTableItem from "./NurseryEstablishmentTableItem";
import AddNurseryEstablishment from "./AddNurseryEstablishment";
import { NurseryDetail } from "../../../../type/nurseryDetail";
import SpeciesList from "../../../../type/speciesList";

const NurseryEstablishmentDetail = ({ id }: { id: string }) => {
  const [nurseryList, setNurseryList] = useState<NurseryDetail[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [nurseryExist, setNurseryExist] = useState<SpeciesList>({
    name_transes: { en: "", fr: "" },
    slug: "",
  });
  const [count, setCount] = useState<number>(0);
  const { t } = useTranslation();
  const {
    fetchData: getMunicipalities,
    response,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${id}/nursery_establishments/?&page=${page}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );
  const { fetchData: addData, response: addResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${id}/nursery_establishment/new/`,
    "POST",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setNurseryList(response.data.results);
      // Log nursery_slug if it exists in the response
      if (response?.data.results.length > 0) {
        setNurseryExist({
          name_transes: response?.data.results[0].nursery_name_transes,
          slug: response?.data.results[0].nursery_slug,
        });
      } else {
        setNurseryExist({
          name_transes: { en: "", fr: "" },
          slug: "",
        });
      }
    }
  }, [response]);

  useEffect(() => {
    if (addResponse) {
      getMunicipalities({});
      toast.success(t("Message.Successful add"));
      setShowAddModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleAddMunicipality = () => {
    setShowAddModal(true);
  };

  const handleModalClose = () => {
    setShowAddModal(false);
  };
  return (
    <div className="bg-white mt-3 rounded-md">
      <div className="flex justify-between mb-11">
        <h1 className="py-[10px] font-semibold mr-[12px] md:mr-0 text-sm text-default-text">
          {t("Events.Nursery establishment details")}
        </h1>
        <button
          onClick={handleAddMunicipality}
          className="main-btn px-[23px] py-[10px] text-sm"
        >
          {t("Events.Add detail")}
        </button>
      </div>
      <GenericTable
        countData={count}
        handleChange={handleChange}
        dataList={nurseryList}
        fetchData={() => {
          getMunicipalities({});
        }}
        loading={loading}
        setOrderingList={order}
        titles={[
          { name: t("Tagging tool.Nursery"), value: "" },
          { name: t("Plantings.Species"), value: "species" },
          { name: t("Events.Number"), value: "number" },
          { name: t("Tree inventory.Initial age"), value: "initial_age" },
        ]}
        renderItem={(nurseries, fetchData, lastIndex) => (
          <NurseryEstablishmentTableItem
            nurseries={nurseries}
            fetchData={fetchData}
            lastIndex={lastIndex}
          />
        )}
      />
      <br />
      {showAddModal && (
        <AddNurseryEstablishment
          handleModalClose={handleModalClose}
          send={addData}
          textButton={t("Events.Add detail")}
          exist={nurseryExist}
        />
      )}
    </div>
  );
};

export default NurseryEstablishmentDetail;
